import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
} from '@react-google-maps/api'
import { Button, Form } from 'react-bootstrap'
import { calculateInitialRegion } from '../lib/geography'
import { X } from 'react-bootstrap-icons'

export type MapAreaEditorType = {
  center: () => void
}

const MapStrokeColors = ['#ff0000', '#0000ff', '#ff00ff', '#000000', '#148514']

export const MapAreaEditor = forwardRef<
  MapAreaEditorType,
  {
    value: [number, number][][]
    onChange: (value: [number, number][][]) => void
    mapContainerStyle?: CSSProperties
    multiplePolygons?: boolean
  }
>(({ value, onChange, mapContainerStyle, multiplePolygons }, ref) => {
  const [areaCenter, setAreaCenter] = useState({ lat: 52, lng: 16 })
  const [areaMode, setAreaMode] = useState<'add/modify' | 'delete'>(
    'add/modify'
  )
  const [areaSelectedPoint, setAreaSelectedPoint] = useState<[number, number]>()
  const [areaPolygonRefs, setAreaPolygonRefs] = useState<google.maps.Polygon[]>(
    []
  )

  useImperativeHandle(ref, () => ({
    center: () => {
      setAreaCenter(
        calculateInitialRegion({
          type: 'Polygon',
          coordinates: value,
        }) || { lat: 52, lng: 16 }
      )
    },
  }))

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={areaCenter}
        zoom={7}
        options={{
          disableDoubleClickZoom: true,
          controlSize: 24,
          streetViewControl: false,
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite'],
          },
        }}
        onClick={() => setAreaSelectedPoint(undefined)}
      >
        <>
          {value.map((polygon, i) => (
            <>
              <Polygon
                onLoad={(polygonRef) =>
                  setAreaPolygonRefs([
                    ...areaPolygonRefs.slice(0, i),
                    polygonRef,
                    ...areaPolygonRefs.slice(i + 1, value.length),
                  ])
                }
                onMouseUp={() => {
                  if (areaPolygonRefs[i]) {
                    const path = areaPolygonRefs[i].getPath().getArray()
                    if (path) {
                      onChange([
                        ...value.slice(0, i),
                        path.map((point) => [point.lat(), point.lng()]) as [
                          number,
                          number
                        ][],
                        ...value.slice(i + 1, value.length),
                      ])
                    }
                  }
                }}
                options={{
                  strokeColor: MapStrokeColors[i % MapStrokeColors.length],
                  fillColor: MapStrokeColors[i % MapStrokeColors.length] + '4c',
                  editable: areaMode === 'add/modify',
                }}
                key={i}
                path={polygon.map(([lat, lng]) => ({
                  lat,
                  lng,
                }))}
              />
              {areaMode === 'delete' &&
                polygon.map(([lat, lng], j) => (
                  <Marker
                    position={{ lat, lng }}
                    icon={{
                      path: 0.0,
                      scale:
                        areaSelectedPoint?.[0] === i &&
                        areaSelectedPoint[1] === j
                          ? 6.0
                          : 4.0,
                      strokeWeight: 1,
                      strokeColor: MapStrokeColors[i % MapStrokeColors.length],
                      fillColor:
                        areaSelectedPoint?.[0] === i &&
                        areaSelectedPoint[1] === j
                          ? MapStrokeColors[i % MapStrokeColors.length]
                          : '#ffffff',
                      fillOpacity: 1,
                    }}
                    draggable={true}
                    onDragEnd={({ latLng }) => {
                      if (!latLng) return

                      onChange([
                        ...value.slice(0, i),
                        [
                          ...value[i].slice(0, j),
                          [latLng.lat(), latLng.lng()],
                          ...value[i].slice(j + 1),
                        ],
                        ...value.slice(i + 1),
                      ])
                    }}
                    onClick={() => setAreaSelectedPoint([i, j])}
                  />
                ))}
            </>
          ))}
        </>
        {areaMode === 'add/modify' &&
          (value.length === 0 || multiplePolygons === true) && (
            <DrawingManager
              drawingMode={'polygon' as any}
              options={{
                polygonOptions: {
                  editable: true,
                  draggable: true,
                  clickable: true,
                  strokeColor:
                    MapStrokeColors[value.length % MapStrokeColors.length],
                  fillColor:
                    MapStrokeColors[value.length % MapStrokeColors.length] +
                    '4c',
                },
                drawingControlOptions: {
                  drawingModes: ['polygon' as any],
                  position: 0.0,
                },
              }}
              onPolygonComplete={(polygon) => {
                polygon.setMap(null)
                onChange([
                  ...value,
                  polygon
                    .getPath()
                    .getArray()
                    .map((point) => [
                      point.lat() as number,
                      point.lng() as number,
                    ]) as [number, number][],
                ])
              }}
            />
          )}
        <div
          style={{
            position: 'absolute',
            borderRadius: '2px',
            padding: '2px 8px 0px 14px',
            top: '36px',
            left: '6px',
            backgroundColor: '#fff',
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
            fontSize: '14px',
            color: 'rgb(86, 86, 86)',
          }}
        >
          <Form.Check
            className="m-0"
            type="switch"
            value={areaMode === 'delete' ? 'true' : 'false'}
            label="Tryb usuwania"
            onChange={({ target: { checked } }) =>
              setAreaMode(checked ? 'delete' : 'add/modify')
            }
          />
        </div>
        {areaMode === 'delete' && areaSelectedPoint !== undefined && (
          <Button
            style={{
              position: 'absolute',
              top: '6px',
              left: '50%',
              marginLeft: '-55px',
              padding: '0 0.5rem 0 0.25rem',
              fontSize: '0.875rem',
              borderRadius: '0.125rem',
              display: 'flex',
              alignItems: 'center',
            }}
            size="sm"
            variant="danger"
            onClick={() => {
              const [i, j] = areaSelectedPoint

              if (value[i].length === 3) {
                onChange([...value.slice(0, i), ...value.slice(i + 1)])
                setAreaSelectedPoint(undefined)
                return
              }

              onChange([
                ...value.slice(0, i),
                [...value[i].slice(0, j), ...value[i].slice(j + 1)],
                ...value.slice(i + 1),
              ])
              setAreaSelectedPoint(undefined)
            }}
          >
            <X size={20} />
            Usuń punkt
          </Button>
        )}
      </GoogleMap>
    </>
  )
})
