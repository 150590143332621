import { FC, ReactElement } from 'react'
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import { FlagMap, Locale } from '../lib/translations'

export const TranslatedField: FC<{
  label: string
  control: (locale: Locale) => ReactElement
}> = ({ label, control }) => (
  <Form.Group as={Row} className="mb-3">
    <Form.Label column sm="2">
      <div style={{ marginTop: '2.65rem' }}>{label}</div>
    </Form.Label>
    <Col sm="10">
      <Tabs defaultActiveKey={Locale.pl_PL} className="translated-field">
        {(Object.keys(Locale) as Locale[]).map((locale) => (
          <Tab
            key={locale}
            eventKey={locale}
            title={FlagMap[locale]}
            className="translated-field-tab"
          >
            {control(locale)}
          </Tab>
        ))}
      </Tabs>
    </Col>
  </Form.Group>
)
