import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetClientsDocument,
  GetClientsQuery,
  GetClientsQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Badge, Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const Clients: FC = () => {
  // page content
  const pageTitle = 'Klienci'
  const [clients, setClients] = useState<GetClientsQuery['client']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => clients, [clients])

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetClientsQuery, GetClientsQueryVariables>(
          GetClientsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setClients(refetch ? data.client : clients.concat(data.client))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.client_aggregate.aggregate?.count || 0)
      }
    },
    [client, clients, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <InfiniteTable<GetClientsQuery['client'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'E-mail', accessor: 'email' },
          {
            Header: 'Zgody',
            id: 'consents',
            accessor: (rowData) => (
              <>
                <div>
                  <span>Newsletter: </span>
                  {rowData.newsletterConsent ? (
                    <Badge bg="success">Tak</Badge>
                  ) : (
                    <Badge bg="danger">Nie</Badge>
                  )}
                </div>
                <div>
                  <span>Push: </span>
                  {rowData.pushConsent ? (
                    <Badge bg="success">Tak</Badge>
                  ) : (
                    <Badge bg="danger">Nie</Badge>
                  )}
                </div>
              </>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/client/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Clients
