import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetTestLaboratoriesDocument,
  GetTestLaboratoriesQuery,
  GetTestLaboratoriesQueryVariables,
  useDeleteLaboratoryTestMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, Plus, Trash } from 'react-bootstrap-icons'
import { InfiniteTable } from '../../components/InfiniteTable'
import TestLaboratoryModal, {
  TestLaboratoryModalType,
} from '../../components/TestLaboratoryModal'

const BatchSize = 20

const TestLaboratories: FC<{ id: number; readOnly?: boolean }> = ({
  id,
  readOnly,
}) => {
  // page content
  const pageTitle = 'Laboratoria wykonujące badanie'
  const [testLaboratories, setTestLaboratories] = useState<
    GetTestLaboratoriesQuery['laboratory_test']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => testLaboratories, [testLaboratories])
  const client = useClient()
  const testLaboratoryModalRef = useRef<TestLaboratoryModalType>(null)
  const [, deleteLaboratoryTest] = useDeleteLaboratoryTestMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetTestLaboratoriesQuery, GetTestLaboratoriesQueryVariables>(
          GetTestLaboratoriesDocument,
          { testId: id, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setTestLaboratories(
          refetch
            ? data.laboratory_test
            : testLaboratories.concat(data.laboratory_test)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.laboratory_test_aggregate.aggregate?.count || 0)
      }
    },
    [client, testLaboratories, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            disabled={readOnly}
            onClick={() => testLaboratoryModalRef.current?.show({ testId: id })}
          >
            <Plus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetTestLaboratoriesQuery['laboratory_test'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Laboratorium',
            accessor: ({ laboratory }) =>
              laboratory.shortName || laboratory.name,
          },
          {
            Header: 'Cena',
            accessor: ({ price }) => (
              <span className="text-nowrap">{price.toFixed(2)} zł</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    disabled={readOnly}
                    onClick={() =>
                      testLaboratoryModalRef.current?.show({
                        id: rowData.id,
                        testId: id,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    disabled={readOnly}
                    onClick={async () => {
                      if (
                        window.confirm(
                          'Czy napewno chcesz usunąć laboratorium?'
                        )
                      ) {
                        await deleteLaboratoryTest({ id: rowData.id })
                        await loadMoreRows({ refetch: true })
                      }
                    }}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <TestLaboratoryModal
        ref={testLaboratoryModalRef}
        onChange={() => loadMoreRows({ refetch: true })}
      />
    </div>
  )
}

export default TestLaboratories
