import Meta from '../components/Meta'
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useClient } from 'urql'
import { useForm } from 'react-hook-form'
import {
  GetTrainingDocument,
  GetTrainingQuery,
  GetTrainingQueryVariables,
  useInsertTrainingMutation,
  usePingMutation,
  useUpdateTrainingMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Image,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft, Trash } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import type { LocalizedField } from '../lib/translations'
import { Locale } from '../lib/translations'
import { TranslatedField } from '../components/TranslatedField'
import { useParams } from 'react-router'
import { guessStorageAddress } from '../lib/api'
import { AuthContext } from '../provider/auth'

type FormTraining = {
  name_i18n: LocalizedField
  description_i18n: LocalizedField
  previewFileId: number
  videoFileId: number
  price: number
}

export function getStorageUrl(fileId: number, accessToken?: string) {
  return (
    guessStorageAddress() +
    `/files/${fileId}${accessToken ? `?accessToken=${accessToken}` : ''}`
  )
}

const Training: FC = () => {
  const pageTitle = `Szkolenie`
  const params = useParams()
  const client = useClient()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [, setGetTrainingFetching] = useState(false)
  const [{ fetching: updateTrainingFetching }, updateTraining] =
    useUpdateTrainingMutation()
  const [{ fetching: insertTrainingFetching }, insertTraining] =
    useInsertTrainingMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const [readOnly, setReadOnly] = useState(false)

  const previewInputRef = useRef<HTMLInputElement>(null)
  const [previewFile, setPreviewFile] = useState<File>()
  const [previewDataUrl, setPreviewDataUrl] = useState<string | undefined>(
    'https://placehold.co/171x180@3x?text=%2B'
  )
  const [previewFileId, setPreviewFileId] = useState<number>()

  const videoInputRef = useRef<HTMLInputElement>(null)
  const [videoFile, setVideoFile] = useState<File>()
  const [videoDataUrl, setVideoDataUrl] = useState<string | undefined>()
  const [videoFileId, setVideoFileId] = useState<number>()
  const [, ping] = usePingMutation()

  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormTraining>({
    defaultValues: {
      name_i18n: { pl_PL: '' },
      description_i18n: { pl_PL: '' },
      previewFileId: undefined,
      videoFileId: undefined,
      price: 0,
    },
  })

  const id = Number.isInteger(parseInt(params.id || ''))
    ? parseInt(params.id || '')
    : undefined

  useEffect(() => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => setPreviewDataUrl(reader.result?.toString() || undefined),
      false
    )

    if (previewFile) {
      reader.readAsDataURL(previewFile)
    }
  }, [previewFile])

  useEffect(() => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => setVideoDataUrl(reader.result?.toString() || undefined),
      false
    )

    if (videoFile) {
      reader.readAsDataURL(videoFile)
    }
  }, [videoFile])

  useEffect(() => {
    setVideoDataUrl(
      videoFileId && auth.accessToken
        ? getStorageUrl(videoFileId, auth.accessToken)
        : undefined
    )
  }, [videoFileId, auth.accessToken])

  useEffect(() => {
    setPreviewDataUrl(
      previewFileId && auth.accessToken
        ? getStorageUrl(previewFileId, auth.accessToken)
        : 'https://placehold.co/171x180@3x?text=%2B'
    )
  }, [previewFileId, auth.accessToken])

  useEffect(() => {
    const interval = setInterval(
      () => ping({}, { requestPolicy: 'network-only' }),
      60000
    )
    return () => clearInterval(interval)
  })

  const uploadFile = useCallback(
    async (file: File, permission: 'private' | 'public') => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('permission', permission)

      const response = await fetch(`${guessStorageAddress()}/files`, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: `Bearer ${auth.accessToken}`,
        },
      })

      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message)
      }

      return (await response.json()).id
    },
    [auth.accessToken]
  )

  useEffect(() => {
    if (id) {
      setGetTrainingFetching(true)
      client
        .query<GetTrainingQuery, GetTrainingQueryVariables>(
          GetTrainingDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(async ({ data }) => {
          if (data?.training_by_pk) {
            reset({
              name_i18n: data.training_by_pk.name_i18n || { pl_PL: '' },
              description_i18n: data.training_by_pk.description_i18n || {
                pl_PL: '',
              },
              previewFileId: data.training_by_pk.previewFileId,
              videoFileId: data.training_by_pk.videoFileId,
              price: data.training_by_pk.price,
            })

            setReadOnly(!!data.training_by_pk.deletedAt)
            setPreviewFileId(data.training_by_pk.previewFileId)
            setVideoFileId(data.training_by_pk.videoFileId)
          }
        })
        .finally(() => setGetTrainingFetching(false))
    }
  }, [id, client, reset])

  async function doSaveTraining(training: FormTraining) {
    setGeneralError(undefined)

    let uploadedPreviewFileId = previewFileId
    if (previewFile) {
      setPreviewFileId(
        (uploadedPreviewFileId = await uploadFile(previewFile, 'public'))
      )
    }

    if (!uploadedPreviewFileId) {
      setGeneralError('Unable to upload video')
      return
    }

    let uploadedVideoFileId = videoFileId
    if (videoFile) {
      setVideoFileId(
        (uploadedVideoFileId = await uploadFile(videoFile, 'private'))
      )
    }

    if (!uploadedVideoFileId) {
      setGeneralError('Unable to upload video')
      return
    }

    const { error, data } = id
      ? await updateTraining({
          ...training,
          previewFileId: uploadedPreviewFileId,
          videoFileId: uploadedVideoFileId,
          id,
        })
      : await insertTraining({
          ...training,
          previewFileId: uploadedPreviewFileId,
          videoFileId: uploadedVideoFileId,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_training_one' in data) {
      navigate(`/training/${data.insert_training_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/trainings')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>

      {readOnly && <Alert variant="danger">Szkolenie usunięte</Alert>}

      <Form onSubmit={handleSubmit(doSaveTraining)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano szkolenie</Toast.Body>
          </Toast>
        </ToastContainer>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            ID
          </Form.Label>
          <Form.Label column sm="10">
            {id}
          </Form.Label>
        </Form.Group>

        <TranslatedField
          label="Nazwa pełna"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.name_i18n?.[locale]}
                type="text"
                disabled={readOnly}
                placeholder="Nazwa pełna"
                {...register(`name_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ??
                    'Proszę podać nazwę pełną badania',
                })}
              />
              {errors.name_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.name_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Opis"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.description_i18n?.[locale]}
                as="textarea"
                disabled={readOnly}
                rows={8}
                {...register(`description_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ?? 'Proszę podać opis badania',
                })}
              />
              {errors.description_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.description_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Cena
          </Form.Label>
          <Col sm="10">
            <Form.Control
              isInvalid={!!errors.price}
              type="text"
              disabled={readOnly}
              placeholder="Cena"
              {...register('price', {
                required: 'Proszę podać cenę szkolenia',
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: 'Nieprawidłowa cena',
                },
              })}
            />
            {errors.price && (
              <Form.Control.Feedback>
                {errors.price.message}
              </Form.Control.Feedback>
            )}{' '}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Podgląd
          </Form.Label>
          <Col sm="10">
            <div
              onClick={(event) => {
                event.currentTarget
                  .querySelectorAll('input[type=file]')
                  .forEach((input: any) => input.click())
              }}
              className="d-inline-block"
              style={{ cursor: 'pointer' }}
            >
              <Image src={previewDataUrl} thumbnail />
              <input
                ref={previewInputRef}
                type="file"
                disabled={readOnly}
                className="visually-hidden"
                accept="image/png, image/jpeg"
                onChange={async (event) => {
                  if (event.target.files?.length) {
                    setPreviewFile(event.target.files[0])
                  }
                }}
              />
            </div>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Plik wideo
          </Form.Label>
          <Col sm="10">
            <div
              onClick={(event) => {
                event.currentTarget
                  .querySelectorAll('input[type=file]')
                  .forEach((input: any) => input.click())
              }}
              className="d-inline-block position-relative"
              style={{ cursor: 'pointer' }}
            >
              {!!videoDataUrl && (
                <video
                  controls
                  src={videoDataUrl}
                  width="300px"
                  height="200px"
                />
              )}
              {!videoDataUrl && (
                <Image
                  src="https://placehold.co/171x180@3x?text=%2B"
                  thumbnail
                />
              )}
              <input
                ref={videoInputRef}
                type="file"
                disabled={readOnly}
                className="visually-hidden"
                accept="image/mp4, image/avi"
                onChange={async (event) => {
                  if (event.target.files?.length) {
                    setVideoFile(event.target.files[0])
                  }
                }}
              />

              {!!videoDataUrl && (
                <Button
                  className="position-absolute"
                  style={{ top: '-2px', right: '-12px' }}
                  variant="danger"
                  onClick={() => {
                    setVideoFile(undefined)
                    setVideoFileId(undefined)
                    setVideoDataUrl(undefined)
                  }}
                >
                  <Trash size={18} />
                </Button>
              )}
            </div>
          </Col>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={
            updateTrainingFetching || insertTrainingFetching || readOnly
          }
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default Training
