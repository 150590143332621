import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetTagsDocument,
  GetTagsQuery,
  GetTagsQueryVariables,
  useDeleteTagMutation,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, PersonPlus, Trash } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const Tags: FC = () => {
  // page content
  const pageTitle = 'Kategorie'
  const [tags, setTags] = useState<GetTagsQuery['tag']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => tags, [tags])
  const client = useClient()
  const navigate = useNavigate()
  const [, deleteTag] = useDeleteTagMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetTagsQuery, GetTagsQueryVariables>(
          GetTagsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setTags(refetch ? data.tag : tags.concat(data.tag))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.tag_aggregate.aggregate?.count || 0)
      }
    },
    [client, tags, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  const doDeleteTag = useCallback(
    async (id: string) => {
      if (window.confirm(`Czy napewno chcesz usunąć kategorię "${id}"?`)) {
        await deleteTag({ id })
        window.location.reload()
      }
    },
    [deleteTag]
  )

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/tag')}
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetTagsQuery['tag'][number]>
        data={data}
        columns={[
          {
            id: 'id',
            Header: 'Kategoria',
            accessor: 'id',
          },
          {
            id: 'count',
            Header: 'Liczba badań',
            accessor: ({ testTags_aggregate: { aggregate } }) => (
              <span>{aggregate?.count}</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: ({ id }) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/tag/${encodeURIComponent(id)}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button variant="danger" onClick={() => doDeleteTag(id)}>
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Tags
