import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import {
  GetPushNotificationDocument,
  GetPushNotificationQuery,
  GetPushNotificationQueryVariables,
  useGetClientsWithPushNotificationConsentQuery,
  useInsertPushNotificationMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import type { LocalizedField } from '../lib/translations'
import { Locale } from '../lib/translations'
import { TranslatedField } from '../components/TranslatedField'

type FormPushNotification = {
  heading_i18n: LocalizedField
  content_i18n: LocalizedField
  url: string
  clientIds: number[]
}

const PushNotification: FC = () => {
  const pageTitle = 'Powiadomienie push'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetPushNotificationFetching] = useState(false)
  const [{ fetching: insertPushNotificationFetching }, insertPushNotification] =
    useInsertPushNotificationMutation()
  const [{ data: clientsData, fetching: clientsFetching }] =
    useGetClientsWithPushNotificationConsentQuery()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    watch,
  } = useForm<FormPushNotification>({
    defaultValues: {
      heading_i18n: { pl_PL: '' },
      content_i18n: { pl_PL: '' },
      url: '',
      clientIds: [],
    },
  })

  useEffect(() => {
    if (id) {
      setGetPushNotificationFetching(true)
      client
        .query<GetPushNotificationQuery, GetPushNotificationQueryVariables>(
          GetPushNotificationDocument,
          {
            id: parseInt(id),
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.push_notification_by_pk) {
            reset({
              heading_i18n: data.push_notification_by_pk.heading_i18n || {
                pl_PL: '',
              },
              content_i18n: data.push_notification_by_pk.content_i18n || {
                pl_PL: '',
              },
              url: data.push_notification_by_pk.url || undefined,
              clientIds:
                data.push_notification_by_pk.pushNotificationClients.map(
                  ({ clientId }) => clientId
                ),
            })
          }
        })
        .finally(() => setGetPushNotificationFetching(false))
    }
  }, [id, client, reset])

  async function doSavePushNotification({
    clientIds,
    ...pushNotification
  }: FormPushNotification) {
    setGeneralError(undefined)

    const { error, data } = await insertPushNotification({
      ...pushNotification,
      pushNotificationClients:
        clientsData?.client.map(({ id }) => ({
          clientId: id,
        })) || [],
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data) {
      navigate(`/push-notification/${data.insert_push_notification_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/push-notifications')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSavePushNotification)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano powiadomienie push</Toast.Body>
          </Toast>
        </ToastContainer>

        {id && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              ID
            </Form.Label>
            <Form.Label column sm="10">
              {id}
            </Form.Label>
          </Form.Group>
        )}

        <TranslatedField
          label="Nagłówek"
          control={(locale) => (
            <>
              <Form.Control
                disabled={!!id}
                isInvalid={!!errors.heading_i18n?.[locale]}
                type="text"
                placeholder="Nagłówek"
                {...register(`heading_i18n.${locale}`, {
                  required:
                    [Locale.pl_PL, Locale.en_GB, Locale.uk_UA].includes(
                      locale
                    ) ??
                    `Proszę podać nagłówek powiadomienia push dla języka ${locale}`,
                })}
              />
              {errors.heading_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.heading_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Treść"
          control={(locale) => (
            <>
              <Form.Control
                disabled={!!id}
                isInvalid={!!errors.content_i18n?.[locale]}
                as="textarea"
                rows={8}
                {...register(`content_i18n.${locale}`, {
                  required:
                    [Locale.pl_PL, Locale.en_GB, Locale.uk_UA].includes(
                      locale
                    ) ??
                    `Proszę podać treść powiadomienia push dla języka ${locale}`,
                })}
              />
              {errors.content_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.content_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            URL
          </Form.Label>
          <Col sm="10">
            <Form.Control
              disabled={!!id}
              isInvalid={!!errors.url}
              type="text"
              placeholder="URL"
              {...register('url')}
            />
            {errors.url && (
              <Form.Control.Feedback>
                {errors.url.message}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Liczba klientów
          </Form.Label>
          {id && (
            <Form.Label column sm="10">
              {watch('clientIds').length}
            </Form.Label>
          )}
          {!id && (
            <Form.Label column sm="10">
              {clientsFetching && <Spinner animation="border" />}
              {clientsData?.client.length}
            </Form.Label>
          )}
        </Form.Group>

        {!id && (
          <Button
            variant="primary"
            type="submit"
            disabled={insertPushNotificationFetching}
          >
            Wyślij
          </Button>
        )}
      </Form>
    </div>
  )
}

export default PushNotification
