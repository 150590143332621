import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetLogsDocument,
  GetLogsQuery,
  GetLogsQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Alert, Badge } from 'react-bootstrap'
import { InfiniteTable } from '../components/InfiniteTable'
import { useNavigate } from 'react-router-dom'
import {
  BagHeartFill,
  FileEarmarkMedicalFill,
  HeartFill,
  KeyFill,
  PersonFill,
  PersonHeart,
} from 'react-bootstrap-icons'

const BatchSize = 100

const Logs: FC = () => {
  // page content
  const pageTitle = 'Logi'
  const [logs, setLogs] = useState<GetLogsQuery['administrator_logs']>([])
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const [generalError, setGeneralError] = useState<string>()
  const data = useMemo(() => logs, [logs])
  const navigate = useNavigate()

  function getBadges(log: GetLogsQuery['administrator_logs'][number]) {
    const badges = []

    if (log.order) {
      badges.push(
        <Badge
          onClick={() => navigate(`/order/${log.order?.id}`)}
          style={{ cursor: 'pointer' }}
          className="me-1"
          key="order"
        >
          <FileEarmarkMedicalFill /> {log.order.id}
        </Badge>
      )
    }

    if (log.test) {
      badges.push(
        <Badge
          onClick={() => navigate(`/test/${log.test?.id}`)}
          style={{ cursor: 'pointer', maxWidth: '250px' }}
          className="me-1"
          key="test"
        >
          <HeartFill /> {log.test.shortName}
        </Badge>
      )
    }

    if (log.client) {
      badges.push(
        <Badge className="me-1" key="client">
          <PersonFill /> {log.client.email}
        </Badge>
      )
    }

    if (log.nurse) {
      badges.push(
        <Badge
          onClick={() => navigate(`/nurse/${log.nurse?.id}`)}
          style={{ cursor: 'pointer' }}
          className="me-1"
          key="nurse"
        >
          <BagHeartFill /> {log.nurse.name}
        </Badge>
      )
    }

    return badges
  }

  function getSessionBadges(
    log: Pick<
      GetLogsQuery['administrator_logs'][number],
      | 'sessionClient'
      | 'sessionNurse'
      | 'sessionLabUser'
      | 'sessionAdministrator'
    >
  ) {
    const badges = []

    if (log.sessionClient) {
      badges.push(
        <Badge className="me-1" bg="success" key="sessionClient">
          <PersonFill /> {log.sessionClient.email}
        </Badge>
      )
    }

    if (log.sessionNurse) {
      badges.push(
        <Badge
          onClick={() => navigate(`/nurse/${log.sessionNurse?.id}`)}
          style={{ cursor: 'pointer' }}
          className="me-1"
          bg="success"
          key="sessionNurse"
        >
          <BagHeartFill /> {log.sessionNurse.email}
        </Badge>
      )
    }

    if (log.sessionLabUser) {
      badges.push(
        <Badge
          onClick={() =>
            navigate(`/laboratory/${log.sessionLabUser?.laboratoryId}`)
          }
          style={{ cursor: 'pointer' }}
          className="me-1"
          bg="success"
          key="sessionLabUser"
        >
          <PersonHeart /> {log.sessionLabUser.name}
        </Badge>
      )
    }

    if (log.sessionAdministrator) {
      badges.push(
        <Badge
          onClick={() =>
            navigate(`/administrator/${log.sessionAdministrator?.id}`)
          }
          style={{ cursor: 'pointer' }}
          className="me-1"
          bg="success"
          key="sessionAdministrator"
        >
          <KeyFill /> {log.sessionAdministrator.name}
        </Badge>
      )
    }

    return badges
  }

  function toLogText(log: GetLogsQuery['administrator_logs'][number]) {
    switch (log.type) {
      case 'order_state_insert':
        return (
          <div>
            <p>
              Dodano stan zamówienia {log.order?.id}: {log.orderState?.state}
            </p>
            {getBadges(log)}
          </div>
        )
      case 'order_state_update':
        return (
          <div>
            <p>
              Zmieniono stan zamówienia {log.order?.id}:{log.orderState?.state}
            </p>
            {getBadges(log)}
          </div>
        )
      case 'order_state_delete':
        return (
          <div>
            <p>
              Usunięto stan zamówienia {log.order?.id}: {log.orderState?.state}
            </p>
            {getBadges(log)}
          </div>
        )
      case 'order_test_result_insert':
        return (
          <div>
            <p>Dodano wynik "{log.orderTestResult?.originalFilename}"</p>
            {getBadges(log)}
          </div>
        )
      case 'order_test_result_update':
        return (
          <div>
            <p>Zaktualizowano wynik {log.orderTestResult?.originalFilename}</p>
            {getBadges(log)}
          </div>
        )
      case 'order_test_result_delete':
        return (
          <div>
            <p>Usunięto wynik {log.orderTestResult?.originalFilename}</p>
            {getBadges(log)}
          </div>
        )
      case 'order_test_insert':
        return (
          <div>
            <p>
              Dodano badanie "{log.orderTest?.test.shortName}" do zamówienia #
              {log.order?.id}
            </p>
            {getBadges(log)}
          </div>
        )
      case 'order_test_update':
        return (
          <div>
            <p>
              Zaktualizowano badanie "{log.orderTest?.test.shortName}" w
              zamówieniu #{log.order?.id}
            </p>
            {getBadges(log)}
          </div>
        )
      case 'order_test_delete':
        return (
          <div>
            <p>
              Usunięto badanie "{log.orderTest?.test.shortName}" z zamówienia #
              {log.order?.id}
            </p>
            {getBadges(log)}
          </div>
        )
      case 'order_insert':
        return (
          <div>
            <p>Dodano zamówienie #{log.order?.id}</p>
            {getBadges(log)}
          </div>
        )
      case 'order_update':
        return (
          <div>
            <p>Zaktualizowano zamówienie #{log.order?.id}</p>
            {getBadges(log)}
          </div>
        )
      case 'order_delete':
        return (
          <div>
            <p>Usunięto zamówienie #{log.order?.id}</p>
            {getBadges(log)}
          </div>
        )
      default:
        return `${log.type}`
    }
  }

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      console.log('loadMoreRows', { offset })
      const { data, error } = await client
        .query<GetLogsQuery, GetLogsQueryVariables>(
          GetLogsDocument,
          { from: refetch ? 0 : offset, size: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setLogs(
          refetch
            ? data.administrator_logs
            : logs.concat(data.administrator_logs)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
      }
    },
    [client, logs, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <InfiniteTable<GetLogsQuery['administrator_logs'][number]>
        data={data}
        columns={[
          {
            Header: 'Data',
            accessor: ({ timestamp }) => new Date(timestamp).toLocaleString(),
          },
          {
            Header: 'Aktor',
            accessor: getSessionBadges,
          },
          {
            Header: 'Zdarzenie',
            accessor: toLogText,
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={true}
      />
    </div>
  )
}

export default Logs
