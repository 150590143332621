import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetLaboratoryCostSummaryDocument,
  GetLaboratoryCostSummaryQuery,
  GetLaboratoryCostSummaryQueryVariables,
  GetOrdersByLaboratoryDocument,
  GetOrdersByLaboratoryQuery,
  GetOrdersByLaboratoryQueryVariables,
  Order_By,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Alert, Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import { CloudDownload } from 'react-bootstrap-icons'
import moment from 'moment'
import ExcelJS from 'exceljs'
import { download } from '../lib/download'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const LaboratoryReports: FC = () => {
  // page content
  const pageTitle = 'Raporty laboratoriów'
  const [laboratories, setLaboratories] = useState<
    GetLaboratoryCostSummaryQuery['laboratory']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [dateFrom, setDateFrom] = useState<Date>(
    moment().subtract(1, 'month').startOf('day').toDate()
  )
  const [dateTo, setDateTo] = useState<Date>(moment().endOf('day').toDate())
  const client = useClient()
  const [generalError, setGeneralError] = useState<string>()
  const data = useMemo(() => laboratories, [laboratories])

  const createLaboratoryReport = useCallback(
    async (laboratoryId: number) => {
      const { data, error } = await client
        .query<GetOrdersByLaboratoryQuery, GetOrdersByLaboratoryQueryVariables>(
          GetOrdersByLaboratoryDocument,
          {
            laboratoryId,
            dateFrom,
            dateTo,
            orderBy: [{ currentStateDate: Order_By.Asc }],
          }
        )
        .toPromise()

      if (error) {
        setGeneralError(error.message)
        return
      }

      if (!data) {
        return
      }

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Wykonane zlecenia')
      worksheet.columns = [
        {
          header: 'ID',
          key: 'id',
          width: 10,
        },
        {
          header: 'Data zakończenia',
          key: 'currentStateDate',
          width: 25,
        },
        {
          header: 'Lista badań',
          key: 'testNames',
          width: 120,
        },
        {
          header: 'Należność',
          key: 'total',
          width: 20,
        },
      ]
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { size: 14, color: { argb: '00FFFFFF' }, bold: true }
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF000000' },
        }
      })

      for (const order of data.order) {
        const row = worksheet.addRow({
          id: order.id,
          currentStateDate: moment(order.currentStateDate).format(
            'DD/MM/yyyy HH:mm:ss'
          ),
          testNames: order.orderTests
            .map(({ test: { name } }) => `• ${name}`)
            .join('\r\n'),
          total: order.orderTests.reduce(
            (sum, orderTest) => sum + orderTest.cost,
            0
          ),
        })
        row.eachCell((cell, i) => {
          cell.alignment = { wrapText: true, vertical: 'top' }
          cell.font = { size: 14, bold: i === 4 }
          if (i === 2) {
            cell.numFmt = 'DD/MM/yyyy\\ HH:mm:ss'
            cell.alignment.horizontal = 'right'
          }
          if (i === 4) {
            cell.numFmt = '#,##0.00 zł'
          }
        })
      }

      return workbook.xlsx.writeBuffer()
    },
    [client, dateFrom, dateTo]
  )

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<
          GetLaboratoryCostSummaryQuery,
          GetLaboratoryCostSummaryQueryVariables
        >(
          GetLaboratoryCostSummaryDocument,
          { dateFrom, dateTo, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setLaboratories(
          refetch ? data.laboratory : laboratories.concat(data.laboratory)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.laboratory_aggregate.aggregate?.count || 0)
      }
    },
    [client, laboratories, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [dateFrom, dateTo])

  return (
    <div>
      <Meta title={pageTitle} />
      <Row style={{ width: '50%', marginTop: '32px', marginBottom: '32px' }}>
        <Col>
          <small style={{ color: '#666' }}>Data od:</small>
          <Form.Control
            type="date"
            value={moment(dateFrom).format('yyyy-MM-DD')}
            onChange={(event) =>
              setDateFrom(moment(event.target.value).startOf('day').toDate())
            }
          />
        </Col>
        <Col>
          <small style={{ color: '#666' }}>Data do:</small>
          <Form.Control
            type="date"
            value={moment(dateTo).format('yyyy-MM-DD')}
            onChange={(event) =>
              setDateTo(moment(event.target.value).endOf('day').toDate())
            }
          />
        </Col>
      </Row>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <InfiniteTable<GetLaboratoryCostSummaryQuery['laboratory'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Imię i nazwisko', accessor: 'name' },
          {
            Header: 'Liczba badań',
            accessor: (rowData) => rowData.costSummary?.[0]?.testCount,
          },
          {
            Header: 'Liczba zleceń',
            accessor: (rowData) => rowData.costSummary?.[0]?.orderCount,
          },
          {
            Header: 'Należność',
            accessor: (rowData) => (
              <div>
                {(rowData.costSummary?.[0]?.costSum || 0).toFixed(2)} zł
              </div>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={async () => {
                      const data = await createLaboratoryReport(rowData.id)
                      if (data) {
                        download({
                          filename: `Raport Laboratorium ${rowData.id} ${moment(
                            dateFrom
                          ).format('yyyy-MM-DD')}-${moment(dateTo).format(
                            'yyyy-MM-DD'
                          )}.xlsx`,
                          mimeType:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          data,
                        })
                      }
                    }}
                  >
                    <CloudDownload size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default LaboratoryReports
