import { Nav, Container, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useLogoutMutation } from '../generated/urql.administrator'
import { useContext } from 'react'
import { AuthContext } from '../provider/auth'
import { BoxArrowRight } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'

const Menu = () => {
  const [{ fetching }, logout] = useLogoutMutation()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  async function doLogout() {
    await logout()
    await auth.clearTokens()
    navigate('/')
  }

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>HomeLab</Navbar.Brand>
        </LinkContainer>
        {auth.isLogged && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>Osoby pobierające</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Klienci" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/clients">
                    Przeglądaj
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/push-notifications">
                    Powiadomienia push
                  </NavDropdown.Item>
                </NavDropdown>
                <LinkContainer to="/orders">
                  <Nav.Link>Zamówienia</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/laboratories">
                  <Nav.Link>Laboratoria</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Raporty" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/nurse/reports">
                    Osoby pobierające
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/laboratory/reports">
                    Laboratoria
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/logs">
                    Dziennik zdarzeń
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Konfiguracja" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/tests">Badania</NavDropdown.Item>
                  <NavDropdown.Item href="/trainings">Szkolenia</NavDropdown.Item>
                  <NavDropdown.Item href="/consultations">Konsultacje</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/rates">Stawki</NavDropdown.Item>
                  <NavDropdown.Item href="/materials">
                    Materiały
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/discounts">
                    Kody rabatowe
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/tags">Kategorie</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/administrators">Administratorzy</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <Nav.Link onClick={doLogout} disabled={fetching}>
                  <BoxArrowRight size={24} />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  )
}

export default Menu
