import Meta from '../components/Meta'
import { FC, useContext, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useLoginMutation } from '../generated/urql.anonymous'
import { AuthContext } from '../provider/auth'
import { LinkContainer } from 'react-router-bootstrap'

const Login: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string
    password: string
  }>()
  const [generalError, setGeneralError] = useState<string>()
  const [, login] = useLoginMutation()
  const { setAccessToken } = useContext(AuthContext)

  async function doSubmit({
    email,
    password,
  }: {
    email: string
    password: string
  }) {
    setGeneralError(undefined)

    const { data, error } = await login({ email, password })

    if (error) {
      switch (error.graphQLErrors[0].extensions.code) {
        case 'invalid-credentials':
          setGeneralError('Nieprawidłowy email lub hasło')
          return
        default:
          setGeneralError(error.message)
      }
      return
    }

    if (data?.administrator_login?.accessToken) {
      setAccessToken(data?.administrator_login?.accessToken)
    }

    console.log('logged in')
  }

  return (
    <>
      <Meta title="Logowanie" />

      <Container>
        <Row className="justify-content-md-center mt-5 mb-5">
          <Col md="4">
            <h1>Logowanie</h1>

            <Form onSubmit={handleSubmit(doSubmit)} className="mt-4">
              {generalError && <Alert variant="danger">{generalError}</Alert>}

              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.email}
                  type="email"
                  placeholder="Email"
                  autoComplete="new-password"
                  {...register('email', {
                    required: 'Proszę podać adres email',
                  })}
                />
                {errors.email && (
                  <Form.Control.Feedback>
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Hasło"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.password}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Hasło"
                  {...register('password', { required: 'Proszę podać hasło' })}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>

              <div className="flex-1 d-flex justify-content-between align-items-center">
                <LinkContainer to="/passwordreset/init">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>Przypomnij hasło</a>
                </LinkContainer>
                <Button variant="primary" type="submit">
                  Zaloguj
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
