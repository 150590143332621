import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import {
  GetMaterialDocument,
  GetMaterialQuery,
  GetMaterialQueryVariables,
  useInsertMaterialMutation,
  useUpdateMaterialMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

type FormMaterial = {
  name: string
}

const Materials: FC = () => {
  const pageTitle = 'Stawka'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetMaterialFetching] = useState(false)
  const [{ fetching: updateMaterialFetching }, updateMaterial] =
    useUpdateMaterialMutation()
  const [{ fetching: insertMaterialFetching }, insertMaterial] =
    useInsertMaterialMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormMaterial>({
    defaultValues: {
      name: '',
    },
  })

  useEffect(() => {
    if (id) {
      setGetMaterialFetching(true)
      client
        .query<GetMaterialQuery, GetMaterialQueryVariables>(
          GetMaterialDocument,
          {
            id: parseInt(id),
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.material_by_pk) {
            reset({
              name: data.material_by_pk.name,
            })
          }
        })
        .finally(() => setGetMaterialFetching(false))
    }
  }, [id, client, reset])

  async function doSaveMaterial(material: FormMaterial) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateMaterial({
          ...material,
          id: parseInt(id),
        })
      : await insertMaterial({
          ...material,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_material_one' in data) {
      navigate(`/material/${data.insert_material_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/materials')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSaveMaterial)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano materiał</Toast.Body>
          </Toast>
        </ToastContainer>

        <h4>Materiał</h4>
        <FloatingLabel label="Nazwa" className="mb-3">
          <Form.Control
            isInvalid={!!errors.name}
            type="text"
            placeholder="Nazwa"
            {...register('name', {
              required: 'Proszę podać nazwę materiału',
            })}
          />
          {errors.name && (
            <Form.Control.Feedback>{errors.name.message}</Form.Control.Feedback>
          )}
        </FloatingLabel>
        <Button
          variant="primary"
          type="submit"
          disabled={updateMaterialFetching || insertMaterialFetching}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default Materials
