import Meta from '../../components/Meta'
import { FC, useContext, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { usePasswordResetFinishMutation } from '../../generated/urql.anonymous'
import { AuthContext } from '../../provider/auth'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '../../lib/query'
import { PasswordRegex } from '../../lib/patterns'

const PasswordResetFinish: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{
    password: string
    password2: string
  }>()
  const [generalError, setGeneralError] = useState<string>()
  const [{ fetching: passwordResetFinishFetching }, passwordResetFinish] =
    usePasswordResetFinishMutation()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const query = useQuery()

  async function doPasswordResetFinish({ password }: { password: string }) {
    setGeneralError(undefined)

    const code = query.get('code')
    if (!code) {
      setGeneralError('Brak kodu weryfikacyjnego')
      return
    }

    const email = query.get('email')
    if (!email) {
      setGeneralError('Brak adresu email')
      return
    }

    const { data, error } = await passwordResetFinish({
      email,
      password,
      code,
    })

    if (error) {
      setGeneralError(error.message)
    }

    if (data?.administrator_password_reset_finish) {
      auth.setAccessToken(data?.administrator_password_reset_finish.accessToken)
      navigate('/')
    }
  }

  return (
    <>
      <Meta title="Logowanie" />

      <Container>
        <Row className="justify-content-md-center mt-5 mb-5">
          <Col md="4">
            <h1>Przypomnienie hasła</h1>
            <p>Podaj nowe hasło do swojego konta.</p>

            <Form
              onSubmit={handleSubmit(doPasswordResetFinish)}
              className="mt-4"
            >
              {generalError && <Alert variant="danger">{generalError}</Alert>}

              <FloatingLabel
                controlId="floatingPassword"
                label="Nowe hasło"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.password}
                  type="password"
                  placeholder="Nowe hasło"
                  autoComplete="new-password"
                  {...register('password', {
                    required: 'Proszę podać hasło',
                    pattern: {
                      value: PasswordRegex,
                      message:
                        'Hasło musi mieć minimum 12 znaków, przynajmniej jedną dużą literę, jedną cyfrę oraz znak specjalny',
                    },
                  })}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingPassword"
                label="Hasło"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.password2}
                  type="password"
                  placeholder="Hasło"
                  autoComplete="new-password"
                  {...register('password2', {
                    required: 'Proszę podać ponownie hasło',
                    validate: (value) =>
                      value !== watch('password')
                        ? 'Podane hasła są różne'
                        : undefined,
                  })}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.password2?.message}
                </Form.Control.Feedback>
              </FloatingLabel>

              <div className="flex-1 d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={passwordResetFinishFetching}
                >
                  Zresetuj hasło
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PasswordResetFinish
