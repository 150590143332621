import Meta from '../components/Meta'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  GetTrainingsDocument,
  GetTrainingsQuery,
  GetTrainingsQueryVariables,
  useDeleteTrainingMutation,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Alert, Button, ButtonGroup, Image } from 'react-bootstrap'
import { PencilSquare, Plus, Trash } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'
import { Locale } from '../lib/translations'
import { guessStorageAddress } from '../lib/api'
import { AuthContext } from '../provider/auth'

const BatchSize = 20

export function getStorageUrl(fileId: number, accessToken?: string) {
  return (
    guessStorageAddress() +
    `/files/${fileId}${accessToken ? `?accessToken=${accessToken}` : ''}`
  )
}

const Trainings: FC = () => {
  // page content
  const pageTitle = 'Szkolenia'
  const auth = useContext(AuthContext)
  const [trainings, setTrainings] = useState<GetTrainingsQuery['training']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => trainings, [trainings])
  const client = useClient()
  const navigate = useNavigate()
  const [generalError, setGeneralError] = useState<string>()
  const [, deleteTraining] = useDeleteTrainingMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetTrainingsQuery, GetTrainingsQueryVariables>(
          GetTrainingsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setTrainings(refetch ? data.training : trainings.concat(data.training))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.training_aggregate.aggregate?.count || 0)
      }
    },
    [client, trainings, offset]
  )

  const doDeleteTraining = useCallback(
    async (id: number) => {
      if (window.confirm('Czy napewno chcesz usunąć szkolenie?')) {
        await deleteTraining({ id })
        await loadMoreRows({ refetch: true })
      }
    },
    [deleteTraining, loadMoreRows]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <Button
          className="d-flex align-items-center"
          onClick={() => navigate('/training')}
        >
          <Plus size={20} />
          &nbsp;Dodaj
        </Button>
      </div>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <InfiniteTable<GetTrainingsQuery['training'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Podgląd',
            accessor: ({ previewFileId }) =>
              !!auth.accessToken && (
                <Image
                  src={getStorageUrl(previewFileId, auth.accessToken)}
                  thumbnail
                  style={{ width: '100px' }}
                />
              ),
          },
          {
            Header: 'Nazwa',
            accessor: ({ name_i18n }) => (
              <p className="my-0">{name_i18n[Locale.pl_PL]}</p>
            ),
          },
          {
            Header: 'Cena',
            accessor: ({ price }) => (
              <span className="text-nowrap">{price} zł</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/training/${rowData.id}`)}
                    onContextMenu={(e) => {
                      e.preventDefault()
                      window
                        .open(
                          `${window.location.origin}/training/${rowData.id}`,
                          '_blank'
                        )
                        ?.focus()
                    }}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => doDeleteTraining(rowData.id)}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Trainings
