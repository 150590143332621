import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { Alert, Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router'
import ConsultationSettings from './consultation/ConsultationSettings'
import ConsultationSlots from './consultation/ConsultationSlots'
import {
  GetConsultationDocument,
  GetConsultationQuery,
  GetConsultationQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'

const Consultation: FC = () => {
  const pageTitle = 'Ustawienia'
  const client = useClient()
  const { id } = useParams()
  const [deletedAt, setDeletedAt] = useState<string | null>()

  useEffect(() => {
    if (id) {
      client
        .query<GetConsultationQuery, GetConsultationQueryVariables>(
          GetConsultationDocument,
          {
            id: parseInt(id),
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.consultation_by_pk) {
            setDeletedAt(data.consultation_by_pk.deletedAt)
          }
        })
    }
  }, [id, client])

  if (!id) {
    return <ConsultationSettings />
  }

  return (
    <div>
      <Meta title={pageTitle} />
      {deletedAt && (
        <Alert variant="danger">Konsultacja usunięta {deletedAt}</Alert>
      )}
      <Tabs defaultActiveKey="settings" className="mb-3 mt-3">
        <Tab eventKey="settings" title="Ustawienia">
          <ConsultationSettings id={parseInt(id)} readOnly={!!deletedAt} />
        </Tab>
        <Tab eventKey="consultationSlots" title="Terminarz">
          <ConsultationSlots id={parseInt(id)} readOnly={!!deletedAt} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Consultation
