import Meta from '../components/Meta'
import { FC } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router'
import LaboratorySettings from './laboratory/LaboratorySettings'
import LaboratoryUsers from './laboratory/LaboratoryUsers'
import LaboratoryCollectionPoints from './laboratory/LaboratoryCollectionPoints'
import LaboratoryTests from './laboratory/LaboratoryTests'

const Laboratory: FC = () => {
  const pageTitle = 'Ustawienia'
  const { id } = useParams()

  if (!id) {
    return <LaboratorySettings />
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <Tabs defaultActiveKey="settings" className="mb-3 mt-3">
        <Tab eventKey="settings" title="Ustawienia">
          <LaboratorySettings id={parseInt(id)} />
        </Tab>
        <Tab eventKey="collectionPoints" title="Punkty pobrań">
          <LaboratoryCollectionPoints id={parseInt(id)} />
        </Tab>
        <Tab eventKey="users" title="Użytkownicy">
          <LaboratoryUsers id={parseInt(id)} />
        </Tab>
        <Tab eventKey="laboratoryTests" title="Badania">
          <LaboratoryTests id={parseInt(id)} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Laboratory
