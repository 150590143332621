import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'
import { useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { CookiesProvider } from 'react-cookie'
import { Route, Routes } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import Layout from './layout/Layout'
import { getClient } from './lib/client'
import NotFound from './pages/NotFound'
import { AuthContext, useAuth } from './provider/auth'
import Login from './pages/Login'
import PasswordResetInit from './pages/password-reset/PasswordResetInit'
import PasswordResetFinish from './pages/password-reset/PasswordResetFinish'
import Nurses from './pages/Nurses'
import Orders from './pages/Orders'
import Settings from './pages/Settings'
import Nurse from './pages/Nurse'
import Laboratories from './pages/Laboratories'
import Laboratory from './pages/Laboratory'
import NurseReports from './pages/NurseReports'
import LaboratoryReports from './pages/LaboratoryReports'
import Tests from './pages/Tests'
import Test from './pages/Test'
import Discounts from './pages/Discounts'
import Discount from './pages/Discount'
import Rates from './pages/Rates'
import Rate from './pages/Rate'
import Materials from './pages/Materials'
import Material from './pages/Material'
import Order from './pages/Order'
import Tags from './pages/Tags'
import Tag from './pages/Tag'
import Logs from './pages/Logs'
import PushNotifications from './pages/PushNotifications'
import PushNotification from './pages/PushNotification'
import Clients from './pages/Clients'
import Client from './pages/Client'
import Administrators from './pages/Administrators'
import Administrator from './pages/Administrator'
import Trainings from './pages/Trainings'
import Training from './pages/Training'
import Consultations from './pages/Consultations'
import Consultation from './pages/Consultation'
import OrderBulkAdd from "./pages/OrderBulkAdd";

const App = () => {
  const auth = useAuth()
  const client = useMemo(() => getClient(auth), [auth])

  return (
    <CookiesProvider>
      <AuthContext.Provider value={auth}>
        <UrqlProvider value={client}>
          <Layout>
            <Container>
              <Routes>
                {!auth.accessToken && (
                  <>
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/passwordreset/init"
                      element={<PasswordResetInit />}
                    />
                    <Route
                      path="/passwordreset/finish"
                      element={<PasswordResetFinish />}
                    />
                  </>
                )}
                {!!auth.accessToken && (
                  <>
                    <Route path="/" element={<Nurses />} />
                    <Route path="/nurse/reports" element={<NurseReports />} />
                    <Route path="/nurse/:id" element={<Nurse />} />
                    <Route path="/nurse" element={<Nurse />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/client/:id" element={<Client />} />
                    <Route
                      path="/administrators"
                      element={<Administrators />}
                    />
                    <Route
                      path="/administrator/:id"
                      element={<Administrator />}
                    />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/order/bulk" element={<OrderBulkAdd />} />
                    <Route path="/order/:id" element={<Order />} />
                    <Route path="/laboratories" element={<Laboratories />} />
                    <Route
                      path="/laboratory/reports"
                      element={<LaboratoryReports />}
                    />
                    <Route path="/laboratory/:id" element={<Laboratory />} />
                    <Route path="/laboratory" element={<Laboratory />} />
                    <Route path="/tests" element={<Tests />} />
                    <Route path="/test/:id" element={<Test />} />
                    <Route path="/test" element={<Test />} />
                    <Route path="/trainings" element={<Trainings />} />
                    <Route path="/training/:id" element={<Training />} />
                    <Route path="/training" element={<Training />} />
                    <Route path="/consultations" element={<Consultations />} />
                    <Route
                      path="/consultation/:id"
                      element={<Consultation />}
                    />
                    <Route path="/consultation" element={<Consultation />} />
                    <Route path="/discounts" element={<Discounts />} />
                    <Route path="/discount/:id" element={<Discount />} />
                    <Route path="/discount" element={<Discount />} />
                    <Route path="/rates" element={<Rates />} />
                    <Route path="/rate/:id" element={<Rate />} />
                    <Route path="/rate" element={<Rate />} />
                    <Route path="/materials" element={<Materials />} />
                    <Route path="/material/:id" element={<Material />} />
                    <Route path="/material" element={<Material />} />
                    <Route path="/tags" element={<Tags />} />
                    <Route path="/tag/:id" element={<Tag />} />
                    <Route path="/tag" element={<Tag />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/logs" element={<Logs />} />
                    <Route
                      path="/push-notifications"
                      element={<PushNotifications />}
                    />
                    <Route
                      path="/push-notification/:id"
                      element={<PushNotification />}
                    />
                    <Route
                      path="/push-notification"
                      element={<PushNotification />}
                    />
                    <Route element={<NotFound />} />
                  </>
                )}
              </Routes>
            </Container>
          </Layout>
        </UrqlProvider>
      </AuthContext.Provider>
    </CookiesProvider>
  )
}

export default App
