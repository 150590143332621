import Meta from '../../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { Controller, useForm } from 'react-hook-form'
import {
  Discount_Type_Enum_Enum,
  GetDiscountDocument,
  GetDiscountQuery,
  GetDiscountQueryVariables,
  useInsertDiscountMutation,
  useUpdateDiscountMutation,
} from '../../generated/urql.administrator'
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

type FormDiscount = Omit<
  NonNullable<GetDiscountQuery['discount_by_pk']>,
  '__typename' | 'id' | 'discountTests'
> & { discountTestIds: number[] }

const DiscountSettings: FC<{
  id?: number
  readOnly?: boolean
  onDiscountTypeChange?: (type: Discount_Type_Enum_Enum) => void
}> = ({ id, readOnly, onDiscountTypeChange }) => {
  const pageTitle = 'Kod rabatowy'
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetDiscountFetching] = useState(false)
  const [{ fetching: updateDiscountFetching }, updateDiscount] =
    useUpdateDiscountMutation()
  const [{ fetching: insertDiscountFetching }, insertDiscount] =
    useInsertDiscountMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormDiscount>({
    defaultValues: {
      code: '',
      dateFrom: '',
      dateTo: '',
      percent: 0,
      type: Discount_Type_Enum_Enum.SomeTests,
      discountTestIds: [],
    },
  })

  const discountType = watch('type')
  useEffect(
    () => onDiscountTypeChange && onDiscountTypeChange(discountType),
    [discountType, onDiscountTypeChange]
  )

  useEffect(() => {
    if (id) {
      setGetDiscountFetching(true)
      client
        .query<GetDiscountQuery, GetDiscountQueryVariables>(
          GetDiscountDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.discount_by_pk) {
            reset({
              code: data.discount_by_pk.code || '',
              dateFrom: data.discount_by_pk.dateFrom
                ? moment(data.discount_by_pk.dateFrom).format('yyyy-MM-DD')
                : '',
              dateTo: data.discount_by_pk.dateTo
                ? moment(data.discount_by_pk.dateTo).format('yyyy-MM-DD')
                : '',
              percent: data.discount_by_pk.percent || 0,
              type: data.discount_by_pk.type,
            })
          }
        })
        .finally(() => setGetDiscountFetching(false))
    }
  }, [id, client, reset])

  async function doSaveDiscount({
    discountTestIds,
    dateFrom,
    dateTo,
    code,
    ...discount
  }: FormDiscount) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateDiscount({
          ...discount,
          code: code.toUpperCase(),
          dateFrom: dateFrom || undefined,
          dateTo: dateTo || undefined,
          id,
        })
      : await insertDiscount({
          ...discount,
          code: code.toUpperCase(),
          dateFrom: dateFrom || undefined,
          dateTo: dateTo || undefined,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_discount_one' in data) {
      navigate(`/discount/${data.insert_discount_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <Form onSubmit={handleSubmit(doSaveDiscount)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano rabat</Toast.Body>
          </Toast>
        </ToastContainer>

        <Controller
          name="type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Rodzaj
              </Form.Label>
              <Col sm="10">
                <Form.Select value={value} onChange={onChange}>
                  <option value={Discount_Type_Enum_Enum.SomeTests}>
                    Kilka badań
                  </option>
                  <option value={Discount_Type_Enum_Enum.AllTests}>
                    Wszystkie badania
                  </option>
                  <option value={Discount_Type_Enum_Enum.WholeOrder}>
                    Całe zamówienie
                  </option>
                  <option value={Discount_Type_Enum_Enum.WholeOrderWithTests}>
                    Całe zamówienie dla wybranych badań
                  </option>
                  <option value={Discount_Type_Enum_Enum.CollectionCost}>
                    Koszt pobrania
                  </option>
                </Form.Select>
              </Col>
            </Form.Group>
          )}
        />

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Kod
          </Form.Label>
          <Col sm="10">
            <Form.Control
              isInvalid={!!errors.code}
              type="text"
              placeholder="Kod"
              {...register('code', {
                required: 'Proszę podać kod',
              })}
            />
            {errors.code && (
              <Form.Control.Feedback>
                {errors.code.message}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Rabat
          </Form.Label>
          <Col sm="10">
            <Form.Control
              isInvalid={!!errors.percent}
              type="text"
              placeholder="Rabat %"
              {...register('percent', {
                required: 'Proszę podać wartość procentową rabatu',
                valueAsNumber: true,
              })}
            />
            {errors.percent && (
              <Form.Control.Feedback>
                {errors.percent.message}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Controller
          name="dateFrom"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Obowiązuje do
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  isInvalid={!!errors.dateTo}
                  type="date"
                  placeholder="Obowiązuje od"
                  value={value}
                  onChange={onChange}
                />
                {errors.dateFrom && (
                  <Form.Control.Feedback>
                    {errors.dateFrom.message}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Form.Group>
          )}
        />

        <Controller
          name="dateTo"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Obowiązuje do
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  isInvalid={!!errors.dateTo}
                  type="date"
                  placeholder="Obowiązuje do"
                  value={value}
                  onChange={onChange}
                />
                {errors.dateTo && (
                  <Form.Control.Feedback>
                    {errors.dateTo.message}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Form.Group>
          )}
        />

        <Button
          variant="primary"
          type="submit"
          disabled={
            updateDiscountFetching || insertDiscountFetching || !!readOnly
          }
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default DiscountSettings
