import Meta from '../components/Meta'
import { FC, useEffect, useState, useRef } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import {
  assertPolygon,
  calculateInitialRegion,
  fromLinearRing,
  Polygon as PolygonType,
  toLinearRing,
} from '../lib/geography'
import {
  GetAvailableTestsQuery,
  GetMaterialsQuery,
  GetNurseDocument,
  GetNurseQuery,
  GetNurseQueryVariables,
  useGetAvailableTestsQuery,
  useGetMaterialsQuery,
  useInsertNurseMutation,
  useUpdateNurseMutation,
  useUpdateNursePasswordMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import {
  DrawingManager,
  GoogleMap,
  LoadScript,
  LoadScriptProps,
  Polygon,
} from '@react-google-maps/api'
import { ChevronLeft, Plus, X } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { testPriceRangeToString } from '../lib/test'
import { MapAreaEditor, MapAreaEditorType } from '../components/MapAreaEditor'

type FormNurse = {
  email: string
  password: string
  name: string
  phoneNumber: string
  orderAreaPolygons: PolygonType['coordinates']
  labAreaPolygons: PolygonType['coordinates']
  testIds: number[]
  materialIds: number[]
  geoEnabled: boolean
  auth2FAEnabled: boolean
}

const googleLibraries: LoadScriptProps['libraries'] = ['drawing']

const Nurse: FC = () => {
  const pageTitle = 'Osoba pobierająca'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetNurseFetching] = useState(false)
  const [{ fetching: updateNurseFetching }, updateNurse] =
    useUpdateNurseMutation()
  const [{ fetching: insertNurseFetching }, insertNurse] =
    useInsertNurseMutation()
  const [, updateNursePassword] = useUpdateNursePasswordMutation()
  const [{ data: availableTestsData, fetching: availableTestsFetching }] =
    useGetAvailableTestsQuery({ variables: { limit: 0xfffff } })
  const [{ data: materialsData, fetching: materialsFetching }] =
    useGetMaterialsQuery({ variables: { limit: 0xfffff } })
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const [selectedTestId, setSelectedTestId] = useState<number>()
  const [selectedMaterialId, setSelectedMaterialId] = useState<number>()
  const orderMapAreaEditorRef = useRef<MapAreaEditorType>(null)
  const labMapAreaEditorRef = useRef<MapAreaEditorType>(null)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    setValue,
  } = useForm<FormNurse>({
    defaultValues: {
      email: '',
      password: '',
      name: '',
      phoneNumber: '',
      orderAreaPolygons: [],
      labAreaPolygons: [],
      testIds: [],
      materialIds: [],
      geoEnabled: true,
      auth2FAEnabled: true,
    },
  })

  useEffect(() => {
    if (id) {
      setGetNurseFetching(true)
      client
        .query<GetNurseQuery, GetNurseQueryVariables>(GetNurseDocument, {
          id: parseInt(id),
        })
        .toPromise()
        .then(({ data }) => {
          const orderArea = data?.nurse_by_pk?.orderArea || {
            type: 'Polygon',
            coordinates: [],
          }
          const labArea = data?.nurse_by_pk?.labArea || {
            type: 'Polygon',
            coordinates: [],
          }

          assertPolygon(orderArea)
          assertPolygon(labArea)

          const orderAreaPolygons = fromLinearRing(orderArea).coordinates
          const labAreaPolygons = fromLinearRing(labArea).coordinates

          if (data?.nurse_by_pk) {
            reset({
              email: data.nurse_by_pk.email || '',
              password: '',
              name: data.nurse_by_pk.name || '',
              phoneNumber: data.nurse_by_pk.phoneNumber || '',
              orderAreaPolygons,
              labAreaPolygons,
              testIds: data.nurse_by_pk.nurseTests.map(({ testId }) => testId),
              materialIds: data.nurse_by_pk.nurseMaterials.map(
                ({ materialId }) => materialId
              ),
              geoEnabled: data.nurse_by_pk.geoEnabled,
              auth2FAEnabled: data.nurse_by_pk.auth2FAEnabled,
            })

            if (Array.isArray(orderAreaPolygons) && orderAreaPolygons.length) {
              orderMapAreaEditorRef.current?.center()
            }

            if (Array.isArray(labAreaPolygons) && labAreaPolygons.length) {
              labMapAreaEditorRef.current?.center()
            }
          }
        })
        .finally(() => setGetNurseFetching(false))
    }
  }, [id, client, reset])

  async function doSaveNurse({
    orderAreaPolygons,
    labAreaPolygons,
    testIds,
    materialIds,
    password,
    ...nurse
  }: FormNurse) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateNurse({
          ...nurse,
          orderArea: toLinearRing({
            type: 'Polygon',
            coordinates: orderAreaPolygons,
          }),
          labArea: toLinearRing({
            type: 'Polygon',
            coordinates: labAreaPolygons,
          }),
          nurseTests: testIds.map((testId) => ({
            nurseId: parseInt(id),
            testId,
          })),
          nurseMaterials: materialIds.map((materialId) => ({
            nurseId: parseInt(id),
            materialId,
          })),
          id: parseInt(id),
        })
      : await insertNurse({
          ...nurse,
          orderArea: toLinearRing({
            type: 'Polygon',
            coordinates: orderAreaPolygons,
          }),
          labArea: toLinearRing({
            type: 'Polygon',
            coordinates: labAreaPolygons,
          }),
          nurseTests: testIds.map((testId) => ({ testId })),
          nurseMaterials: materialIds.map((materialId) => ({ materialId })),
        })

    if (password) {
      await updateNursePassword({
        email: nurse.email,
        password,
      })
    }

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_nurse_one' in data) {
      navigate(`/nurse/${data.insert_nurse_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <LoadScript
        googleMapsApiKey="AIzaSyBeR-j3Q7in_4YcIrqHWsyS2ZqQZh3XwBY"
        language="pl"
        libraries={googleLibraries as any}
      >
        <div className="mt-3 mb-3 d-flex justify-content-start">
          <ButtonGroup>
            <Button
              className="d-flex align-items-center"
              onClick={() => navigate('/')}
            >
              <ChevronLeft size={20} />
              &nbsp;Wróć
            </Button>
          </ButtonGroup>
        </div>
        <Form
          onSubmit={handleSubmit(doSaveNurse, console.log)}
          className="mt-4"
        >
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <ToastContainer
            position="bottom-center"
            className="mb-4 bottom-center-toast-container"
          >
            <Toast
              onClose={() => setSuccessToast(false)}
              show={successToast}
              autohide
              animation={true}
              bg="success"
            >
              <Toast.Header>
                <strong className="me-auto">Sukces</strong>
              </Toast.Header>
              <Toast.Body>
                Pomyślnie zapisano parametry osoby pobierającej
              </Toast.Body>
            </Toast>
          </ToastContainer>

          <Row>
            <Col>
              <h4>Dane osoby pobierającej</h4>
              <FloatingLabel label="Email" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.email}
                  type="email"
                  autoComplete="new-password"
                  placeholder="Email"
                  {...register('email', {
                    required: 'Proszę podać email',
                    pattern: {
                      value:
                        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                      message: 'Nieprawidłowy format',
                    },
                  })}
                />
                {errors.email && (
                  <Form.Control.Feedback>
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel label="Hasło" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.password}
                  type="password"
                  placeholder="Hasło"
                  autoComplete="new-password"
                  {...register(
                    'password',
                    id ? undefined : { required: 'Proszę podać hasło' }
                  )}
                />
                {errors.password && (
                  <Form.Control.Feedback>
                    {errors.password.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel label="Imie i nazwisko" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.name}
                  type="text"
                  placeholder="Imię i nazwisko"
                  {...register('name', {
                    required: 'Proszę podać imię i nazwisko',
                  })}
                />
                {errors.name && (
                  <Form.Control.Feedback>
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel label="Numer telefonu" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.phoneNumber}
                  type="text"
                  placeholder="Numer telefonu"
                  {...register('phoneNumber', {
                    required: 'Proszę podać numer telefonu',
                  })}
                />
                {errors.phoneNumber && (
                  <Form.Control.Feedback>
                    {errors.phoneNumber.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <Form.Check
                type="switch"
                label="Geolokalizacja"
                {...register('geoEnabled')}
              />
              {errors.geoEnabled && (
                <Form.Control.Feedback>
                  {errors.geoEnabled.message}
                </Form.Control.Feedback>
              )}
              <Form.Check
                type="switch"
                label="Uwierzytelnianie dwuskładnikowe"
                {...register('auth2FAEnabled')}
              />
              {errors.auth2FAEnabled && (
                <Form.Control.Feedback>
                  {errors.auth2FAEnabled.message}
                </Form.Control.Feedback>
              )}
              <h4 className="mt-4">Wykonywane badania</h4>
              <Controller
                name="testIds"
                control={control}
                render={({ field: { value: testIds, onChange } }) => {
                  const availableTests = (
                    availableTestsData?.test || []
                  ).filter(({ id }) => !testIds.includes(id))

                  const selectedTests = testIds
                    .map((testId) =>
                      (availableTestsData?.test || []).find(
                        ({ id }) => id === testId
                      )
                    )
                    .filter(Boolean) as GetAvailableTestsQuery['test']

                  return (
                    <>
                      <ListGroup>
                        {selectedTests.map((test) => (
                          <ListGroupItem
                            key={test.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div>
                              <div className="fw-bold">{test.name}</div>
                              {test.laboratoryTests_aggregate.aggregate && (
                                <small>
                                  {testPriceRangeToString(
                                    test.laboratoryTests_aggregate.aggregate
                                  )}
                                </small>
                              )}
                            </div>
                            <Button
                              variant="outline-danger"
                              className="d-flex align-items-center p-0"
                              onClick={() =>
                                onChange(
                                  testIds.filter((testId) => testId !== test.id)
                                )
                              }
                            >
                              <X size={20} />
                            </Button>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                      <div className="d-flex mt-3">
                        <Form.Select
                          disabled={
                            !availableTests.length || availableTestsFetching
                          }
                          value={selectedTestId}
                          onChange={({ target: { value } }) =>
                            setSelectedTestId(parseInt(value) || undefined)
                          }
                        >
                          <option>Proszę wybrać badanie</option>
                          {availableTests.map((test) => (
                            <option value={test.id} key={test.id}>
                              {test.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Button
                          className="ms-3 d-flex align-items-center"
                          disabled={
                            !availableTests.length ||
                            availableTestsFetching ||
                            !selectedTestId
                          }
                          onClick={() =>
                            selectedTestId &&
                            onChange([...testIds, selectedTestId])
                          }
                        >
                          <Plus size={20} />
                          &nbsp;Dodaj
                        </Button>
                      </div>
                    </>
                  )
                }}
              />
              <h4 className="mt-4">Materiały</h4>
              <Controller
                name="materialIds"
                control={control}
                rules={{
                  validate: (value) =>
                    value.length
                      ? undefined
                      : 'Proszę dodać przynajmniej jeden materiał',
                }}
                render={({ field: { value: materialIds, onChange } }) => {
                  const materials = (materialsData?.material || []).filter(
                    ({ id }) => !materialIds.includes(id)
                  )

                  const selectedMaterials = materialIds
                    .map((materialId) =>
                      (materialsData?.material || []).find(
                        ({ id }) => id === materialId
                      )
                    )
                    .filter(Boolean) as GetMaterialsQuery['material']

                  return (
                    <>
                      <ListGroup>
                        {selectedMaterials.map((material) => (
                          <ListGroupItem
                            key={material.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div>{material.name}</div>
                            <Button
                              variant="outline-danger"
                              className="d-flex align-items-center p-0"
                              onClick={() =>
                                onChange(
                                  materialIds.filter(
                                    (materialId) => materialId !== material.id
                                  )
                                )
                              }
                            >
                              <X size={20} />
                            </Button>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                      <div className="d-flex mt-3">
                        <Form.Select
                          disabled={!materials.length || materialsFetching}
                          value={selectedMaterialId}
                          onChange={({ target: { value } }) =>
                            setSelectedMaterialId(parseInt(value) || undefined)
                          }
                        >
                          <option>Proszę wybrać materiał</option>
                          {materials.map((material) => (
                            <option value={material.id} key={material.id}>
                              {material.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Button
                          className="ms-3 d-flex align-items-center"
                          disabled={
                            !materials.length ||
                            materialsFetching ||
                            !selectedMaterialId
                          }
                          onClick={() =>
                            selectedMaterialId &&
                            onChange([...materialIds, selectedMaterialId])
                          }
                        >
                          <Plus size={20} />
                          &nbsp;Dodaj
                        </Button>
                      </div>
                    </>
                  )
                }}
              />
            </Col>
            <Col>
              <h4>Obszar zamówień</h4>

              <Controller
                name="orderAreaPolygons"
                control={control}
                render={({ field: { value: polygons } }) => (
                  <MapAreaEditor
                    ref={orderMapAreaEditorRef}
                    value={polygons}
                    onChange={(polygons) =>
                      setValue('orderAreaPolygons', polygons)
                    }
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    multiplePolygons
                  />
                )}
              />

              <h4 className="mt-4">Obszar laboratoriów</h4>

              <Controller
                name="labAreaPolygons"
                control={control}
                render={({ field: { value: polygons } }) => (
                  <MapAreaEditor
                    ref={labMapAreaEditorRef}
                    value={polygons}
                    onChange={(polygons) =>
                      setValue('labAreaPolygons', polygons)
                    }
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    multiplePolygons
                  />
                )}
              />
            </Col>
          </Row>
          <Button
            variant="primary"
            type="submit"
            disabled={updateNurseFetching || insertNurseFetching}
          >
            Zapisz
          </Button>
        </Form>
      </LoadScript>
    </div>
  )
}

export default Nurse
