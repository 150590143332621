import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetAdministratorsDocument,
  GetAdministratorsQuery,
  GetAdministratorsQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const Administrators: FC = () => {
  // page content
  const pageTitle = 'Administratorzy'
  const [administrators, setAdministrators] = useState<
    GetAdministratorsQuery['administrator']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => administrators, [administrators])

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetAdministratorsQuery, GetAdministratorsQueryVariables>(
          GetAdministratorsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setAdministrators(
          refetch
            ? data.administrator
            : administrators.concat(data.administrator)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.administrator_aggregate.aggregate?.count || 0)
      }
    },
    [client, administrators, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <InfiniteTable<GetAdministratorsQuery['administrator'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Nazwa', accessor: 'name' },
          { Header: 'E-mail', accessor: 'email' },
          { Header: 'Nr telefonu', accessor: 'phoneNumber' },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/administrator/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Administrators
