import Meta from '../components/Meta'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap'
import {
  ChevronLeft,
  Envelope,
  Person,
  Telephone,
  X,
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import {
  GetLaboratoriesQuery,
  Order_By,
  Order_State_Enum_Enum,
  useGetLaboratoriesByAreaQuery,
  useGetNursesByOrderLocationQuery,
  useInsertOrdersMutation,
} from '../generated/urql.administrator'
import Select from 'react-select'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { findLocation, Point } from '../lib/geography'
import OrderAddModal, {
  FormOrder,
  OrderAddModalType,
} from '../components/OrderAddModal'

export type FormOrders = {
  collectionPointId: number
  nurseId: number
  address: string
  postalCode: string
  city: string
  location?: Point
  collectionDate?: string
  orders: FormOrder[]
}

const OrderBulkAdd: FC = () => {
  const pageTitle = 'Zbiorcze dodawanie zleceń'
  const navigate = useNavigate()
  const orderAddModalRef = useRef<OrderAddModalType>(null)
  const [generalError, setGeneralError] = useState<string>()
  const [{ fetching: insertOrdersFetching }, insertOrders] =
    useInsertOrdersMutation()
  const {
    control,
    handleSubmit,
    register,
    watch,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FormOrders>({
    defaultValues: {
      collectionPointId: -1,
      nurseId: -1,
      address: '',
      postalCode: '',
      city: '',
      location: undefined,
      collectionDate: undefined,
      orders: [],
    },
  })
  const collectionPointId = watch('collectionPointId')
  const nurseId = watch('nurseId')
  const location = watch('location')
  const address = watch('address')
  const postalCode = watch('postalCode')
  const city = watch('city')
  const orders = watch('orders')

  const [{ data: nursesData, fetching: nursesFetching }] =
    useGetNursesByOrderLocationQuery({
      pause: !location,
      variables: {
        location: location || {
          type: 'Point',
          coordinates: [0, 0],
        },
      },
    })

  const nurse = useMemo(
    () => (nursesData?.nurse || []).find(({ id }) => id === nurseId),
    [nurseId, nursesData?.nurse]
  )

  const [{ data: laboratoriesData, fetching: laboratoriesFetching }] =
    useGetLaboratoriesByAreaQuery({
      pause: !nurse,
      variables: {
        area: nurse?.labArea || { type: 'Polygon', coordinates: [] },
        orderBy: { shortName: Order_By.Asc },
      },
    })

  const collectionPoint = useMemo(
    () =>
      (laboratoriesData?.laboratory || []).reduce<
        | GetLaboratoriesQuery['laboratory'][number]['collectionPoints'][number]
        | undefined
      >(
        (collectionPoint, laboratory) =>
          collectionPoint ||
          laboratory.collectionPoints.find(
            ({ id }) => collectionPointId === id
          ),
        undefined
      ),
    [laboratoriesData?.laboratory, collectionPointId]
  )

  useEffect(() => setValue('location', undefined), [address, setValue])
  useEffect(() => setValue('location', undefined), [postalCode, setValue])
  useEffect(() => setValue('location', undefined), [city, setValue])
  useEffect(() => resetField('nurseId'), [location, resetField])
  useEffect(() => resetField('collectionPointId'), [nurseId, resetField])

  const collectionPointOptions = useMemo(
    () =>
      (laboratoriesData?.laboratory || []).map((laboratory) => ({
        label: `${laboratory.shortName} - ${laboratory.name}`,
        options: laboratory.collectionPoints.map((collectionPoint) => ({
          label: collectionPoint.address,
          value: collectionPoint.id,
        })),
      })),
    [laboratoriesData]
  )

  const nurseOptions = useMemo(
    () =>
      (nursesData?.nurse || []).map((nurse) => ({
        label: nurse.name,
        value: nurse.id,
      })),
    [nursesData?.nurse]
  )

  async function doInsertOrders(form: FormOrders) {
    setGeneralError(undefined)

    const { error } = await insertOrders({
      orders: form.orders.map((order) => ({
        orderCollections: {
          data: [
            {
              nurseId: form.nurseId,
              address: form.address,
              postalCode: form.postalCode,
              city: form.city,
              collectionDate: new Date(form.collectionDate || '').toISOString(),
              phoneNumber: order.phoneNumber,
              location: form.location,
              deliveryCost: 0,
              collectionCost: 0,
              collectionKitCost: 0,
            },
          ],
        },
        orderTests: {
          data: order.tests.map((test) => ({
            testId: test.id,
            cost: 0,
            firstName: order.firstName,
            lastName: order.lastName,
            identity: order.identity,
            collectionPointId: form.collectionPointId,
          })),
        },
        totalCost: 0,
        orderStates: {
          data: [{ state: Order_State_Enum_Enum.AwaitingClientConfirmation }],
        },
        ...(order.clientId
          ? { clientId: order.clientId }
          : {
              client: {
                data: {
                  email: order.email,
                  confirmedOn: new Date().toISOString(),
                  password: '',
                  clientProfiles: {
                    data: [
                      {
                        firstName: order.firstName,
                        lastName: order.lastName,
                        phoneNumber: order.phoneNumber,
                        isMain: true,
                      },
                    ],
                  },
                },
              },
            }),
      })),
      clientProfiles: orders
        .filter((order) => order.clientId && !order.clientProfileId)
        .map((order) => ({
          clientId: order.clientId,
          firstName: order.firstName,
          lastName: order.lastName,
          phoneNumber: order.phoneNumber,
        })),
    })

    if (error) {
      setGeneralError(error.message)
      console.error(error)
      return
    }

    navigate('/orders')
  }

  const checkLocation = useCallback(() => {
    findLocation({
      address: watch('address'),
      postalCode: watch('postalCode'),
      city: watch('city'),
    }).then((location) => {
      setValue('location', location)
    })
  }, [setValue, watch])

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBeR-j3Q7in_4YcIrqHWsyS2ZqQZh3XwBY"
      language="pl"
    >
      <div>
        <Meta title={pageTitle} />
        <div className="mt-3 mb-3 d-flex justify-content-start">
          <ButtonGroup>
            <Button
              className="d-flex align-items-center"
              onClick={() => navigate('/orders')}
            >
              <ChevronLeft size={20} />
              &nbsp;Wróć
            </Button>
          </ButtonGroup>
        </div>
        <Form
          onSubmit={handleSubmit(doInsertOrders, console.log)}
          className="mt-4"
        >
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Row>
            <Col>
              <FloatingLabel label="Adres" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.address}
                  type="text"
                  placeholder="Adres"
                  {...register('address', {
                    required: 'Proszę podać adres',
                  })}
                />
                {errors.address && (
                  <Form.Control.Feedback>
                    {errors.address.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>

              <Row>
                <Col>
                  <FloatingLabel label="Kod pocztowy" className="mb-3">
                    <Form.Control
                      isInvalid={!!errors.postalCode}
                      type="text"
                      placeholder="Kod pocztowy"
                      {...register('postalCode', {
                        required: 'Proszę podać kod pocztowy',
                      })}
                    />
                    {errors.postalCode && (
                      <Form.Control.Feedback>
                        {errors.postalCode.message}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="Miasto" className="mb-3">
                    <Form.Control
                      isInvalid={!!errors.city}
                      type="text"
                      placeholder="Miasto"
                      {...register('city', {
                        required: 'Proszę podać miasto',
                      })}
                    />
                    {errors.postalCode && (
                      <Form.Control.Feedback>
                        {errors.postalCode.message}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>

              {!location && (
                <Button className="mb-3" onClick={checkLocation}>
                  Sprawdź lokalizację
                </Button>
              )}

              {location && (
                <GoogleMap
                  center={{
                    lat: location.coordinates[0],
                    lng: location.coordinates[1],
                  }}
                  zoom={16}
                  mapContainerClassName="mb-3"
                  mapContainerStyle={{ width: '100%', height: '200px' }}
                  options={{
                    controlSize: 24,
                    streetViewControl: false,
                    mapTypeControlOptions: {
                      mapTypeIds: ['roadmap', 'satellite'],
                    },
                  }}
                >
                  <Marker
                    position={{
                      lat: location.coordinates[0],
                      lng: location.coordinates[1],
                    }}
                    draggable={false}
                  />
                </GoogleMap>
              )}
            </Col>
            <Col>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Select
                    styles={{ menu: (base) => ({ ...base, zIndex: 5 }) }}
                    className="mb-3"
                    isLoading={nursesFetching}
                    isDisabled={nursesFetching || !location}
                    onChange={(newValue) => onChange(newValue?.value)}
                    options={nurseOptions}
                    value={
                      nurseOptions.find((option) => option.value === value) ||
                      null
                    }
                    placeholder="Wybierz osobę pobierającą"
                  />
                )}
                name="nurseId"
                control={control}
              />
              <Controller
                render={({ field: { onChange } }) => (
                  <Select<
                    typeof collectionPointOptions[number]['options'][number],
                    false,
                    typeof collectionPointOptions[number]
                  >
                    className="mb-3"
                    styles={{ menu: (base) => ({ ...base, zIndex: 5 }) }}
                    isLoading={laboratoriesFetching}
                    isDisabled={laboratoriesFetching || !nurse}
                    onChange={(newValue) => onChange(newValue?.value)}
                    options={collectionPointOptions}
                    placeholder="Wybierz punkt pobrań"
                    value={
                      collectionPoint
                        ? {
                            label: collectionPoint.address,
                            value: collectionPoint.id,
                          }
                        : null
                    }
                  />
                )}
                name="collectionPointId"
                control={control}
              />

              <FloatingLabel label="Data pobrania" className="mb-3">
                <Form.Control
                  disabled={!nurse}
                  isInvalid={!!errors.collectionDate}
                  type="datetime-local"
                  placeholder="Data pobrania"
                  {...register('collectionDate', {
                    required: 'Data jest wymagana',
                    validate: (value) =>
                      value && new Date(value) > new Date()
                        ? undefined
                        : 'Data musi być w przyszłości',
                  })}
                />
                {errors.postalCode && (
                  <Form.Control.Feedback>
                    {errors.postalCode.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
          </Row>

          <hr />

          <Controller
            control={control}
            name="orders"
            render={({ field: { onChange, value: orders } }) => (
              <>
                <ListGroup className="mb-3">
                  {orders.map((order) => (
                    <ListGroupItem
                      key={`${order.firstName}-${order.lastName}-${order.phoneNumber}`}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div style={{ flex: '1' }}>
                        <p className="mb-1">
                          <Envelope /> {order.email}{' '}
                          {!!order.clientId && (
                            <Badge bg="secondary">#{order.clientId}</Badge>
                          )}
                          {!order.clientId && (
                            <Badge bg="success">nowy klient</Badge>
                          )}
                        </p>
                        <p className="mb-1">
                          <Person /> {order.firstName} {order.lastName}{' '}
                          {!!order.clientProfileId && (
                            <Badge bg="secondary">
                              #{order.clientProfileId}
                            </Badge>
                          )}
                          {!order.clientProfileId && (
                            <Badge bg="success">nowy profil</Badge>
                          )}
                        </p>
                        <p className="mb-0">
                          <Telephone /> {order.phoneNumber}
                        </p>
                      </div>
                      <ul style={{ flex: '1' }}>
                        {order.tests.map((test) => (
                          <li className="small">
                            {test.name} (#{test.id})
                          </li>
                        ))}
                      </ul>
                      <Button
                        variant="outline-danger"
                        className="d-flex align-items-center p-0"
                        onClick={() =>
                          onChange(orders.filter((o) => o !== order))
                        }
                      >
                        <X size={20} />
                      </Button>
                    </ListGroupItem>
                  ))}
                </ListGroup>

                <Button
                  onClick={() =>
                    orderAddModalRef.current?.show({
                      collectionPointId,
                      nurseId,
                    })
                  }
                  disabled={!collectionPoint || !nurse}
                >
                  Dodaj pozycję
                </Button>
              </>
            )}
          />

          <hr />

          <Button
            variant="primary"
            type="submit"
            disabled={insertOrdersFetching}
          >
            Utwórz zamówienia
          </Button>
        </Form>

        <OrderAddModal
          onChange={(order) => setValue('orders', [...orders, order])}
          ref={orderAddModalRef}
        />
      </div>
    </LoadScript>
  )
}

export default OrderBulkAdd
