import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetLaboratoriesDocument,
  GetLaboratoriesQuery,
  GetLaboratoriesQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, PersonPlus } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const Laboratories: FC = () => {
  // page content
  const pageTitle = 'Laboratoria'
  const [laboratories, setLaboratories] = useState<
    GetLaboratoriesQuery['laboratory']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => laboratories, [laboratories])
  const client = useClient()
  const navigate = useNavigate()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetLaboratoriesQuery, GetLaboratoriesQueryVariables>(
          GetLaboratoriesDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setLaboratories(
          refetch ? data.laboratory : laboratories.concat(data.laboratory)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.laboratory_aggregate.aggregate?.count || 0)
      }
    },
    [client, laboratories, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/laboratory')}
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetLaboratoriesQuery['laboratory'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Nazwa skrócona',
            accessor: (rowData) => (
              <span
                className={
                  rowData.enabled ? undefined : 'text-muted fst-italic'
                }
              >
                {rowData.shortName || '-'}
              </span>
            ),
          },
          {
            Header: 'Nazwa',
            accessor: (rowData) =>
              rowData.enabled ? (
                rowData.name
              ) : (
                <span className="text-muted fst-italic">
                  {rowData.name} (nieaktywne)
                </span>
              ),
          },
          {
            Header: 'Miasto',
            accessor: (rowData) => (
              <span
                className={
                  rowData.enabled ? undefined : 'text-muted fst-italic'
                }
              >
                {rowData.collectionPoints[0]?.city || '-'}
              </span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/laboratory/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Laboratories
