export type Time = `${number}:${number}:${number}`

export type Interval = [Time, Time]

export type WeekSchedule = {
  mon?: Interval
  tue?: Interval
  wed?: Interval
  thu?: Interval
  fri?: Interval
  sat?: Interval
  sun?: Interval
}

export const WeekDay2Name = {
  mon: 'Poniedziałek',
  tue: 'Wtorek',
  wed: 'Środa',
  thu: 'Czwartek',
  fri: 'Piątek',
  sat: 'Sobota',
  sun: 'Niedziela',
} as const

export function isTime(str: string): str is Time {
  return !!str.match(/^[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/)
}
