import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Discount_Type_Enum_Enum,
  GetDiscountTestsDocument,
  GetDiscountTestsQuery,
  GetDiscountTestsQueryVariables,
  useDeleteDiscountTestMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, Plus, Trash } from 'react-bootstrap-icons'
import { InfiniteTable } from '../../components/InfiniteTable'
import DiscountTestModal, {
  DiscountTestModalType,
} from '../../components/DiscountTestModal'

const BatchSize = 20

const DiscountTests: FC<{
  id: number
  readOnly?: boolean
  discountType: Discount_Type_Enum_Enum
}> = ({ id, readOnly, discountType }) => {
  // page content
  const pageTitle = 'Badania'
  const [discountTests, setDiscountTests] = useState<
    GetDiscountTestsQuery['discount_test']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => discountTests, [discountTests])
  const client = useClient()
  const discountTestModalRef = useRef<DiscountTestModalType>(null)
  const [, deleteDiscountTest] = useDeleteDiscountTestMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetDiscountTestsQuery, GetDiscountTestsQueryVariables>(
          GetDiscountTestsDocument,
          { discountId: id, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setDiscountTests(
          refetch
            ? data.discount_test
            : discountTests.concat(data.discount_test)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.discount_test_aggregate.aggregate?.count || 0)
      }
    },
    [client, discountTests, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            disabled={readOnly}
            onClick={() =>
              discountTestModalRef.current?.show({ discountId: id })
            }
          >
            <Plus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetDiscountTestsQuery['discount_test'][number]>
        data={data}
        columns={[
          { Header: 'ID', accessor: 'testId' },
          {
            Header: 'Badanie',
            accessor: ({ test }) => test.shortName || test.name,
          },
          {
            Header: 'Maksymalna liczba wystąpień',
            accessor: ({ maxCount }) => (
              <span className="text-nowrap">
                {Number.isInteger(maxCount) ? maxCount : 'nieograniczona'}
              </span>
            ),
            width:
              discountType === Discount_Type_Enum_Enum.WholeOrderWithTests
                ? 200
                : 0,
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    disabled={readOnly}
                    onClick={() =>
                      discountTestModalRef.current?.show({
                        discountId: id,
                        testId: rowData.testId,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    disabled={readOnly}
                    onClick={async () => {
                      if (
                        window.confirm('Czy napewno chcesz usunąć badanie?')
                      ) {
                        await deleteDiscountTest({
                          discountId: id,
                          testId: rowData.testId,
                        })
                        await loadMoreRows({ refetch: true })
                      }
                    }}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <DiscountTestModal
        ref={discountTestModalRef}
        onChange={() => loadMoreRows({ refetch: true })}
        discountType={discountType}
      />
    </div>
  )
}

export default DiscountTests
