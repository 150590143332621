import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetRatesDocument,
  GetRatesQuery,
  GetRatesQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, PersonPlus } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const Rates: FC = () => {
  // page content
  const pageTitle = 'Stawki'
  const [Rates, setRates] = useState<GetRatesQuery['rate']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => Rates, [Rates])

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetRatesQuery, GetRatesQueryVariables>(
          GetRatesDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setRates(refetch ? data.rate : Rates.concat(data.rate))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.rate_aggregate.aggregate?.count || 0)
      }
    },
    [client, Rates, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/rate')}
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetRatesQuery['rate'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Nazwa',
            id: 'name',
            accessor: ({ name, default: def }) => (
              <span>
                {name}
                {def && <strong> (domyślny)</strong>}
              </span>
            ),
          },
          {
            Header: 'Stawka za dojazd',
            id: 'deliveryCostPerKm',
            accessor: ({ deliveryCostPerKm }) => (
              <span>
                {deliveryCostPerKm.toFixed(4)}{' '}
                <span className="text-gray-300">zł/km</span>
              </span>
            ),
          },
          {
            Header: 'Stawka za pobranie',
            id: 'collectionCost',
            accessor: ({ collectionCost, collectionCost3Plus }) => (
              <>
                <div>
                  <strong>1-2</strong>: {collectionCost.toFixed(2)}{' '}
                  <span className="text-gray-300">zł</span>
                </div>
                <div>
                  <strong>3+</strong>: {collectionCost3Plus.toFixed(2)}{' '}
                  <span className="text-gray-300">zł</span>
                </div>
              </>
            ),
          },
          {
            Header: 'Zestaw pobraniowy',
            id: 'collectionKitCost',
            accessor: ({ collectionKitCost }) => (
              <span>
                {collectionKitCost.toFixed(2)}{' '}
                <span className="text-gray-300">zł</span>
              </span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/rate/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Rates
