import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetLaboratoriesDocument,
  GetLaboratoriesQuery,
  GetLaboratoriesQueryVariables,
  GetMaterialsDocument,
  GetMaterialsQuery,
  GetMaterialsQueryVariables,
  GetTagsDocument,
  GetTagsQuery,
  GetTagsQueryVariables,
  GetTestsDocument,
  GetTestsQuery,
  GetTestsQueryVariables,
  useDeleteTestMutation,
  useUpsertTestsMutation,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Alert, Badge, Button, ButtonGroup } from 'react-bootstrap'
import {
  BagPlus,
  Download,
  PencilSquare,
  Trash,
  Upload,
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'
import {
  testExportGenerator,
  testImportParser,
  testPriceRangeToString,
} from '../lib/test'
import { download } from '../lib/download'
import { Locale } from '../lib/translations'

const BatchSize = 20

const Tests: FC = () => {
  // page content
  const pageTitle = 'Badania'
  const [tests, setTests] = useState<GetTestsQuery['test']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [, upsertTests] = useUpsertTestsMutation()
  const data = useMemo(() => tests, [tests])
  const client = useClient()
  const navigate = useNavigate()
  const [generalError, setGeneralError] = useState<string>()
  const [importExportStatus, setImportExportStatus] = useState<string>()
  const [importSuccess, setImportSuccess] = useState<string>()
  const [, deleteTest] = useDeleteTestMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetTestsQuery, GetTestsQueryVariables>(
          GetTestsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setTests(refetch ? data.test : tests.concat(data.test))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.test_aggregate.aggregate?.count || 0)
      }
    },
    [client, tests, offset]
  )

  const doDeleteTest = useCallback(
    async (id: number) => {
      if (window.confirm('Czy napewno chcesz usunąć badanie?')) {
        await deleteTest({ id })
        await loadMoreRows({ refetch: true })
      }
    },
    [deleteTest, loadMoreRows]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  const exportToExcel = useCallback(async () => {
    try {
      setGeneralError(undefined)
      setImportSuccess(undefined)

      setImportExportStatus('Pobieranie informacji o badaniach...')

      const { data: testsData, error: testsError } = await client
        .query<GetTestsQuery, GetTestsQueryVariables>(
          GetTestsDocument,
          { limit: 0xffff },
          { requestPolicy: 'network-only' }
        )
        .toPromise()

      if (!testsData) {
        setGeneralError(`Error fetching tests: ${testsError?.message}`)
        return
      }

      setImportExportStatus('Tworzenie arkusza XLSX...')

      const generate = testExportGenerator({ tests: testsData.test })

      const data = await generate()

      download({
        filename: `Badania.xlsx`,
        mimeType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        data,
      })
    } catch (e: unknown) {
      setGeneralError((e as Error).message)
    } finally {
      setImportExportStatus(undefined)
    }
  }, [client])

  const importFromExcel = useCallback(
    async (file: File) => {
      try {
        setGeneralError(undefined)
        setImportSuccess(undefined)

        setImportExportStatus(
          'Pobieranie informacji o materiałach, kategoriach oraz laboratoriach...'
        )

        const [
          { error: materialsError, data: materialsData },
          { error: tagsError, data: tagsData },
          { error: laboratoriesError, data: laboratoriesData },
        ] = await Promise.all([
          client
            .query<GetMaterialsQuery, GetMaterialsQueryVariables>(
              GetMaterialsDocument,
              { limit: 0xffff }
            )
            .toPromise(),
          client
            .query<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, {
              limit: 0xffff,
            })
            .toPromise(),
          client
            .query<GetLaboratoriesQuery, GetLaboratoriesQueryVariables>(
              GetLaboratoriesDocument,
              { limit: 0xffff }
            )
            .toPromise(),
        ])

        if (!materialsData) {
          setGeneralError(
            `Error fetching materials: ${materialsError?.message}`
          )
          return
        }

        if (!tagsData) {
          setGeneralError(`Error fetching tags: ${tagsError?.message}`)
          return
        }

        if (!laboratoriesData) {
          setGeneralError(
            `Error fetching laboratories: ${laboratoriesError?.message}`
          )
          return
        }

        try {
          const parse = testImportParser({
            materials: materialsData?.material || [],
            tags: tagsData?.tag || [],
            laboratories: laboratoriesData?.laboratory || [],
          })

          setImportExportStatus('Czytanie arkusza...')
          const data = await parse(file)

          setImportExportStatus('Aktualizacja badań...')
          const { error } = await upsertTests(data)

          if (error) {
            setGeneralError(`Database error: ${error.message}`)
            console.error(error)
            return
          }

          await loadMoreRows({ refetch: true })

          setImportSuccess(
            `Dodano ${data.testInserts.length} badań, zaktualizowano ${data.testUpdates.length} badań 🥳`
          )
        } catch (error: unknown) {
          setGeneralError(`Parse error: ${(error as Error).message}`)
          console.error(error)
        }
      } finally {
        setImportExportStatus(undefined)
      }
    },
    [client, upsertTests, loadMoreRows]
  )

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <Button
          title="Export"
          className="d-flex align-items-center me-2"
          onClick={() => {
            exportToExcel()
          }}
        >
          <Upload size={20} />
          &nbsp;Export
        </Button>
        <Button
          title="Import"
          className="d-flex align-items-center me-2"
          onClick={(event) => {
            event.currentTarget
              .querySelectorAll('input[type=file]')
              .forEach((input: any) => input.click())
          }}
        >
          <Download size={20} />
          &nbsp;Import
          <input
            type="file"
            className="visually-hidden"
            multiple
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(event) => {
              if (event.target.files?.length) {
                event.target.files?.length &&
                  importFromExcel(event.target.files[0])
              }
            }}
          />
        </Button>
        <Button
          className="d-flex align-items-center"
          onClick={() => navigate('/test')}
        >
          <BagPlus size={20} />
          &nbsp;Dodaj
        </Button>
      </div>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      {importExportStatus && (
        <Alert variant="warning">{importExportStatus}</Alert>
      )}
      {importSuccess && <Alert variant="success">{importSuccess}</Alert>}
      <InfiniteTable<GetTestsQuery['test'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Nazwa',
            accessor: ({ name_i18n, mostPopular }) => (
              <>
                <p className="my-0">{name_i18n[Locale.pl_PL]}</p>
                {mostPopular && <Badge bg="secondary">Najpopularniejsze</Badge>}
              </>
            ),
          },
          {
            Header: 'Kategorie',
            accessor: ({ testTags }) => (
              <div className="flex-wrap">
                {testTags.map(({ tagId }) => (
                  <Badge bg="warning" className="m-1">
                    {tagId}
                  </Badge>
                ))}
              </div>
            ),
          },
          {
            Header: 'Laboratoria',
            accessor: ({ bloodlabEligible, laboratoryTests }) => (
              <div className="flex-wrap">
                {laboratoryTests.map(
                  ({ laboratory: { id, name, shortName } }) => (
                    <Badge
                      bg="info"
                      className="m-1"
                      title={name}
                      onClick={() => navigate(`/laboratory/${id}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      {shortName || name}
                    </Badge>
                  )
                )}
                {bloodlabEligible && (
                  <Badge bg="light" className="m-1">
                    <img src="/bloodlab-logo.svg" alt="Bloodlab" height={12} />
                  </Badge>
                )}
              </div>
            ),
          },
          {
            Header: 'Cena',
            accessor: (test) =>
              test.laboratoryTests_aggregate.aggregate && (
                <span className="text-nowrap">
                  {testPriceRangeToString(
                    test.laboratoryTests_aggregate.aggregate
                  )}
                </span>
              ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/test/${rowData.id}`)}
                    onContextMenu={(e) => {
                      e.preventDefault()
                      window
                        .open(
                          `${window.location.origin}/test/${rowData.id}`,
                          '_blank'
                        )
                        ?.focus()
                    }}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => doDeleteTest(rowData.id)}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Tests
