export enum Locale {
  pl_PL = 'pl_PL',
  en_GB = 'en_GB',
  uk_UA = 'uk_UA',
}

export const FlagMap: Record<Locale, string> = {
  [Locale.pl_PL]: '🇵🇱',
  [Locale.en_GB]: '🇬🇧',
  [Locale.uk_UA]: '🇺🇦',
}

export type LocalizedField = { pl_PL: string; en_GB?: string; uk_UA?: string }
