import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import {
  GetConsultationSlotDocument,
  GetConsultationSlotQuery,
  GetConsultationSlotQueryVariables,
  useInsertConsultationSlotMutation,
  useUpdateConsultationSlotMutation,
} from '../generated/urql.administrator'
import { useForm } from 'react-hook-form'
import { useClient } from 'urql'
import moment from 'moment/moment'

export type ConsultationSlotModalType = {
  show: (args: { id?: number; consultationId: number }) => void
}

type FormConsultationSlot = {
  dateFrom: string
  dateTo: string
}

const ConsultationSlotModal = forwardRef<
  ConsultationSlotModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState<number>()
  const [consultationId, setConsultationId] = useState<number>()
  const client = useClient()
  const [, setGetConsultationSlotFetching] = useState(false)
  const [{ fetching: updateConsultationSlotFetching }, updateConsultationSlot] =
    useUpdateConsultationSlotMutation()
  const [{ fetching: insertConsultationSlotFetching }, insertConsultationSlot] =
    useInsertConsultationSlotMutation()
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormConsultationSlot>({
    defaultValues: {
      dateFrom: undefined,
      dateTo: undefined,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ id, consultationId }) => {
      setId(id)
      setConsultationId(consultationId)
      reset({
        dateFrom: undefined,
        dateTo: undefined,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (id) {
      setGetConsultationSlotFetching(true)
      client
        .query<GetConsultationSlotQuery, GetConsultationSlotQueryVariables>(
          GetConsultationSlotDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.consultation_slot_by_pk) {
            console.log(data)
            reset({
              dateFrom: data.consultation_slot_by_pk.dateFrom
                ? moment(data.consultation_slot_by_pk.dateFrom).format(
                    'yyyy-MM-DDTHH:mm'
                  )
                : '',
              dateTo: data.consultation_slot_by_pk.dateTo
                ? moment(data.consultation_slot_by_pk.dateTo).format(
                    'yyyy-MM-DDTHH:mm'
                  )
                : '',
            })
          }
        })
        .finally(() => setGetConsultationSlotFetching(false))
    }
  }, [id, client, reset])

  async function doSaveConsultationSlot(
    consultationSlot: FormConsultationSlot
  ) {
    setGeneralError(undefined)

    if (!consultationId) {
      setGeneralError('Missing consultationId')
      return
    }

    const { error } = id
      ? await updateConsultationSlot({
          ...consultationSlot,
          dateFrom: moment(consultationSlot.dateFrom).toISOString(),
          dateTo: moment(consultationSlot.dateTo).toISOString(),
          consultationId,
          id,
        })
      : await insertConsultationSlot({
          ...consultationSlot,
          dateFrom: moment(consultationSlot.dateFrom).toISOString(),
          dateTo: moment(consultationSlot.dateTo).toISOString(),
          consultationId,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setId(undefined)
    setConsultationId(undefined)
    onChange()
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setId(undefined)
        setConsultationId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveConsultationSlot)}>
        <Modal.Header closeButton>
          <Modal.Title>Slot konsultacji</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <FloatingLabel label="Data/czas od" className="mb-3">
            <Form.Control
              isInvalid={!!errors.dateTo}
              type="datetime-local"
              placeholder="Od"
              {...register('dateFrom', {
                required: 'Proszę podać datę początkową',
              })}
            />
            {errors.dateFrom && (
              <Form.Control.Feedback>
                {errors.dateFrom.message}
              </Form.Control.Feedback>
            )}{' '}
          </FloatingLabel>

          <FloatingLabel label="Data/czas do" className="mb-3">
            <Form.Control
              isInvalid={!!errors.dateTo}
              type="datetime-local"
              placeholder="Do"
              {...register('dateTo', {
                required: 'Proszę podać datę końcową',
              })}
            />
            {errors.dateTo && (
              <Form.Control.Feedback>
                {errors.dateTo.message}
              </Form.Control.Feedback>
            )}{' '}
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={
              updateConsultationSlotFetching || insertConsultationSlotFetching
            }
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default ConsultationSlotModal
