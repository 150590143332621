import Header from '../components/Header'
import Meta from '../components/Meta'
import { FC } from 'react'

const Settings: FC = () => {
  // page content
  const pageTitle = 'Ustawienia'
  const pageDescription = 'Ustawienia'

  return (
    <div>
      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />
    </div>
  )
}

export default Settings
