import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetAdministratorNotificationsDocument,
  GetAdministratorNotificationsQuery,
  GetAdministratorNotificationsQueryVariables,
  useDeleteAdministratorNotificationMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Badge, Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, Plus, Trash } from 'react-bootstrap-icons'
import { InfiniteTable } from '../../components/InfiniteTable'
import AdministratorNotificationModal, {
  AdministratorNotificationModalType,
} from '../../components/AdministratorNotificationModal'

const BatchSize = 20

const AdministratorNotifications: FC<{ id: number }> = ({ id }) => {
  // page content
  const pageTitle = 'Powiadomienia administratora'
  const [administratorNotifications, setAdministratorNotifications] = useState<
    GetAdministratorNotificationsQuery['administrator_notification']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(
    () => administratorNotifications,
    [administratorNotifications]
  )
  const client = useClient()
  const administratorNotificationRef =
    useRef<AdministratorNotificationModalType>(null)
  const [, deleteAdministratorNotification] =
    useDeleteAdministratorNotificationMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<
          GetAdministratorNotificationsQuery,
          GetAdministratorNotificationsQueryVariables
        >(
          GetAdministratorNotificationsDocument,
          {
            administratorId: id,
            offset: refetch ? 0 : offset,
            limit: BatchSize,
          },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setAdministratorNotifications(
          refetch
            ? data.administrator_notification
            : administratorNotifications.concat(data.administrator_notification)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(
          data.administrator_notification_aggregate.aggregate?.count || 0
        )
      }
    },
    [client, administratorNotifications, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() =>
              administratorNotificationRef.current?.show({
                administratorId: id,
              })
            }
          >
            <Plus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<
        GetAdministratorNotificationsQuery['administrator_notification'][number]
      >
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Rodzaj',
            id: 'scope',
            accessor: (rowData) => (
              <span>
                {rowData.scope === 'order:new' ? 'Nowe zlecenie' : '?'}
              </span>
            ),
          },
          {
            Header: 'Kanały',
            id: 'channels',
            accessor: (rowData) => (
              <div>
                {rowData.email ? (
                  <Badge bg="success">Email</Badge>
                ) : (
                  <Badge bg="danger">Email</Badge>
                )}
                &nbsp;
                {rowData.sms ? (
                  <Badge bg="success">SMS</Badge>
                ) : (
                  <Badge bg="danger">SMS</Badge>
                )}
              </div>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() =>
                      administratorNotificationRef.current?.show({
                        id: rowData.id,
                        administratorId: id,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () => {
                      if (
                        window.confirm(
                          'Czy napewno chcesz usunąć powiadomienia?'
                        )
                      ) {
                        await deleteAdministratorNotification({
                          id: rowData.id,
                        })
                        await loadMoreRows({ refetch: true })
                      }
                    }}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <AdministratorNotificationModal
        ref={administratorNotificationRef}
        onChange={() => loadMoreRows({ refetch: true })}
      />
    </div>
  )
}

export default AdministratorNotifications
