import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetLaboratoryCollectionPointsByLaboratoryIdDocument,
  GetLaboratoryCollectionPointsByLaboratoryIdQuery,
  GetLaboratoryCollectionPointsByLaboratoryIdQueryVariables,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Badge, Button, ButtonGroup, Col, Row } from 'react-bootstrap'
import { CartPlus, PencilSquare } from 'react-bootstrap-icons'
import LaboratoryCollectionPointModal, {
  LaboratoryCollectionPointModalType,
} from '../../components/LaboratoryCollectionPointModal'
import { InfiniteTable } from '../../components/InfiniteTable'
import * as Time from '../../lib/time'

const BatchSize = 20

const LaboratoryCollectionPoints: FC<{ id: number }> = ({ id }) => {
  // page content
  const pageTitle = 'Punkty pobrań'
  const [laboratoryCollectionPoints, setLaboratoryCollectionPoints] = useState<
    GetLaboratoryCollectionPointsByLaboratoryIdQuery['laboratory_collection_point']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(
    () => laboratoryCollectionPoints,
    [laboratoryCollectionPoints]
  )
  const client = useClient()
  const laboratoryCollectionPointRef =
    useRef<LaboratoryCollectionPointModalType>(null)

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<
          GetLaboratoryCollectionPointsByLaboratoryIdQuery,
          GetLaboratoryCollectionPointsByLaboratoryIdQueryVariables
        >(
          GetLaboratoryCollectionPointsByLaboratoryIdDocument,
          { laboratoryId: id, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setLaboratoryCollectionPoints(
          refetch
            ? data.laboratory_collection_point
            : laboratoryCollectionPoints.concat(
                data.laboratory_collection_point
              )
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(
          data.laboratory_collection_point_aggregate.aggregate?.count || 0
        )
      }
    },
    [client, laboratoryCollectionPoints, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() =>
              laboratoryCollectionPointRef.current?.show({ laboratoryId: id })
            }
          >
            <CartPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<
        GetLaboratoryCollectionPointsByLaboratoryIdQuery['laboratory_collection_point'][number]
      >
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Nazwa', accessor: 'name' },
          {
            Header: 'Adres',
            accessor: ({ address, postalCode, city }) => (
              <p>
                {address}
                <br />
                {city}, {postalCode}
              </p>
            ),
          },
          {
            Header: 'Godziny otwarcia',
            id: 'openingHours',
            accessor: ({ openingHours }) =>
              Object.entries(Time.WeekDay2Name).map(([weekDay, dayName]) => (
                <Row>
                  <Col className="">
                    <strong>
                      <small>{dayName}</small>
                    </strong>
                  </Col>
                  {openingHours[weekDay as keyof Time.WeekSchedule] && (
                    <Col>
                      <Badge bg="success">
                        {openingHours[weekDay as keyof Time.WeekSchedule][0]}
                      </Badge>{' '}
                      -{' '}
                      <Badge bg="danger">
                        {openingHours[weekDay as keyof Time.WeekSchedule][1]}
                      </Badge>
                    </Col>
                  )}
                  {!openingHours[weekDay as keyof Time.WeekSchedule] && (
                    <Col>
                      <Badge bg="secondary">Zamknięte</Badge>
                    </Col>
                  )}
                </Row>
              )),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() =>
                      laboratoryCollectionPointRef.current?.show({
                        id: rowData.id,
                        laboratoryId: id,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <LaboratoryCollectionPointModal
        ref={laboratoryCollectionPointRef}
        onChange={() => loadMoreRows({ refetch: true })}
      />
    </div>
  )
}

export default LaboratoryCollectionPoints
