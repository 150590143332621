import * as Geocoder from 'react-geocode'
import { GoogleApiKey } from '../const'

export type Point = { type: 'Point'; coordinates: [number, number] }
export type Polygon = { type: 'Polygon'; coordinates: [number, number][][] }
export type Geography = Point | Polygon

export function isPoint(geography: Geography): geography is Point {
  return geography.type === 'Point'
}

export function isPolygon(geography: Geography): geography is Polygon {
  return geography.type === 'Polygon'
}

export function assertPoint(geography: Geography): asserts geography is Point {
  if (!isPoint(geography)) {
    throw new Error('Expected Geography(Point)')
  }
}

export function assertPolygon(
  geography: Geography
): asserts geography is Polygon {
  if (!isPolygon(geography)) {
    throw new Error('Expected Geography(Polygon)')
  }
}

export function calculateInitialRegion(geography: Geography) {
  if (isPolygon(geography)) {
    const minMax = geography.coordinates.reduce(
      (r, innerPolygon) => {
        for (const [lat, lng] of innerPolygon) {
          r.latMin = r.latMin > lat ? lat : r.latMin
          r.latMax = r.latMax < lat ? lat : r.latMax
          r.lngMin = r.lngMin > lng ? lng : r.lngMin
          r.lngMax = r.lngMax < lng ? lng : r.lngMax
        }
        return r
      },
      {
        latMin: Number.MAX_VALUE,
        latMax: Number.MIN_VALUE,
        lngMin: Number.MAX_VALUE,
        lngMax: Number.MIN_VALUE,
      }
    )

    return {
      lat: (minMax.latMin + minMax.latMax) / 2,
      lng: (minMax.lngMin + minMax.lngMax) / 2,
    }
  }

  if (isPoint(geography)) {
    return {
      lat: geography.coordinates[0],
      lng: geography.coordinates[1],
    }
  }
}

export function fromLinearRing(polygon: Polygon) {
  return {
    ...polygon,
    coordinates: polygon.coordinates.map((coordinates) => {
      if (
        coordinates.length > 1 &&
        coordinates[0][0] === coordinates[coordinates.length - 1][0] &&
        coordinates[0][1] === coordinates[coordinates.length - 1][1]
      ) {
        return coordinates.slice(0, coordinates.length - 1)
      } else {
        return coordinates
      }
    }),
  }
}

export function toLinearRing(polygon: Polygon) {
  return {
    ...polygon,
    coordinates: polygon.coordinates.map((coordinates) => {
      if (
        coordinates.length > 1 &&
        (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
          coordinates[0][1] !== coordinates[coordinates.length - 1][1])
      ) {
        return [...coordinates, coordinates[0]]
      } else {
        return coordinates
      }
    }),
  }
}

function distance([x1, y1]: [number, number], [x2, y2]: [number, number]) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

export function getNearestPoint(
  polygons: Polygon['coordinates'],
  point: Point['coordinates']
) {
  return polygons.reduce<[number, number] | undefined>(
    (ref, coordinates, pi) => {
      let newRef: [number, number] | undefined = ref ? [...ref] : undefined

      for (let ci = 0; ci < coordinates.length; ci++) {
        if (
          !newRef ||
          distance(point, coordinates[ci]) <
            distance(point, polygons[newRef[0]][newRef[1]])
        ) {
          newRef = [pi, ci]
        }
      }

      return newRef
    },
    undefined
  )
}

export type GeocoderLocation = {
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

export async function findLocations(
  address: string,
  locale = 'pl'
): Promise<GeocoderLocation[]> {
  if (!GoogleApiKey) {
    throw new Error('You need to provide GoogleApiKey')
  }

  Geocoder.setKey(GoogleApiKey)
  Geocoder.setLanguage(locale)
  const response = await Geocoder.fromAddress(address)
  // @ts-ignore
  return response.results
}

export async function findLocation(
  address: {
    address: string
    city: string
    postalCode: string
  },
  locale = 'pl'
): Promise<Point | undefined> {
  const locations = await findLocations(
    `${address.address}, ${address.postalCode} ${address.city}, Polska`,
    locale
  )

  if (!locations.length) {
    return
  }

  return {
    type: 'Point',
    coordinates: [
      locations[0].geometry.location.lat,
      locations[0].geometry.location.lng,
    ],
  }
}
