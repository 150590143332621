import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import {
  GetClientDocument,
  GetClientQuery,
  GetClientQueryVariables,
  useUpdateClientMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

type FormClient = {
  email: string
  newsletterConsent: boolean
  pushConsent: boolean
  auth2FAEnabled: boolean
}

const Client: FC = () => {
  const pageTitle = 'Klient'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetClientFetching] = useState(false)
  const [{ fetching: updateClientFetching }, updateClient] =
    useUpdateClientMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    register,
  } = useForm<FormClient>({
    defaultValues: {
      email: '',
      newsletterConsent: false,
      pushConsent: false,
      auth2FAEnabled: false,
    },
  })

  useEffect(() => {
    if (id) {
      setGetClientFetching(true)
      client
        .query<GetClientQuery, GetClientQueryVariables>(GetClientDocument, {
          id: parseInt(id),
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.client_by_pk) {
            reset({
              email: data.client_by_pk.email || '',
              newsletterConsent: data.client_by_pk.newsletterConsent,
              pushConsent: data.client_by_pk.pushConsent,
              auth2FAEnabled: data.client_by_pk.auth2FAEnabled,
            })
          }
        })
        .finally(() => setGetClientFetching(false))
    }
  }, [id, client, reset])

  async function doSaveClient(client: FormClient) {
    setGeneralError(undefined)

    if (!id) {
      throw new Error(`Missing client id`)
    }

    const { error } = await updateClient({
      auth2FAEnabled: client.auth2FAEnabled,
      email: client.email,
      id: parseInt(id),
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/clients')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSaveClient, console.log)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano parametry klienta</Toast.Body>
          </Toast>
        </ToastContainer>

        <Row>
          <Col>
            <h4>Dane klienta</h4>
            <FloatingLabel label="Email" className="mb-3">
              <Form.Control
                isInvalid={!!errors.email}
                type="email"
                autoComplete="new-password"
                placeholder="Email"
                {...register('email', {
                  required: 'Proszę podać email',
                  pattern: {
                    value:
                      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    message: 'Nieprawidłowy format',
                  },
                })}
              />
              {errors.email && (
                <Form.Control.Feedback>
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <Form.Check
              type="switch"
              label="Uwierzytelnianie dwuskładnikowe"
              {...register('auth2FAEnabled')}
            />
            {errors.auth2FAEnabled && (
              <Form.Control.Feedback>
                {errors.auth2FAEnabled.message}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col>
            <h4>Zgody</h4>
            <p>
              <span className="fw-bold">Newsletter: </span>
              {watch('newsletterConsent') ? (
                <Badge bg="success">Tak</Badge>
              ) : (
                <Badge bg="danger">Nie</Badge>
              )}
              <p className="small text-muted">
                Tak, chcę otrzymywać na podany przeze mnie podczas rejestracji
                adres e-mail newsletter i wiadomości mailowe informujące o
                promocjach dostępnych za pośrednictwem Aplikacji Home Lab. Wiem,
                że niniejsza zgoda jest w pełni dobrowolna i mogę ją odwołać w
                każdym czasie.
              </p>
            </p>
            <p>
              <span className="fw-bold">Push: </span>
              {watch('pushConsent') ? (
                <Badge bg="success">Tak</Badge>
              ) : (
                <Badge bg="danger">Nie</Badge>
              )}
              <p className="small text-muted">
                Tak, wyrażam zgodę na wyświetlanie mi na używanym przeze mnie
                urządzeniu komunikatów i powiadomień Aplikacji Home Lab, a także
                na otrzymywanie wiadomości sms informujących o aktualnych
                promocjach dostępnych za pośrednictwem Aplikacji Home Lab. Wiem,
                ze niniejsza zgoda jest w pełni dobrowolna i w każdej chwili
                mogę ją odwołać.
              </p>
            </p>
          </Col>
        </Row>
        <Button
          className="mt-4"
          variant="primary"
          type="submit"
          disabled={updateClientFetching}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default Client
