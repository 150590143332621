import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import {
  GetLabUserDocument,
  GetLabUserQuery,
  GetLabUserQueryVariables,
  useInsertLabUserMutation,
  useUpdateLabUserMutation,
  useUpdateLabUserPasswordMutation,
} from '../generated/urql.administrator'
import { useForm } from 'react-hook-form'
import { useClient } from 'urql'
import { useLabUserPasswordResetInitMutation } from '../generated/urql.anonymous'

export type LaboratoryUserModalType = {
  show: (args: { id?: number; laboratoryId: number }) => void
}

type FormLabUser = {
  email: string
  password: string
  sendPasswordReset: boolean
  name: string
}

const LaboratoryUserModal = forwardRef<
  LaboratoryUserModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState<number>()
  const [laboratoryId, setLaboratoryId] = useState<number>()
  const client = useClient()
  const [, setGetLabUserFetching] = useState(false)
  const [{ fetching: updateLabUserFetching }, updateLabUser] =
    useUpdateLabUserMutation()
  const [{ fetching: insertLabUserFetching }, insertLabUser] =
    useInsertLabUserMutation()
  const [, updateLabUserPassword] = useUpdateLabUserPasswordMutation()
  const [, labUserPasswordResetInit] = useLabUserPasswordResetInitMutation()
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    watch,
  } = useForm<FormLabUser>({
    defaultValues: {
      email: '',
      password: '',
      sendPasswordReset: true,
      name: '',
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ id, laboratoryId }) => {
      setId(id)
      setLaboratoryId(laboratoryId)
      reset({
        email: '',
        password: '',
        sendPasswordReset: true,
        name: '',
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (id) {
      setGetLabUserFetching(true)
      client
        .query<GetLabUserQuery, GetLabUserQueryVariables>(GetLabUserDocument, {
          id,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.lab_user_by_pk) {
            reset({
              email: data.lab_user_by_pk.email || '',
              password: '',
              sendPasswordReset: false,
              name: data.lab_user_by_pk.name || '',
            })
          }
        })
        .finally(() => setGetLabUserFetching(false))
    }
  }, [id, client, reset])

  async function doSaveLabUser({
    password,
    sendPasswordReset,
    ...labUser
  }: FormLabUser) {
    setGeneralError(undefined)

    if (!laboratoryId) {
      setGeneralError('Missing laboratoryId')
      return
    }

    const { error } = id
      ? await updateLabUser({
          ...labUser,
          id,
        })
      : await insertLabUser({
          ...labUser,
          laboratoryId,
        })

    if (password) {
      await updateLabUserPassword({
        email: labUser.email,
        password,
      })
    }

    if (sendPasswordReset) {
      await labUserPasswordResetInit({ email: labUser.email })
    }

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setId(undefined)
    setLaboratoryId(undefined)
    onChange()
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setId(undefined)
        setLaboratoryId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveLabUser)}>
        <Modal.Header closeButton>
          <Modal.Title>Użytkownik laboratorium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <FloatingLabel label="Email" className="mb-3">
            <Form.Control
              isInvalid={!!errors.email}
              type="email"
              placeholder="Email"
              autoComplete="new-password"
              {...register('email', {
                required: 'Proszę podać email',
                pattern: {
                  value:
                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                  message: 'Nieprawidłowy format',
                },
              })}
            />
            {errors.email && (
              <Form.Control.Feedback>
                {errors.email.message}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
          {!id && (
            <Form.Check
              type="switch"
              style={{ marginBottom: '16px' }}
              label="Wyślij link do resetu hasła"
              {...register('sendPasswordReset')}
            />
          )}
          {!watch('sendPasswordReset') && (
            <FloatingLabel label="Hasło" className="mb-3">
              <Form.Control
                isInvalid={!!errors.password}
                type="password"
                placeholder="Hasło"
                autoComplete="new-password"
                {...register(
                  'password',
                  id ? undefined : { required: 'Proszę podać hasło' }
                )}
              />
              {errors.password && (
                <Form.Control.Feedback>
                  {errors.password.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          )}
          <FloatingLabel label="Imie i nazwisko" className="mb-3">
            <Form.Control
              isInvalid={!!errors.name}
              type="text"
              placeholder="Imię i nazwisko"
              {...register('name', {
                required: 'Proszę podać imię i nazwisko',
              })}
            />
            {errors.name && (
              <Form.Control.Feedback>
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={updateLabUserFetching || insertLabUserFetching}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default LaboratoryUserModal
