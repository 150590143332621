import Meta from '../../components/Meta'
import { FC, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { usePasswordResetInitMutation } from '../../generated/urql.anonymous'

const PasswordResetInit: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>()
  const [generalError, setGeneralError] = useState<string>()
  const [{ fetching: passwordResetInitFetching }, passwordResetInit] =
    usePasswordResetInitMutation()
  const [checkEmailVisible, setCheckEmailVisible] = useState(false)

  async function doPasswordResetInit({ email }: { email: string }) {
    setGeneralError(undefined)

    const { data, error } = await passwordResetInit({ email })

    if (error) {
      setGeneralError(error.message)
    }

    if (data?.administrator_password_reset_init) {
      setCheckEmailVisible(true)
    }
  }

  return (
    <>
      <Meta title="Logowanie" />

      <Container>
        <Row className="justify-content-md-center mt-5 mb-5">
          <Col md="4">
            <h1>Przypomnienie hasła</h1>
            <p>
              Podaj e-mail do konta. Prześlemy na niego link pozwalający
              zresetować hasło.
            </p>

            <Form onSubmit={handleSubmit(doPasswordResetInit)} className="mt-4">
              {generalError && <Alert variant="danger">{generalError}</Alert>}
              {checkEmailVisible && (
                <Alert variant="success">
                  Jeśli konto o podanym adresie email istnieje to została na nie
                  wysłana wiadomość zawierająca dalsze instrukcje przypomnienia
                  hasła.
                </Alert>
              )}

              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.email}
                  type="email"
                  placeholder="Email"
                  autoComplete="new-password"
                  {...register('email', {
                    required: 'Proszę podać adres email',
                  })}
                />
                {errors.email && (
                  <Form.Control.Feedback>
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>

              <div className="flex-1 d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={passwordResetInitFetching}
                >
                  Wyślij link resetujący hasło
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PasswordResetInit
