import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetPushNotificationsDocument,
  GetPushNotificationsQuery,
  GetPushNotificationsQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { ChatDotsFill, Eye } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'
import { Locale } from '../lib/translations'

const BatchSize = 20

const PushNotifications: FC = () => {
  // page content
  const pageTitle = 'Powiadomienia push'
  const [pushNotifications, setPushNotifications] = useState<
    GetPushNotificationsQuery['push_notification']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => pushNotifications, [pushNotifications])

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetPushNotificationsQuery, GetPushNotificationsQueryVariables>(
          GetPushNotificationsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setPushNotifications(
          refetch
            ? data.push_notification
            : pushNotifications.concat(data.push_notification)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.push_notification_aggregate.aggregate?.count || 0)
      }
    },
    [client, pushNotifications, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/push-notification')}
          >
            <ChatDotsFill size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetPushNotificationsQuery['push_notification'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Nagłówek',
            accessor: ({ heading_i18n }) => heading_i18n[Locale.pl_PL],
          },
          {
            Header: 'Treść',
            accessor: ({ content_i18n }) => content_i18n[Locale.pl_PL],
          },
          {
            Header: 'Liczba klientów',
            accessor: ({ pushNotificationClients_aggregate }) =>
              pushNotificationClients_aggregate.aggregate?.count,
          },
          {
            Header: '',
            id: 'actions',
            accessor: ({ id }) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/push-notification/${id}`)}
                  >
                    <Eye size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default PushNotifications
