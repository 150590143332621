import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { Alert, Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router'
import TestSettings from './test/TestSettings'
import TestLaboratories from './test/TestLaboratories'
import {
  GetTestDocument,
  GetTestQuery,
  GetTestQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'

const Test: FC = () => {
  const pageTitle = 'Ustawienia'
  const client = useClient()
  const { id } = useParams()
  const [deletedAt, setDeletedAt] = useState<string | null>()

  useEffect(() => {
    if (id) {
      client
        .query<GetTestQuery, GetTestQueryVariables>(GetTestDocument, {
          id: parseInt(id),
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.test_by_pk) {
            setDeletedAt(data.test_by_pk.deletedAt)
          }
        })
    }
  }, [id, client])

  if (!id) {
    return <TestSettings />
  }

  return (
    <div>
      <Meta title={pageTitle} />
      {deletedAt && (
        <Alert variant="danger">Badanie usunięte {deletedAt}</Alert>
      )}
      <Tabs defaultActiveKey="settings" className="mb-3 mt-3">
        <Tab eventKey="settings" title="Ustawienia">
          <TestSettings id={parseInt(id)} readOnly={!!deletedAt} />
        </Tab>
        <Tab eventKey="laboratoryTests" title="Laboratoria">
          <TestLaboratories id={parseInt(id)} readOnly={!!deletedAt} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Test
