import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetLabUsersDocument,
  GetLabUsersQuery,
  GetLabUsersQueryVariables,
  useDeleteLabUserMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, PersonPlus, Trash } from 'react-bootstrap-icons'
import LaboratoryUserModal, {
  LaboratoryUserModalType,
} from '../../components/LaboratoryUserModal'
import { InfiniteTable } from '../../components/InfiniteTable'

const BatchSize = 20

const LaboratoryUsers: FC<{ id: number }> = ({ id }) => {
  // page content
  const pageTitle = 'Użytkownicy laboratorium'
  const [labUsers, setLabUsers] = useState<GetLabUsersQuery['lab_user']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => labUsers, [labUsers])
  const client = useClient()
  const laboratoryUserRef = useRef<LaboratoryUserModalType>(null)
  const [, deleteLabUser] = useDeleteLabUserMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetLabUsersQuery, GetLabUsersQueryVariables>(
          GetLabUsersDocument,
          { laboratoryId: id, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setLabUsers(refetch ? data.lab_user : labUsers.concat(data.lab_user))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.lab_user_aggregate.aggregate?.count || 0)
      }
    },
    [client, labUsers, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  const doDeleteLabUser = useCallback(
    async (id: number) => {
      if (
        window.confirm('Czy napewno chcesz usunąć użytkownika laboratorium?')
      ) {
        await deleteLabUser({ id })
        await loadMoreRows({ refetch: true })
      }
    },
    [deleteLabUser]
  )

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() =>
              laboratoryUserRef.current?.show({ laboratoryId: id })
            }
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetLabUsersQuery['lab_user'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Nazwa', accessor: 'name' },
          { Header: 'E-mail', accessor: 'email' },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() =>
                      laboratoryUserRef.current?.show({
                        id: rowData.id,
                        laboratoryId: id,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => doDeleteLabUser(rowData.id)}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <LaboratoryUserModal
        ref={laboratoryUserRef}
        onChange={() => loadMoreRows({ refetch: true })}
      />
    </div>
  )
}

export default LaboratoryUsers
