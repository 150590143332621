import Meta from '../components/Meta'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  GetConsultationsDocument,
  GetConsultationsQuery,
  GetConsultationsQueryVariables,
  useDeleteConsultationMutation,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Alert, Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, TelephonePlus, Trash } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'
import { Locale } from '../lib/translations'
import { guessStorageAddress } from '../lib/api'
import { AuthContext } from '../provider/auth'

const BatchSize = 20

export function getStorageUrl(fileId: number, accessToken?: string) {
  return (
    guessStorageAddress() +
    `/files/${fileId}${accessToken ? `?accessToken=${accessToken}` : ''}`
  )
}

const Consultations: FC = () => {
  // page content
  const pageTitle = 'Konsultacje'
  const auth = useContext(AuthContext)
  const [consultations, setConsultations] = useState<
    GetConsultationsQuery['consultation']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => consultations, [consultations])
  const client = useClient()
  const navigate = useNavigate()
  const [generalError, setGeneralError] = useState<string>()
  const [, deleteConsultation] = useDeleteConsultationMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetConsultationsQuery, GetConsultationsQueryVariables>(
          GetConsultationsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setConsultations(
          refetch ? data.consultation : consultations.concat(data.consultation)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.consultation_aggregate.aggregate?.count || 0)
      }
    },
    [client, consultations, offset]
  )

  const doDeleteConsultation = useCallback(
    async (id: number) => {
      if (window.confirm('Czy napewno chcesz usunąć szkolenie?')) {
        await deleteConsultation({ id })
        await loadMoreRows({ refetch: true })
      }
    },
    [deleteConsultation, loadMoreRows]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <Button
          className="d-flex align-items-center"
          onClick={() => navigate('/consultation')}
        >
          <TelephonePlus size={20} />
          &nbsp;Dodaj
        </Button>
      </div>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <InfiniteTable<GetConsultationsQuery['consultation'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Nazwa',
            accessor: ({ name_i18n }) => (
              <p className="my-0">{name_i18n[Locale.pl_PL]}</p>
            ),
          },
          {
            Header: 'Cena',
            accessor: ({ price }) => (
              <span className="text-nowrap">{price} zł</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/consultation/${rowData.id}`)}
                    onContextMenu={(e) => {
                      e.preventDefault()
                      window
                        .open(
                          `${window.location.origin}/consultation/${rowData.id}`,
                          '_blank'
                        )
                        ?.focus()
                    }}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => doDeleteConsultation(rowData.id)}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Consultations
