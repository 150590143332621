import Meta from '../../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useForm } from 'react-hook-form'
import {
  GetConsultationDocument,
  GetConsultationQuery,
  GetConsultationQueryVariables,
  useInsertConsultationMutation,
  useUpdateConsultationMutation,
} from '../../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import type { LocalizedField } from '../../lib/translations'
import { Locale } from '../../lib/translations'
import { TranslatedField } from '../../components/TranslatedField'
import isSvg from 'is-svg'

type FormConsultation = {
  name_i18n: LocalizedField
  description_i18n: LocalizedField
  svgIcon: string
  price: number
}

const ConsultationSettings: FC<{ id?: number; readOnly?: boolean }> = ({
  id,
  readOnly,
}) => {
  const pageTitle = 'Ustawienia'
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetConsultationFetching] = useState(false)
  const [{ fetching: updateConsultationFetching }, updateConsultation] =
    useUpdateConsultationMutation()
  const [{ fetching: insertConsultationFetching }, insertConsultation] =
    useInsertConsultationMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormConsultation>({
    defaultValues: {
      name_i18n: { pl_PL: '' },
      description_i18n: { pl_PL: '' },
      svgIcon: '',
      price: 0,
    },
  })

  useEffect(() => {
    if (id) {
      setGetConsultationFetching(true)
      client
        .query<GetConsultationQuery, GetConsultationQueryVariables>(
          GetConsultationDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.consultation_by_pk) {
            reset({
              name_i18n: data.consultation_by_pk.name_i18n || { pl_PL: '' },
              description_i18n: data.consultation_by_pk.description_i18n || {
                pl_PL: '',
              },
              svgIcon: data.consultation_by_pk.svgIcon || '',
              price: data.consultation_by_pk.price || 0,
            })
          }
        })
        .finally(() => setGetConsultationFetching(false))
    }
  }, [id, client, reset])

  async function doSaveConsultation(consultation: FormConsultation) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateConsultation({
          ...consultation,
          id,
        })
      : await insertConsultation(consultation)

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_consultation_one' in data) {
      navigate(`/consultation/${data.insert_consultation_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/consultations')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSaveConsultation)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano konsultację</Toast.Body>
          </Toast>
        </ToastContainer>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            ID
          </Form.Label>
          <Form.Label column sm="10">
            {id}
          </Form.Label>
        </Form.Group>

        <TranslatedField
          label="Nazwa"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.name_i18n?.[locale]}
                type="text"
                disabled={readOnly}
                placeholder="Nazwa"
                {...register(`name_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ?? 'Proszę podać nazwę konsultacji',
                })}
              />
              {errors.name_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.name_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <TranslatedField
          label="Opis"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.description_i18n?.[locale]}
                as="textarea"
                disabled={readOnly}
                rows={8}
                {...register(`description_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ?? 'Proszę podać opis konsultacji',
                })}
              />
              {errors.description_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.description_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Ikona konsultacji (svg)
          </Form.Label>
          <Col sm="10">
            <Form.Control
              isInvalid={!!errors.svgIcon}
              disabled={readOnly}
              as="textarea"
              rows={8}
              {...register('svgIcon', {
                validate: (value) => {
                  return value && !isSvg(value)
                    ? 'Nieprawidłowy plik SVG'
                    : undefined
                },
              })}
            />
            {errors.svgIcon && (
              <Form.Control.Feedback>
                {errors.svgIcon.message}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Cena
          </Form.Label>
          <Col sm="10">
            <Form.Control
              isInvalid={!!errors.price}
              type="text"
              disabled={readOnly}
              placeholder="Cena"
              {...register('price', {
                required: 'Proszę podać cenę konsultacji',
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: 'Nieprawidłowa cena',
                },
              })}
            />
            {errors.price && (
              <Form.Control.Feedback>
                {errors.price.message}
              </Form.Control.Feedback>
            )}{' '}
          </Col>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={
            updateConsultationFetching || insertConsultationFetching || readOnly
          }
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default ConsultationSettings
