import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetConsultationSlotsDocument,
  GetConsultationSlotsQuery,
  GetConsultationSlotsQueryVariables,
  useDeleteConsultationSlotMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { CalendarPlus, PencilSquare, Trash } from 'react-bootstrap-icons'
import { InfiniteTable } from '../../components/InfiniteTable'
import ConsultationSlotModal, {
  ConsultationSlotModalType,
} from '../../components/ConsultationSlotModal'
import moment from 'moment'

const BatchSize = 20

const ConsultationSlots: FC<{ id: number; readOnly?: boolean }> = ({
  id,
  readOnly,
}) => {
  // page content
  const pageTitle = 'Terminarz'
  const [consultationSlots, setConsultationSlots] = useState<
    GetConsultationSlotsQuery['consultation_slot']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => consultationSlots, [consultationSlots])
  const client = useClient()
  const consultationSlotModalRef = useRef<ConsultationSlotModalType>(null)
  const [, deleteConsultationSlot] = useDeleteConsultationSlotMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetConsultationSlotsQuery, GetConsultationSlotsQueryVariables>(
          GetConsultationSlotsDocument,
          {
            consultationId: id,
            offset: refetch ? 0 : offset,
            limit: BatchSize,
          },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setConsultationSlots(
          refetch
            ? data.consultation_slot
            : consultationSlots.concat(data.consultation_slot)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.consultation_slot_aggregate.aggregate?.count || 0)
      }
    },
    [client, consultationSlots, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            disabled={readOnly}
            onClick={() =>
              consultationSlotModalRef.current?.show({ consultationId: id })
            }
          >
            <CalendarPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetConsultationSlotsQuery['consultation_slot'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Data od',
            accessor: ({ dateFrom }) => (
              <span className="text-nowrap">{moment(dateFrom).format('yyyy-MM-DDTHH:mm')}</span>
            ),
          },
          {
            Header: 'Data do',
            accessor: ({ dateTo }) => (
              <span className="text-nowrap">{moment(dateTo).format('yyyy-MM-DDTHH:mm')}</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    disabled={readOnly}
                    onClick={() =>
                      consultationSlotModalRef.current?.show({
                        id: rowData.id,
                        consultationId: id,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    disabled={readOnly}
                    onClick={async () => {
                      if (window.confirm('Czy napewno chcesz usunąć slot?')) {
                        await deleteConsultationSlot({ id: rowData.id })
                        await loadMoreRows({ refetch: true })
                      }
                    }}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <ConsultationSlotModal
        ref={consultationSlotModalRef}
        onChange={() => loadMoreRows({ refetch: true })}
      />
    </div>
  )
}

export default ConsultationSlots
