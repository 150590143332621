import Meta from '../components/Meta'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router'
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap'
import {
  CalendarDate,
  CalendarEvent,
  CameraVideo,
  CashCoin,
  CheckCircleFill,
  ChevronLeft,
  DropletFill,
  DropletHalf,
  FileEarmark,
  FileEarmarkPlus,
  GenderFemale,
  GenderMale,
  Headset,
  Person,
  PersonVideo3,
  Radioactive,
  Tag,
  Telephone,
  UpcScan,
  Upload,
  X,
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import {
  Discount_Type_Enum_Enum,
  Gender_Enum,
  GetOrderQuery,
  Order_State_Enum_Enum,
  useDeleteOrderTestResultMutation,
  useGetOrderQuery,
  usePingMutation,
  useUpdateOrderConsultationFinishedOnMutation,
} from '../generated/urql.administrator'
import moment from 'moment'
import { AuthContext } from '../provider/auth'
import {
  getOrderStateLabel,
  OrderState2BgVariant,
  OrderState2Label,
} from '../lib/order'
import { guessStorageAddress } from '../lib/api'
import { isIdentity } from '../lib/identity'
import TestResultConfirmationModal, {
  TestResultConfirmationModalType,
  ValidTestResult,
} from '../components/TestResultConfirmationModal'
import { FilenamePattern } from '../lib/testResult'

const pageTitle = 'Szczegóły badania'

type OrderTest = NonNullable<GetOrderQuery['order_by_pk']>['orderTests'][number]
type OrderConsultation = NonNullable<
  GetOrderQuery['order_by_pk']
>['orderConsultations'][number]
type OrderTraining = NonNullable<
  GetOrderQuery['order_by_pk']
>['orderTrainings'][number]

function isOrderTest(
  item: OrderTest | OrderConsultation | OrderTraining
): item is OrderTest {
  return 'test' in item
}

function isOrderConsultation(
  item: OrderTest | OrderConsultation | OrderTraining
): item is OrderConsultation {
  return 'consultation' in item
}

function isOrderTraining(
  item: OrderTest | OrderConsultation | OrderTraining
): item is OrderTraining {
  return 'training' in item
}

function renderIdentity(identity: unknown) {
  if (!isIdentity(identity)) {
    return 'Nieznany rodzaj tożsamości'
  }

  switch (identity.type) {
    case 'id_card':
      return (
        <span>
          {identity.value}{' '}
          <small>
            (dowód tożsamości, {identity.countryCode}, {identity.dateOfBirth})
          </small>
        </span>
      )
    case 'passport':
      return (
        <span>
          {identity.value}{' '}
          <small>
            (paszport, {identity.countryCode}, {identity.dateOfBirth})
          </small>
        </span>
      )
    case 'pesel':
      return (
        <span>
          {identity.value} <small>(PESEL)</small>
        </span>
      )
  }
}

function getDiscountBadge(type: Discount_Type_Enum_Enum) {
  switch (type) {
    case Discount_Type_Enum_Enum.SomeTests:
      return <Badge bg="secondary">Wiele badań</Badge>
    case Discount_Type_Enum_Enum.AllTests:
      return <Badge bg="success">Wszystkie badania</Badge>
    case Discount_Type_Enum_Enum.WholeOrder:
      return <Badge bg="primary">Całe zamówienie</Badge>
    case Discount_Type_Enum_Enum.CollectionCost:
      return <Badge bg="secondary">Koszt pobrania</Badge>
    default:
      return <span>?</span>
  }
}

const Order: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const [{ data }, refetchGetOrder] = useGetOrderQuery({
    variables: { id: parseInt(id!) },
    requestPolicy: 'network-only',
  })
  const [generalError, setGeneralError] = useState<string>()
  const [, updateOrderConsultationFinishedOn] =
    useUpdateOrderConsultationFinishedOnMutation()
  const [documents, setDocuments] = useState<string[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [, deleteOrderTestResult] = useDeleteOrderTestResultMutation()
  const [checkedOrderTestIds, setCheckedOrderTestIds] = useState<number[]>([])
  const [, ping] = usePingMutation()
  const testResultConfirmationModalRef =
    useRef<TestResultConfirmationModalType>(null)

  const order = data?.order_by_pk
  const orderCollection = order?.orderCollections[0]

  const orderItems = useMemo(
    () => [
      ...(order?.orderTests || []),
      ...(order?.orderConsultations || []),
      ...(order?.orderTrainings || []),
    ],
    [order?.orderConsultations, order?.orderTests, order?.orderTrainings]
  )

  const uploadOrderTestResult = useCallback(
    async (orderTests: ValidTestResult['orderTests'], files: FileList) => {
      setGeneralError(undefined)

      await ping({}, { requestPolicy: 'network-only' })

      if (orderTests.length === 1) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const matches = file.name.match(FilenamePattern)
          if (matches) {
            const [, tubeCode, , laboratoryCode] = matches
            if (
              orderTests[0].tubeCode === tubeCode ||
              orderTests[0].laboratoryCode === laboratoryCode
            ) {
              continue
            }
          }

          setGeneralError(`Nieprawidłowa nazwa pliku ${file.name}`)
          return
        }
      }

      testResultConfirmationModalRef.current?.show(
        [...files].map((file) => ({
          file,
          orderTests,
        }))
      )
    },
    [ping]
  )

  const downloadFile = useCallback(
    async (id: number) => {
      const response = await fetch(`${guessStorageAddress()}/files/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })

      const blob = await response.blob()
      return window.URL.createObjectURL(blob)
    },
    [auth.accessToken]
  )

  const openFile = useCallback(
    async (id: number) => {
      try {
        window.location.assign(await downloadFile(id))
      } catch (error) {
        console.error(error)
      }
    },
    [downloadFile]
  )

  useEffect(() => {
    if (order) {
      Promise.all(
        order.documents.map(async (document) => downloadFile(document.fileId))
      )
        .then(setDocuments)
        .catch(console.error)
    }
  }, [order, downloadFile])

  if (!order) {
    return <div>Ładowanie...</div>
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-between">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/orders')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <>
        <div className="d-flex">
          <div className="flex-grow-1 w-50">
            <h4 className="mt-5">Informacje</h4>
            {!!orderCollection && (
              <>
                <p>
                  <span className="fw-bold">Data zlecenia: </span>
                  {moment(orderCollection.collectionDate).format(
                    'D MMM YYYY HH:mm'
                  )}
                </p>
                <p>
                  <span className="fw-bold">Osoba pobierająca: </span>
                  {orderCollection.nurse?.deletedAt && (
                    <del>{orderCollection.nurse?.name}</del>
                  )}
                  {!orderCollection.nurse?.deletedAt && (
                    <span>{orderCollection.nurse?.name}</span>
                  )}
                </p>
              </>
            )}
            <p>
              <span className="fw-bold">Email klienta: </span>
              <a
                href={`mailto:${order.client.email}?subject=Zamówienie%20nr%20${order.id}`}
              >
                {order.client.email}
              </a>
            </p>
            <p>
              <span className="fw-bold">Status: </span>
              <Badge
                bg={
                  OrderState2BgVariant[
                    order.currentState as Order_State_Enum_Enum
                  ]
                }
              >
                {getOrderStateLabel(order)}
              </Badge>
            </p>

            {!!orderCollection && (
              <>
                <h4 className="mt-5">Miejsce pobrania</h4>
                <p>
                  <span className="fw-bold">Adres: </span>
                  {orderCollection.address}, {orderCollection.postalCode}{' '}
                  {orderCollection.city}
                </p>
                <p>
                  <span className="fw-bold">Numer telefonu: </span>
                  {orderCollection.phoneNumber}
                </p>
              </>
            )}

            {!!orderCollection?.nurseNotes && (
              <>
                <h4 className="mt-5">Notatki osoby pobierającej</h4>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {orderCollection.nurseNotes}
                </p>
              </>
            )}

            {order.orderDiscounts.length > 0 && (
              <>
                <h4 className="mt-5">Rabaty</h4>
                <ul>
                  {order.orderDiscounts.map((orderDiscount) => (
                    <li>
                      <span>
                        <strong>{orderDiscount.discount.percent}</strong>
                        <span className="small">%</span>
                      </span>{' '}
                      {getDiscountBadge(orderDiscount.discount.type)} (
                      <span
                        className={`${
                          orderDiscount.discount.deletedAt
                            ? 'text-decoration-line-through'
                            : ''
                        } small`}
                      >
                        {orderDiscount.discount.code}
                      </span>
                      )
                    </li>
                  ))}
                </ul>
              </>
            )}

            {documents.length > 0 && (
              <>
                <h4 className="mt-5">Dokumenty pacjenta</h4>
                <ul className="d-flex list-unstyled">
                  {documents.map((uri) => (
                    <li className="me-2">
                      <a href={uri} target="_blank" rel="noreferrer">
                        <img src={uri} alt="" className="img-thumbnail" />
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className="flex-grow-1 w-50">
            <h4 className="mt-5">Koszty</h4>
            {!!orderCollection && (
              <>
                <p>
                  <span className="fw-bold">Koszt pobrania: </span>
                  <span>{orderCollection.collectionCost.toFixed(2)} zł</span>
                </p>
                {orderCollection.collectionCostBeforeDiscount && (
                  <p>
                    <span className="fw-bold">
                      Koszt pobrania przed rabatem:{' '}
                    </span>
                    <span>
                      {orderCollection.collectionCostBeforeDiscount.toFixed(2)}{' '}
                      zł
                    </span>
                  </p>
                )}
                <p>
                  <span className="fw-bold">Koszt zestawu: </span>
                  <span>{orderCollection.collectionKitCost.toFixed(2)} zł</span>
                </p>
                <p>
                  <span className="fw-bold">Koszt dostawy: </span>
                  <span>{orderCollection.deliveryCost.toFixed(2)} zł</span>
                </p>
              </>
            )}
            <p>
              <span className="fw-bold">Koszt całkowity: </span>
              <span>{order.totalCost.toFixed(2)} zł</span>
            </p>
            {order.costBeforeDiscount && (
              <p>
                <span className="fw-bold">Koszt przed rabatem: </span>
                <span>{order.costBeforeDiscount.toFixed(2)} zł</span>
              </p>
            )}

            <h4 className="mt-5">Zgody</h4>
            <p>
              <span className="fw-bold">Zgoda 1: </span>
              {order.clientConsent1
                ? `udzielona ${moment(order.firstStateDate).format(
                    'D MMM YYYY HH:mm'
                  )}`
                : 'brak'}
              <p className="small text-muted">
                Wyrażam zgodę na realizację zamówionych przeze mnie badań
                diagnostycznych i/lub usług dodatkowych, pobranie ode mnie
                właściwego materiału diagnostycznego i wykonanie badań.
              </p>
            </p>
            <p>
              <span className="fw-bold">Zgoda 2: </span>
              {order.clientConsent2
                ? `udzielona ${moment(order.firstStateDate).format(
                    'D MMM YYYY HH:mm'
                  )}`
                : 'brak'}
              <p className="small text-muted">
                Oświadczam, iż zapoznałam/ em się i akceptuję Regulamin
                Aplikacji Home Lab oraz Politykę prywatności Aplikacji Home Lab.
              </p>
            </p>
            <p>
              <span className="fw-bold">Zgoda 3: </span>
              {order.clientConsent3
                ? `udzielona ${moment(order.firstStateDate).format(
                    'D MMM YYYY HH:mm'
                  )}`
                : 'brak'}
              <p className="small text-muted">
                Oświadczam, iż zlecane przeze mnie za pośrednictwem Aplikacji
                Home Lab badania laboratoryjne są wykonywane w celu zapewnienia
                mnie lub osobie/małoletniemu pozostającemu pod moją władzą
                rodzicielską, opieką lub kuratelą opieki medycznej w zakresie
                profilaktyki zdrowia, dbania o zdrowie, ratowania zdrowia,
                przywracaniu i poprawie zdrowia. Akceptuję metody pobierania
                materiału diagnostycznego przez Osobę Uprawnioną do Pobierania
                Materiału Diagnostycznego oraz metody wykonywania badań
                praktykowane w laboratoriach wykonujących badania laboratoryjne.
                Oświadczam także, iż zlecone badania nie będą wykorzystywane w
                celach związanych z opieką medyczną w zakresie:
                <ul>
                  <li>
                    testów genetycznych mających na celu ustalenie ojcostwa lub
                    identyfikacji zwłok, diagnozy, opinii albo czynności
                    wykonywanych do celów medycyny sądowej,
                  </li>
                  <li>
                    badań w celu uzyskania renty inwalidzkiej czy dla celów
                    ubiegania się o polisę ubezpieczeniową,
                  </li>
                  <li>
                    chirurgii plastycznej i innych zabiegów kosmetycznych, nie
                    służącym przywracaniu lub poprawie zdrowia,
                  </li>
                  <li>bankowania krwi pępowinowej i komórek macierzystych.</li>
                </ul>
              </p>
            </p>
          </div>
        </div>

        <h4 className="mt-5">Produkty</h4>
        <ListGroup>
          {orderItems.map((orderItem) => {
            if (isOrderTest(orderItem)) {
              return (
                <ListGroup.Item
                  key={`test-${orderItem.id}`}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Form.Check
                    className="me-1 d-print-none"
                    checked={checkedOrderTestIds.includes(orderItem.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckedOrderTestIds([
                          ...checkedOrderTestIds,
                          orderItem.id,
                        ])
                      } else {
                        setCheckedOrderTestIds(
                          checkedOrderTestIds.filter(
                            (orderTestId) => orderTestId !== orderItem.id
                          )
                        )
                      }
                    }}
                  />
                  <div className="ms-2 me-auto w-100">
                    <Row className="mb-3">
                      <Col>
                        <div
                          className={
                            orderItem.test.deletedAt
                              ? 'text-decoration-line-through'
                              : ''
                          }
                        >
                          <p className="small m-0">
                            <DropletHalf size={16} className="me-1" />
                            Badanie
                          </p>
                          <strong>{orderItem.test.name}</strong>
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          title="Dodaj wynik"
                          className="ms-2 d-flex align-items-center"
                          onClick={(event) => {
                            event.currentTarget
                              .querySelectorAll('input[type=file]')
                              .forEach((input: any) => input.click())
                          }}
                        >
                          <FileEarmarkPlus size={20} />
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="visually-hidden"
                            multiple
                            accept="application/pdf"
                            onChange={(event) => {
                              if (event.target.files?.length) {
                                event.target.files?.length &&
                                  uploadOrderTestResult(
                                    [orderItem],
                                    event.target.files
                                  )
                              }
                            }}
                          />
                        </Button>
                      </Col>
                    </Row>

                    <Row className="w-100">
                      <Col xs={{ span: 6 }}>
                        <div className="d-flex align-items-center">
                          <UpcScan size={20} className="me-1" />
                          {orderItem.tubeCode}
                        </div>
                        {orderItem.collectedOn && (
                          <div
                            className="d-flex align-items-center"
                            title="Data pobrania"
                          >
                            <DropletFill size={20} className="me-1" />
                            <span>
                              {new Date(orderItem.collectedOn).toLocaleString()}
                            </span>
                          </div>
                        )}
                        {!!orderItem.cost && (
                          <div className="d-flex align-items-center">
                            <CashCoin size={20} className="me-1" />
                            <span>
                              {orderItem.cost.toFixed(2)} zł
                              {orderItem.costBeforeDiscount && (
                                <>
                                  {' '}
                                  (przed rabatem:{' '}
                                  {orderItem.costBeforeDiscount.toFixed(2)} zł)
                                </>
                              )}
                            </span>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <Person size={20} className="me-1" />
                          <span>
                            {orderItem.firstName} {orderItem.lastName}
                          </span>
                        </div>
                        {orderItem.gender && (
                          <div className="d-flex align-items-center">
                            {orderItem.gender === Gender_Enum.Male && (
                              <>
                                <GenderMale size={20} className="me-1" />
                                <span>Mężczyzna</span>
                              </>
                            )}
                            {orderItem.gender === Gender_Enum.Female && (
                              <>
                                <GenderFemale size={20} className="me-1" />
                                <span>Kobieta</span>
                              </>
                            )}
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <PersonVideo3 size={20} className="me-1" />
                          {renderIdentity(orderItem.identity)}
                        </div>
                      </Col>
                      <Col xs={{ span: 6 }}>
                        {orderItem.collectionPoint && (
                          <div className="d-flex align-items-center">
                            <Radioactive size={20} className="me-1" />
                            <span>
                              <a
                                href={`/laboratory/${orderItem.collectionPoint.laboratory.id}`}
                              >
                                {orderItem.collectionPoint.laboratory.shortName}{' '}
                                / {orderItem.collectionPoint.name}
                              </a>
                              , {orderItem.collectionPoint.address},{' '}
                              {orderItem.collectionPoint.city} (
                              {orderItem.collectionPoint.postalCode})
                            </span>
                          </div>
                        )}
                        {orderItem.laboratoryCode && (
                          <div className="d-flex align-items-center">
                            <Tag size={20} className="me-1" />
                            <span>{orderItem.laboratoryCode}</span>
                          </div>
                        )}
                        {orderItem.finishedOn && (
                          <div
                            className="d-flex align-items-center"
                            title="Data wysłania wyniku do klienta"
                          >
                            <CheckCircleFill size={20} className="me-1" />
                            <span>
                              {new Date(orderItem.finishedOn).toLocaleString()}
                            </span>
                          </div>
                        )}
                        {orderItem.collectionPoint &&
                          !!orderItem.testResults.length &&
                          orderItem.testResults.map((testResult) => (
                            <div
                              className="d-flex align-items-center"
                              key={testResult.file.id}
                            >
                              <FileEarmark size={20} className="me-1" />
                              <Button
                                onClick={() => openFile(testResult.file.id)}
                                className="p-0"
                                variant="link"
                              >
                                {testResult.originalFilename}
                              </Button>
                              <Button
                                variant="link"
                                className="p-0 d-print-none"
                                onClick={async () => {
                                  if (
                                    window.confirm(
                                      'Czy napewno chcesz usunąć wynik badania?'
                                    )
                                  ) {
                                    const { error } =
                                      await deleteOrderTestResult({
                                        fileId: testResult.file.id,
                                        orderTestId: orderItem.id,
                                      })
                                    if (error) {
                                      setGeneralError(error.message)
                                      return
                                    }

                                    refetchGetOrder({
                                      requestPolicy: 'network-only',
                                    })
                                  }
                                }}
                              >
                                <X size={22} color="#f56565" />
                              </Button>
                            </div>
                          ))}
                      </Col>
                    </Row>
                  </div>
                </ListGroup.Item>
              )
            } else if (isOrderConsultation(orderItem)) {
              return (
                <ListGroup.Item key={`consultation-${orderItem.id}`}>
                  <Row className="ms-3 mb-3">
                    <Col>
                      <div
                        className={
                          orderItem.consultation.deletedAt
                            ? 'text-decoration-line-through'
                            : ''
                        }
                      >
                        <p className="small m-0">
                          <Headset size={16} className="me-1" />
                          Konsultacja
                        </p>
                        <strong>{orderItem.consultation.name}</strong>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      {!orderItem.finishedOn &&
                        !['Unpaid', 'Cancelled'].includes(
                          order?.currentState || ''
                        ) && (
                          <>
                            <Button
                              variant="success"
                              className="d-flex align-items-center"
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    'Czy napewno chcesz zakończyć konsultację?'
                                  )
                                ) {
                                  const { error } =
                                    await updateOrderConsultationFinishedOn({
                                      id: orderItem.id,
                                    })
                                  if (error) {
                                    setGeneralError(error.message)
                                    return
                                  }

                                  refetchGetOrder({
                                    requestPolicy: 'network-only',
                                  })
                                }
                              }}
                            >
                              <CheckCircleFill size={20} className="me-1" />
                              Zakończ konsultację
                            </Button>
                          </>
                        )}
                    </Col>
                  </Row>

                  <Row className="ms-3 w-100">
                    <Col xs={{ span: 6 }}>
                      {orderItem.cost && (
                        <div className="d-flex align-items-center">
                          <CashCoin size={20} className="me-1" />
                          <span>{orderItem.cost.toFixed(2)} zł</span>
                        </div>
                      )}
                      <div className="d-flex align-items-center">
                        <Person size={20} className="me-1" />
                        <span>
                          {orderItem.firstName} {orderItem.lastName}
                        </span>
                      </div>
                      {orderItem.gender && (
                        <div className="d-flex align-items-center">
                          {orderItem.gender === Gender_Enum.Male && (
                            <>
                              <GenderMale size={20} className="me-1" />
                              <span>Mężczyzna</span>
                            </>
                          )}
                          {orderItem.gender === Gender_Enum.Female && (
                            <>
                              <GenderFemale size={20} className="me-1" />
                              <span>Kobieta</span>
                            </>
                          )}
                        </div>
                      )}
                      <div className="d-flex align-items-center">
                        <PersonVideo3 size={20} className="me-1" />
                        {renderIdentity(orderItem.identity)}
                      </div>
                    </Col>
                    <Col xs={{ span: 6 }}>
                      {orderItem.preferredDate && (
                        <div
                          className="d-flex align-items-center"
                          title="Wybrana data konsultacji"
                        >
                          <CalendarEvent size={20} className="me-1" />
                          <span>
                            {new Date(orderItem.preferredDate).toLocaleString()}
                          </span>
                        </div>
                      )}
                      <div
                        className="d-flex align-items-center"
                        title="Nr telefonu"
                      >
                        <Telephone size={20} className="me-1" />
                        <span>{orderItem.phoneNumber}</span>
                      </div>
                      {!!orderItem.finishedOn && (
                        <div
                          className="d-flex align-items-center"
                          title="Data zakończenia konsultacji"
                        >
                          <CheckCircleFill size={20} className="me-1" />
                          <span>
                            {new Date(orderItem.finishedOn).toLocaleString()}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              )
            } else if (isOrderTraining(orderItem)) {
              return (
                <ListGroup.Item key={`training-${orderItem.id}`}>
                  <Row className="ms-3 mb-3">
                    <Col>
                      <div
                        className={
                          orderItem.training.deletedAt
                            ? 'text-decoration-line-through'
                            : ''
                        }
                      >
                        <p className="small m-0">
                          <CameraVideo size={16} className="me-1" />
                          Szkolenie
                        </p>

                        <strong>{orderItem.training.name}</strong>
                      </div>
                    </Col>
                  </Row>
                  <Row className="ms-3 w-100">
                    <Col xs={{ span: 6 }}>
                      {orderItem.cost && (
                        <div className="d-flex align-items-center">
                          <CashCoin size={20} className="me-1" />
                          <span>{orderItem.cost.toFixed(2)} zł</span>
                        </div>
                      )}
                    </Col>
                    <Col xs={{ span: 6 }}></Col>
                  </Row>
                </ListGroup.Item>
              )
            } else {
              return <span>unknown item</span>
            }
          })}
        </ListGroup>
        {checkedOrderTestIds.length > 0 && (
          <Button
            title="Prześlij wyniki dla wybranych badań"
            className="mt-2 d-flex align-items-center d-print-none"
            onClick={(event) => {
              event.currentTarget
                .querySelectorAll('input[type=file]')
                .forEach((input: any) => input.click())
            }}
          >
            <Upload size={20} className="me-2" />
            Prześlij wyniki dla wybranych badań
            <input
              ref={fileInputRef}
              type="file"
              className="visually-hidden"
              multiple
              accept="application/pdf"
              onChange={async (event) => {
                if (event.target.files?.length && data.order_by_pk) {
                  await uploadOrderTestResult(
                    data.order_by_pk.orderTests.filter((orderTest) =>
                      checkedOrderTestIds.includes(orderTest.id)
                    ),
                    event.target.files
                  )

                  setCheckedOrderTestIds([])
                }
              }}
            />
          </Button>
        )}

        <h4 className="mt-5">Historia statusów</h4>
        <ListGroup className="w-50">
          {order.orderStates
            .filter(
              (orderState, currentIndex, array) =>
                array[currentIndex].state !==
                  Order_State_Enum_Enum.OnTheWayToClient ||
                currentIndex === 0 ||
                array[currentIndex - 1].state !== array[currentIndex].state
            )
            .map((orderState) => (
              <ListGroup.Item className="" key={orderState.id}>
                <div className="ms-2 me-auto d-flex gap-2">
                  <div className="d-flex align-items-center">
                    <CalendarDate size={16} className="me-1" />
                    <span className="text-muted small">
                      {new Date(orderState.date).toLocaleString()}
                    </span>
                  </div>
                  <Badge bg={OrderState2BgVariant[orderState.state]}>
                    {OrderState2Label[orderState.state]}
                  </Badge>
                </div>
                <div className="ms-2">
                  {orderState.state ===
                    Order_State_Enum_Enum.DifferentDateProposed && (
                    <span>
                      Zaproponowana data pobrania:{' '}
                      <strong>
                        {moment(orderState.extra.proposedDate).format(
                          'yyyy-MM-DD HH:mm'
                        )}
                      </strong>
                    </span>
                  )}
                </div>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </>

      <TestResultConfirmationModal
        ref={testResultConfirmationModalRef}
        onFinished={() => {
          refetchGetOrder({ requestPolicy: 'network-only' })
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }}
      />
    </div>
  )
}

export default Order
