import Meta from '../components/Meta'
import { FC } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router'
import AdministratorNotifications from './administrator/AdministratorNotifications'

const Administrator: FC = () => {
  const pageTitle = 'Administrator'
  const { id } = useParams()

  if (!id) {
    return <div/>
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <Tabs defaultActiveKey="notifications" className="mb-3 mt-3">
        <Tab eventKey="notifications" title="Powiadomienia">
          <AdministratorNotifications id={parseInt(id)} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Administrator
