import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  GetClientsDocument,
  GetClientsQuery,
  GetClientsQueryVariables,
  Order_By,
  TestFragment,
  useGetClientProfilesQuery,
  useGetTestsByCollectionPointAndNurseQuery,
} from '../generated/urql.administrator'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { useClient } from 'urql'
import { useForm, Controller } from 'react-hook-form'
import { EmailRegex, PhoneNumberRegex } from '../lib/patterns'
import { ClientIdentity } from '../lib/identity'

export type OrderAddModalType = {
  show: (args: { collectionPointId: number; nurseId: number }) => void
}

export type FormOrder = {
  clientId?: number
  email: string
  clientProfileId?: number
  firstName: string
  lastName: string
  phoneNumber: string
  identity?: ClientIdentity
  tests: Array<Pick<TestFragment, 'id' | 'name'>>
}

const isFormOrder = (order: Partial<FormOrder>): order is FormOrder =>
  !!order.email && !!order.firstName && !!order.lastName && !!order.phoneNumber

const OrderAddModal = forwardRef<
  OrderAddModalType,
  {
    onChange: (order: FormOrder) => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const client = useClient()
  const [collectionPointId, setCollectionPointId] = useState<number>()
  const [nurseId, setNurseId] = useState<number>()
  const [generalError, setGeneralError] = useState<string>()
  const [{ data: testsData, fetching: testsFetching }] =
    useGetTestsByCollectionPointAndNurseQuery({
      pause: !collectionPointId || !nurseId,
      variables: {
        collectionPointId: collectionPointId || 0,
        nurseId: nurseId || 0,
        orderBy: { name: Order_By.Asc },
        limit: 0xffff,
      },
    })
  const {
    control,
    handleSubmit,
    register,
    watch,
    resetField,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    clientId?: number
    email?: string
    clientProfileId?: number
    firstName?: string
    lastName?: string
    phoneNumber?: string
    identity?: ClientIdentity
    tests: Array<Pick<TestFragment, 'id' | 'name'>>
  }>({
    defaultValues: {
      clientId: undefined,
      email: undefined,
      clientProfileId: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      identity: undefined,
      tests: [],
    },
  })
  const clientId = watch('clientId')
  const email = watch('email')
  const clientProfileId = watch('clientProfileId')
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const identity = watch('identity')
  const phoneNumber = watch('phoneNumber')

  const [{ data: clientProfilesData, fetching: clientProfilesFetching }] =
    useGetClientProfilesQuery({
      variables: {
        clientId: clientId || 0,
      },
    })
  const clientProfileOptions = useMemo(
    () =>
      (clientProfilesData?.client_profile || []).map((clientProfile) => ({
        get label() {
          return `${this.firstName} ${this.lastName} (${this.phoneNumber})`
        },
        value: clientProfile.id,
        firstName: clientProfile.firstName,
        lastName: clientProfile.lastName,
        phoneNumber: clientProfile.phoneNumber,
        identity: clientProfile.identity as ClientIdentity | undefined,
      })),
    [clientProfilesData?.client_profile]
  )

  const testOptions = useMemo(
    () =>
      (testsData?.tests_by_collection_point_and_nurse || []).map((test) => ({
        label: test.name,
        value: test.id,
        test,
      })),
    [testsData?.tests_by_collection_point_and_nurse]
  )

  useImperativeHandle(ref, () => ({
    show: ({ collectionPointId, nurseId }) => {
      reset()
      setCollectionPointId(collectionPointId)
      setNurseId(nurseId)
      setShow(true)
    },
  }))

  async function doSaveOrder(order: Partial<FormOrder>) {
    if (!isFormOrder(order)) {
      setGeneralError('Nieprawidłowe dane')
      return
    }
    onChange(order)
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setNurseId(undefined)
        setCollectionPointId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form
        onSubmit={handleSubmit(doSaveOrder, console.error)}
        className="mt-4"
      >
        <Modal.Header closeButton>
          <Modal.Title>Zamówienie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Pole wymagane',
              pattern: {
                value: EmailRegex,
                message: 'Nieprawidłowy email',
              },
            }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <AsyncCreatableSelect
                  loadOptions={async (inputValue: string) => {
                    const { data: clientsData } = await client
                      .query<GetClientsQuery, GetClientsQueryVariables>(
                        GetClientsDocument,
                        { where: { email: { _like: `%${inputValue}%` } } }
                      )
                      .toPromise()

                    return (clientsData?.client || []).map((client) => ({
                      label: client.email,
                      value: client.id,
                    }))
                  }}
                  value={
                    (clientId || email) && { label: email, value: clientId }
                  }
                  onCreateOption={(inputValue) => {
                    resetField('clientId')
                    onChange(inputValue)
                  }}
                  onChange={(option) => {
                    setValue('clientId', option ? option.value : undefined)
                    onChange(option ? option.label : undefined)
                  }}
                  placeholder="Wybierz klienta"
                />
                {!!error && (
                  <p className="small text-danger">{error.message}</p>
                )}
              </>
            )}
          />

          {!!email && (
            <Controller
              control={control}
              name="clientProfileId"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <CreatableSelect
                  placeholder="Wybierz profil"
                  className="mt-3"
                  options={clientProfileOptions}
                  value={
                    firstName
                      ? {
                          label:
                            `${firstName} ${lastName}` +
                            (phoneNumber ? ` (${phoneNumber})` : ''),
                          value: clientProfileId,
                          firstName,
                          lastName,
                          phoneNumber,
                          identity,
                        }
                      : undefined
                  }
                  isLoading={clientProfilesFetching}
                  isDisabled={clientProfilesFetching}
                  onChange={(option) => {
                    onChange(option ? option.value : undefined)
                    setValue('firstName', option ? option.firstName : undefined)
                    setValue('lastName', option ? option.lastName : undefined)
                    setValue('identity', option ? option.identity : undefined)
                    setValue(
                      'phoneNumber',
                      option ? option.phoneNumber : undefined
                    )
                  }}
                  onCreateOption={(inputValue) => {
                    onChange(undefined)
                    setValue('firstName', inputValue.split(' ')[0])
                    setValue('lastName', inputValue.split(' ')[1])
                    resetField('phoneNumber')
                  }}
                  isSearchable
                />
              )}
            />
          )}

          {!!firstName && !!lastName && !clientProfileId && (
            <FloatingLabel label="Nr telefonu" className="mt-3">
              <Form.Control
                type="text"
                {...register('phoneNumber', {
                  required: 'Pole wymagane',
                  pattern: {
                    value: PhoneNumberRegex,
                    message: 'Nieprawidłowy numer telefonu',
                  },
                })}
              />
              {errors.phoneNumber && (
                <p className="small text-danger">
                  {errors.phoneNumber.message}
                </p>
              )}
            </FloatingLabel>
          )}

          <Controller
            control={control}
            name="tests"
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                isSearchable
                className="mt-3"
                isLoading={testsFetching}
                isDisabled={testsFetching || !email || !phoneNumber}
                options={testOptions}
                value={value.map((test) =>
                  testOptions.find((o) => o.test.id === test.id)
                )}
                onChange={(options) =>
                  onChange(
                    options
                      .filter(
                        (option): option is typeof testOptions[number] =>
                          !!option
                      )
                      .map((option) => option.test)
                  )
                }
                placeholder="Wybierz badania"
              />
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            Dodaj
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default OrderAddModal
