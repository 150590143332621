import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  GetLaboratoryTestDocument,
  GetLaboratoryTestQuery,
  GetLaboratoryTestQueryVariables,
  GetLaboratoryTestsDocument,
  GetLaboratoryTestsQuery,
  GetLaboratoryTestsQueryVariables,
  useGetAvailableTestsQuery,
  useInsertLaboratoryTestMutation,
  useUpdateLaboratoryTestMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'
import { testPriceRangeToString } from '../lib/test'

export type LaboratoryTestModalType = {
  show: (args: { id?: number; laboratoryId: number }) => void
}

type FormLaboratoryTest = {
  testId: number
  price: number
}

const LaboratoryTestModal = forwardRef<
  LaboratoryTestModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState<number>()
  const [laboratoryId, setLaboratoryId] = useState<number>()
  const client = useClient()
  const [, setGetLaboratoryTestFetching] = useState(false)
  const [{ fetching: updateLaboratoryTestFetching }, updateLaboratoryTest] =
    useUpdateLaboratoryTestMutation()
  const [{ fetching: insertLaboratoryTestFetching }, insertLaboratoryTest] =
    useInsertLaboratoryTestMutation()
  const [{ data: availableTestsData, fetching: availableTestsFetching }] =
    useGetAvailableTestsQuery({ variables: { limit: 0xfffff } })
  const [laboratoryTests, setLaboratoryTests] = useState<
    GetLaboratoryTestsQuery['laboratory_test']
  >([])
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormLaboratoryTest>({
    defaultValues: {
      testId: undefined,
      price: 0,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ id, laboratoryId }) => {
      setId(id)
      setLaboratoryId(laboratoryId)
      reset({
        testId: undefined,
        price: 0,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (!laboratoryId) return

    client
      .query<GetLaboratoryTestsQuery, GetLaboratoryTestsQueryVariables>(
        GetLaboratoryTestsDocument,
        {
          laboratoryId,
          limit: 0xffff,
        }
      )
      .toPromise()
      .then(({ data }) => {
        if (data?.laboratory_test) {
          setLaboratoryTests(data.laboratory_test)
        }
      })
      .finally(() => setGetLaboratoryTestFetching(false))
  }, [laboratoryId])

  useEffect(() => {
    if (id) {
      setGetLaboratoryTestFetching(true)
      client
        .query<GetLaboratoryTestQuery, GetLaboratoryTestQueryVariables>(
          GetLaboratoryTestDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.laboratory_test_by_pk) {
            reset({
              testId: data.laboratory_test_by_pk.testId,
              price: data.laboratory_test_by_pk.price,
            })
          }
        })
        .finally(() => setGetLaboratoryTestFetching(false))
    }
  }, [id, client, reset])

  async function doSaveLaboratoryTest(laboratoryTest: FormLaboratoryTest) {
    setGeneralError(undefined)

    if (!laboratoryId) {
      setGeneralError('Missing laboratoryId')
      return
    }

    const { error } = id
      ? await updateLaboratoryTest({
          ...laboratoryTest,
          laboratoryId,
          id,
        })
      : await insertLaboratoryTest({
          ...laboratoryTest,
          laboratoryId,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setId(undefined)
    setLaboratoryId(undefined)
    onChange()
  }

  const availableTests = useMemo(() => {
    return (availableTestsData?.test || []).filter(
      ({ id }) =>
        id === watch('testId') ||
        !laboratoryTests.find((laboratoryTest) => laboratoryTest.testId === id)
    )
  }, [laboratoryTests, availableTestsData, watch])

  return (
    <Modal
      show={show}
      onHide={() => {
        setId(undefined)
        setLaboratoryId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveLaboratoryTest)}>
        <Modal.Header closeButton>
          <Modal.Title>Laboratorium - badanie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="testId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Badanie" className="mb-3">
                <Form.Select
                  disabled={!availableTests.length || availableTestsFetching}
                  value={String(value)}
                  onChange={onChange}
                >
                  <option>Proszę wybrać badanie</option>
                  {availableTests.map((test) => (
                    <option value={String(test.id)} key={test.id}>
                      {test.name} -{' '}
                      {test.laboratoryTests_aggregate.aggregate &&
                        testPriceRangeToString(
                          test.laboratoryTests_aggregate.aggregate
                        )}
                    </option>
                  ))}
                </Form.Select>
                {errors.testId && (
                  <Form.Control.Feedback>
                    {errors.testId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />

          <FloatingLabel label="Cena" className="mb-3">
            <Form.Control
              isInvalid={!!errors.price}
              type="text"
              placeholder="Cena"
              {...register('price', {
                required: 'Proszę podać cenę badania',
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: 'Nieprawidłowa cena',
                },
              })}
            />
            {errors.price && (
              <Form.Control.Feedback>
                {errors.price.message}
              </Form.Control.Feedback>
            )}{' '}
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={
              updateLaboratoryTestFetching || insertLaboratoryTestFetching
            }
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default LaboratoryTestModal
