import {
  GetLaboratoriesQuery,
  GetMaterialsQuery,
  GetTagsQuery,
  GetTestsQuery,
  Laboratory_Test_Arr_Rel_Insert_Input,
  Laboratory_Test_Insert_Input,
  Test_Insert_Input,
  Test_Material_Arr_Rel_Insert_Input,
  Test_Material_Insert_Input,
  Test_Tag_Arr_Rel_Insert_Input,
  Test_Tag_Insert_Input,
  Test_Updates,
} from '../generated/urql.administrator'
import ExcelJS, {
  CellValue,
  CellRichTextValue,
  CellErrorValue,
  CellHyperlinkValue,
  CellFormulaValue,
  CellSharedFormulaValue,
} from 'exceljs'
import _ from 'lodash'
import { Locale } from './translations'

export function testPriceRangeToString({
  min,
  max,
}: {
  min?: { price?: number | null } | null
  max?: { price?: number | null } | null
}) {
  if (
    min?.price === undefined ||
    min.price === null ||
    max?.price === undefined ||
    max.price === null
  ) {
    return '-'
  }

  if (min.price === max.price) {
    return `${min.price} zł`
  }

  return `${min.price} - ${max.price} zł`
}

function cellValueToPrimitive(value: CellValue) {
  if (isRichTextCellValue(value)) {
    return value.richText.reduce((text, part) => text + part.text, '')
  } else if (isErrorValue(value)) {
    return value.error
  } else if (isCellHyperlinkValue(value)) {
    return value.text
  } else if (isCellSharedFormulaValue(value)) {
    return value.result || value.formula
  } else if (isCellFormulaValue(value)) {
    return value.result || value.formula
  } else {
    return value
  }
}

function isRichTextCellValue(value: CellValue): value is CellRichTextValue {
  return (
    typeof value === 'object' &&
    value !== null &&
    'richText' in value &&
    Array.isArray(value.richText) &&
    value.richText.every((richText) => 'text' in richText)
  )
}

function isErrorValue(value: CellValue): value is CellErrorValue {
  return typeof value === 'object' && value !== null && 'error' in value
}

function isCellHyperlinkValue(value: CellValue): value is CellHyperlinkValue {
  return typeof value === 'object' && value !== null && 'hyperlink' in value
}

function isCellSharedFormulaValue(
  value: CellValue
): value is CellSharedFormulaValue {
  return typeof value === 'object' && value !== null && 'sharedFormula' in value
}

function isCellFormulaValue(value: CellValue): value is CellFormulaValue {
  return typeof value === 'object' && value !== null && 'formula' in value
}

export function testImportParser({
  materials,
  tags,
  laboratories,
}: {
  materials: GetMaterialsQuery['material']
  tags: GetTagsQuery['tag']
  laboratories: GetLaboratoriesQuery['laboratory']
}) {
  return async (
    file: File
  ): Promise<{
    testInserts: Test_Insert_Input[]
    testUpdates: Test_Updates[]
    testTags: Test_Tag_Insert_Input[]
    testMaterials: Test_Material_Insert_Input[]
    laboratoryTests: Laboratory_Test_Insert_Input[]
    testIds: number[]
  }> => {
    const workbook = new ExcelJS.Workbook()
    await workbook.xlsx.load(await file.arrayBuffer())
    const worksheet = workbook.worksheets[0]

    const keys = worksheet.getRow(1).values as string[]

    const upsert: {
      testInserts: Test_Insert_Input[]
      testUpdates: Test_Updates[]
      testTags: Test_Tag_Insert_Input[]
      testMaterials: Test_Material_Insert_Input[]
      laboratoryTests: Laboratory_Test_Insert_Input[]
      testIds: number[]
    } = {
      testInserts: [],
      testUpdates: [],
      testTags: [],
      testMaterials: [],
      testIds: [],
      laboratoryTests: [],
    }

    worksheet.eachRow((row, rowIdx) => {
      if (rowIdx === 1) {
        return
      }

      const test = keys.reduce<Test_Insert_Input>((obj, key, i) => {
        const value = cellValueToPrimitive((row.values as CellValue[])[i])

        switch (key) {
          case 'materials':
            const testMaterials: Test_Material_Arr_Rel_Insert_Input['data'] = []
            if (value) {
              for (const materialName of String(value).split(/, ?/)) {
                const material = materials.find(
                  (material) =>
                    material.name.toLowerCase() === materialName.toLowerCase()
                )
                if (!material) {
                  throw new Error(`Material "${materialName}" does not exist`)
                }

                testMaterials.push({ materialId: material.id })
              }
            }
            _.set(obj, 'testMaterials', { data: testMaterials })
            break

          case 'laboratories':
            const laboratoryTests: Laboratory_Test_Arr_Rel_Insert_Input['data'] =
              []
            if (value) {
              for (const laboratoryShortNameAndPrice of String(value).split(
                /, ?/
              )) {
                const [laboratoryShortName, priceString] =
                  laboratoryShortNameAndPrice.split(':')
                if (!priceString) {
                  throw new Error(`Missing price`)
                }

                const price = parseFloat(priceString)
                if (Number.isNaN(price)) {
                  throw new Error(`invalid number: "${priceString}"`)
                }

                const laboratory = laboratories.find(
                  (laboratory) =>
                    (laboratory.shortName || '').toLowerCase() ===
                    laboratoryShortName.toLowerCase()
                )
                if (!laboratory) {
                  throw new Error(
                    `Laboratory "${laboratoryShortName}" does not exist`
                  )
                }

                laboratoryTests.push({ laboratoryId: laboratory.id, price })
              }
            }
            _.set(obj, 'laboratoryTests', { data: laboratoryTests })
            break

          case 'tags':
            const testTags: Test_Tag_Arr_Rel_Insert_Input['data'] = []
            if (value) {
              for (const tagName of String(value).split(/, ?/)) {
                const tag = tags.find(
                  (tag) =>
                    (tag.id || '').toLowerCase() === tagName.toLowerCase()
                )
                if (!tag) {
                  throw new Error(`Tag "${tagName}" does not exist`)
                }

                testTags.push({ tagId: tag.id })
              }
            }
            _.set(obj, 'testTags', { data: testTags })
            break
          case 'id':
            if (value) {
              const id = parseInt(String(value))
              if (Number.isNaN(id)) {
                throw new Error(
                  `Cannot parse test id "${value}" at row ${rowIdx}`
                )
              }
              _.set(obj, key, id)
            }
            break
          case 'svgIcon':
            // omit svgIcon - it's value often exceeds the size of a single cell
            break

          default:
            _.set(obj, key, value)
        }

        return obj
      }, {})

      if (test.id) {
        const { id, testMaterials, testTags, laboratoryTests, ...testUpdate } =
          test

        upsert.testUpdates.push({
          where: { id: { _eq: id } },
          _set: testUpdate,
        })
        upsert.testIds.push(id)
        upsert.testMaterials.push(
          ...(testMaterials?.data || []).map(({ materialId }) => ({
            testId: id,
            materialId,
          }))
        )
        upsert.testTags.push(
          ...(testTags?.data || []).map(({ tagId }) => ({
            testId: id,
            tagId,
          }))
        )
        upsert.laboratoryTests.push(
          ...(laboratoryTests?.data || []).map(({ laboratoryId, price }) => ({
            testId: id,
            laboratoryId,
            price,
          }))
        )
      } else {
        upsert.testInserts.push(test)
      }
    })

    return upsert
  }
}

export function testExportGenerator({
  tests,
}: {
  tests: GetTestsQuery['test']
}) {
  return () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Badania')
    worksheet.views = [
      {
        state: 'frozen',
        xSplit: 0,
        ySplit: 1,
        topLeftCell: 'A2',
        activeCell: 'A1',
      },
    ]

    worksheet.columns = [
      {
        header: 'id',
        key: 'id',
        width: 16,
      },
      ...Object.keys(Locale).map((locale) => ({
        header: `name_i18n.${locale}`,
        key: `name_i18n.${locale}`,
        width: 60,
      })),
      ...Object.keys(Locale).map((locale) => ({
        header: `shortName_i18n.${locale}`,
        key: `shortName_i18n.${locale}`,
        width: 60,
      })),
      ...Object.keys(Locale).map((locale) => ({
        header: `description_i18n.${locale}`,
        key: `description_i18n.${locale}`,
        width: 60,
      })),
      ...Object.keys(Locale).map((locale) => ({
        header: `preparation_i18n.${locale}`,
        key: `preparation_i18n.${locale}`,
        width: 60,
      })),
      ...Object.keys(Locale).map((locale) => ({
        header: `instructions_i18n.${locale}`,
        key: `instructions_i18n.${locale}`,
        width: 60,
      })),
      ...Object.keys(Locale).map((locale) => ({
        header: `waitingTime_i18n.${locale}`,
        key: `waitingTime_i18n.${locale}`,
        width: 60,
      })),
      ...Object.keys(Locale).map((locale) => ({
        header: `labInstructions_i18n.${locale}`,
        key: `labInstructions_i18n.${locale}`,
        width: 60,
      })),
      {
        header: 'mostPopular',
        key: 'mostPopular',
        width: 60,
      },
      {
        header: 'materials',
        key: 'materials',
        width: 60,
      },
      {
        header: 'tags',
        key: 'tags',
        width: 60,
      },
      {
        header: 'laboratories',
        key: 'laboratories',
        width: 60,
      },
    ]
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { size: 14, color: { argb: '00FFFFFF' }, bold: true }
      cell.style.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF000000' },
      }
    })

    for (const test of tests) {
      const row = worksheet.addRow({
        ...Object.entries(test).reduce((r, [k, v]) => {
          if (k.endsWith('_i18n') && typeof v === 'object') {
            return {
              ...r,
              ...Object.fromEntries(
                Object.entries(v).map(([locale, translation]) => [
                  `${k}.${locale}`,
                  translation,
                ])
              ),
            }
          } else if (k === 'testMaterials') {
            return {
              ...r,
              materials: v
                .map(
                  ({
                    material,
                  }: GetTestsQuery['test'][number]['testMaterials'][number]) =>
                    material.name
                )
                .join(', '),
            }
          } else if (k === 'testTags') {
            return {
              ...r,
              tags: v
                .map(
                  ({
                    tagId,
                  }: {
                    tagId: GetTestsQuery['test'][number]['testTags'][number]
                  }) => tagId
                )
                .join(', '),
            }
          } else if (k === 'laboratoryTests') {
            return {
              ...r,
              laboratories: v
                .map(
                  ({
                    laboratory,
                    price,
                  }: GetTestsQuery['test'][number]['laboratoryTests'][number]) =>
                    `${laboratory.shortName || '?'}:${price.toFixed(2)}`
                )
                .join(', '),
            }
          } else {
            return { ...r, [k]: v }
          }
        }, {}),
      })
      row.eachCell((cell, i) => {
        cell.alignment = { wrapText: true, vertical: 'top' }
        cell.font = { size: 14, bold: i === 2 }
        if (i === 1) {
          cell.numFmt = '0'
        }
        if (i === 16) {
          cell.numFmt = '#,##0.00 zł'
        }
      })
    }

    return workbook.xlsx.writeBuffer()
  }
}
