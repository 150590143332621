import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from 'react-bootstrap'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {
  GetLaboratoryCollectionPointDocument,
  GetLaboratoryCollectionPointQuery,
  GetLaboratoryCollectionPointQueryVariables,
  useInsertLaboratoryCollectionPointMutation,
  useUpdateLaboratoryCollectionPointMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'
import { assertPoint, Point as PointType } from '../lib/geography'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import * as Time from '../lib/time'

export type LaboratoryCollectionPointModalType = {
  show: (args: { id?: number; laboratoryId: number }) => void
}

type FormLaboratoryCollectionPoint = {
  name: string
  address: string
  postalCode: string
  city: string
  location: PointType['coordinates']
  openingHours: Time.WeekSchedule
}

const LaboratoryCollectionPointModal = forwardRef<
  LaboratoryCollectionPointModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState<number>()
  const [laboratoryId, setLaboratoryId] = useState<number>()
  const client = useClient()
  const [, setGetLaboratoryCollectionPointFetching] = useState(false)
  const [
    { fetching: updateLaboratoryCollectionPointFetching },
    updateLaboratoryCollectionPoint,
  ] = useUpdateLaboratoryCollectionPointMutation()
  const [
    { fetching: insertLaboratoryCollectionPointFetching },
    insertLaboratoryCollectionPoint,
  ] = useInsertLaboratoryCollectionPointMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [zoom, setZoom] = useState(15)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    setValue,
    watch,
  } = useForm<FormLaboratoryCollectionPoint>({
    defaultValues: {
      name: '',
      address: '',
      postalCode: '',
      city: '',
      location: [52, 16],
      openingHours: {},
    },
  })

  useImperativeHandle(ref, () => ({
    show: ({ id, laboratoryId }) => {
      setId(id)
      setLaboratoryId(laboratoryId)
      reset({
        name: '',
        address: '',
        postalCode: '',
        city: '',
        location: [52, 16],
        openingHours: {},
      })
      setShow(true)
    },
  }))

  const onZoomChanged = useCallback(function () {
    // @ts-ignore
    setZoom((this as GoogleMap).getZoom())
  }, [])

  useEffect(() => {
    if (id) {
      setGetLaboratoryCollectionPointFetching(true)
      client
        .query<
          GetLaboratoryCollectionPointQuery,
          GetLaboratoryCollectionPointQueryVariables
        >(
          GetLaboratoryCollectionPointDocument,
          {
            id,
          },
          { requestPolicy: 'network-only' }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.laboratory_collection_point_by_pk) {
            const location = data.laboratory_collection_point_by_pk
              .location || {
              type: 'Point',
              coordinates: [52, 16],
            }

            assertPoint(location)

            reset({
              name: data.laboratory_collection_point_by_pk.name || '',
              address: data.laboratory_collection_point_by_pk.address || '',
              postalCode:
                data.laboratory_collection_point_by_pk.postalCode || '',
              city: data.laboratory_collection_point_by_pk.city || '',
              location: location.coordinates,
              openingHours:
                data.laboratory_collection_point_by_pk.openingHours || {},
            })
          }
        })
        .finally(() => setGetLaboratoryCollectionPointFetching(false))
    }
  }, [id, client, reset])

  async function doSaveLaboratoryCollectionPoint({
    location,
    ...laboratoryCollectionPoint
  }: FormLaboratoryCollectionPoint) {
    setGeneralError(undefined)

    console.log(laboratoryCollectionPoint)

    if (!laboratoryId) {
      setGeneralError('Missing laboratoryId')
      return
    }

    const { error } = id
      ? await updateLaboratoryCollectionPoint({
          ...laboratoryCollectionPoint,
          location: {
            type: 'Point',
            coordinates: location,
          },
          id,
        })
      : await insertLaboratoryCollectionPoint({
          ...laboratoryCollectionPoint,
          location: {
            type: 'Point',
            coordinates: location,
          },
          laboratoryId,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setId(undefined)
    setLaboratoryId(undefined)
    onChange()
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setId(undefined)
        setLaboratoryId(undefined)
        setShow(false)
      }}
      size="xl"
    >
      <Form onSubmit={handleSubmit(doSaveLaboratoryCollectionPoint)}>
        <Modal.Header closeButton>
          <Modal.Title>Punkt pobrań</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadScript googleMapsApiKey="AIzaSyBeR-j3Q7in_4YcIrqHWsyS2ZqQZh3XwBY">
            <Form
              onSubmit={handleSubmit(doSaveLaboratoryCollectionPoint)}
              className="mt-4"
            >
              {generalError && <Alert variant="danger">{generalError}</Alert>}

              <Row>
                <Col>
                  <h4>Dane punktu pobrań</h4>
                  <FloatingLabel label="Nazwa" className="mb-3">
                    <Form.Control
                      isInvalid={!!errors.name}
                      type="name"
                      placeholder="Nazwa"
                      {...register('name', {
                        required: 'Proszę podać nazwę',
                      })}
                    />
                    {errors.name && (
                      <Form.Control.Feedback>
                        {errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>

                  <FloatingLabel label="Adres" className="mb-3">
                    <Form.Control
                      isInvalid={!!errors.address}
                      type="text"
                      placeholder="Adres"
                      {...register('address', {
                        required: 'Proszę podać adres',
                      })}
                    />
                    {errors.address && (
                      <Form.Control.Feedback>
                        {errors.address.message}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>

                  <FloatingLabel label="Kod pocztowy" className="mb-3">
                    <Form.Control
                      isInvalid={!!errors.postalCode}
                      type="text"
                      placeholder="Kod pocztowy"
                      {...register('postalCode', {
                        required: 'Proszę podać kod pocztowy',
                        pattern: {
                          value: /^[0-9]{2}-[0-9]{3}$/,
                          message: 'Nieprowidłowy kod pocztowy',
                        },
                      })}
                    />
                    {errors.postalCode && (
                      <Form.Control.Feedback>
                        {errors.postalCode.message}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>

                  <FloatingLabel label="Miasto" className="mb-3">
                    <Form.Control
                      isInvalid={!!errors.city}
                      type="text"
                      placeholder="Miasto"
                      {...register('city', {
                        required: 'Proszę podać miasto',
                      })}
                    />
                    {errors.city && (
                      <Form.Control.Feedback>
                        {errors.city.message}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>

                  <h4>Godziny otwarcia</h4>

                  {(
                    ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const
                  ).map((weekDay) => (
                    <Row className="mb-1">
                      <Col className="d-flex align-items-center">
                        {Time.WeekDay2Name[weekDay]}
                      </Col>
                      <Col className="d-flex align-items-center">
                        <Form.Control
                          type="time"
                          disabled={!watch(`openingHours.${weekDay}`)}
                          step={1}
                          value={watch(`openingHours.${weekDay}.0`)}
                          onChange={(event) =>
                            setValue(
                              `openingHours.${weekDay}.0`,
                              event.target.value as Time.Time
                            )
                          }
                        />
                        &nbsp;-&nbsp;
                        <Form.Control
                          type="time"
                          disabled={!watch(`openingHours.${weekDay}`)}
                          step={1}
                          value={watch(`openingHours.${weekDay}.1`)}
                          onChange={(event) =>
                            setValue(
                              `openingHours.${weekDay}.1`,
                              event.target.value as Time.Time
                            )
                          }
                        />
                      </Col>

                      <Col className="d-flex align-items-center">
                        <Form.Check
                          checked={!watch(`openingHours.${weekDay}`)}
                          onChange={(event) => {
                            setValue(
                              `openingHours.${weekDay}`,
                              event.target.checked
                                ? undefined
                                : ['00:00:00', '23:59:59']
                            )
                          }}
                        />
                        &nbsp;Zamknięte
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col>
                  <h4>Lokalizacja</h4>

                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { value: location } }) => {
                      return (
                        <GoogleMap
                          mapContainerStyle={{ width: '100%', height: '400px' }}
                          center={{ lat: location[0], lng: location[1] }}
                          zoom={zoom}
                          onZoomChanged={onZoomChanged}
                        >
                          <Marker
                            position={{ lat: location[0], lng: location[1] }}
                            draggable
                            onDragEnd={(event) => {
                              if (event.latLng) {
                                setValue('location', [
                                  event.latLng.lat(),
                                  event.latLng.lng(),
                                ])
                              }
                            }}
                          />
                        </GoogleMap>
                      )
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </LoadScript>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={
              updateLaboratoryCollectionPointFetching ||
              insertLaboratoryCollectionPointFetching
            }
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default LaboratoryCollectionPointModal
