import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetNursesDocument,
  GetNursesQuery,
  GetNursesQueryVariables,
  useDeleteNurseMutation,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, PersonPlus, Trash } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 20

const Nurses: FC = () => {
  // page content
  const pageTitle = 'Osoby pobierające'
  const [nurses, setNurses] = useState<GetNursesQuery['nurse']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => nurses, [nurses])
  const [{ fetching: deleteNurseFetching }, deleteNurse] =
    useDeleteNurseMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetNursesQuery, GetNursesQueryVariables>(
          GetNursesDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setNurses(refetch ? data.nurse : nurses.concat(data.nurse))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.nurse_aggregate.aggregate?.count || 0)
      }
    },
    [client, nurses, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  const doDeleteNurse = useCallback(
    async (id: number) => {
      if (window.confirm('Czy napewno chcesz usunąć osobę pobierającą?')) {
        await deleteNurse({ id })
        window.location.reload()
      }
    },
    [deleteNurse]
  )

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/nurse')}
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetNursesQuery['nurse'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Nazwa', accessor: 'name' },
          { Header: 'E-mail', accessor: 'email' },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/nurse/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => doDeleteNurse(rowData.id)}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Nurses
