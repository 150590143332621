import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import {
  GetAdministratorNotificationDocument,
  GetAdministratorNotificationQuery,
  GetAdministratorNotificationQueryVariables,
  useInsertAdministratorNotificationMutation,
  useUpdateAdministratorNotificationMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'

export type AdministratorNotificationModalType = {
  show: (args: { id?: number; administratorId: number }) => void
}

type FormAdministratorNotification = {
  scope: string
  email: boolean
  sms: boolean
}

const AdministratorNotificationModal = forwardRef<
  AdministratorNotificationModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState<number>()
  const [administratorId, setAdministratorId] = useState<number>()
  const client = useClient()
  const [, setGetAdministratorNotificationFetching] = useState(false)
  const [
    { fetching: updateAdministratorNotificationFetching },
    updateAdministratorNotification,
  ] = useUpdateAdministratorNotificationMutation()
  const [
    { fetching: insertAdministratorNotificationFetching },
    insertAdministratorNotification,
  ] = useInsertAdministratorNotificationMutation()
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    watch,
    control,
  } = useForm<FormAdministratorNotification>({
    defaultValues: {
      scope: 'order:new',
      email: true,
      sms: true,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ id, administratorId }) => {
      setId(id)
      setAdministratorId(administratorId)
      reset({
        scope: 'order:new',
        email: true,
        sms: true,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (id) {
      setGetAdministratorNotificationFetching(true)
      client
        .query<
          GetAdministratorNotificationQuery,
          GetAdministratorNotificationQueryVariables
        >(GetAdministratorNotificationDocument, {
          id,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.administrator_notification_by_pk) {
            reset({
              scope: data.administrator_notification_by_pk.scope || '',
              email: data.administrator_notification_by_pk.email,
              sms: data.administrator_notification_by_pk.sms,
            })
          }
        })
        .finally(() => setGetAdministratorNotificationFetching(false))
    }
  }, [id, client, reset])

  async function doSaveAdministratorNotification(
    administratorNotification: FormAdministratorNotification
  ) {
    setGeneralError(undefined)

    if (!administratorId) {
      setGeneralError('Missing administratorId')
      return
    }

    const { error } = id
      ? await updateAdministratorNotification({
          ...administratorNotification,
          id,
        })
      : await insertAdministratorNotification({
          ...administratorNotification,
          administratorId,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setId(undefined)
    setAdministratorId(undefined)
    onChange()
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setId(undefined)
        setAdministratorId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveAdministratorNotification)}>
        <Modal.Header closeButton>
          <Modal.Title>Powiadomienie administratora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="scope"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Rodzaj" className="mb-3">
                <Form.Select value={String(value)} onChange={onChange}>
                  <option value="order:new">Nowe zlecenie</option>
                </Form.Select>
                {errors.scope && (
                  <Form.Control.Feedback>
                    {errors.scope.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />

          <Form.Check
            type="switch"
            style={{ marginBottom: '16px' }}
            label="Powiadomienie email"
            {...register('email')}
          />

          <Form.Check
            type="switch"
            style={{ marginBottom: '16px' }}
            label="Powiadomienie SMS"
            {...register('sms')}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={
              updateAdministratorNotificationFetching ||
              insertAdministratorNotificationFetching
            }
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default AdministratorNotificationModal
