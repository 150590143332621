import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Discount_Type_Enum_Enum,
  GetDiscountsDocument,
  GetDiscountsQuery,
  GetDiscountsQueryVariables,
  useDeleteDiscountMutation,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Badge, Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, PersonPlus, Trash } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'
import moment from 'moment'

const BatchSize = 20

const Discounts: FC = () => {
  // page content
  const pageTitle = 'Kody rabatowe'
  const [discounts, setDiscounts] = useState<GetDiscountsQuery['discount']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => discounts, [discounts])
  const client = useClient()
  const navigate = useNavigate()
  const [, deleteDiscount] = useDeleteDiscountMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetDiscountsQuery, GetDiscountsQueryVariables>(
          GetDiscountsDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setDiscounts(refetch ? data.discount : discounts.concat(data.discount))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.discount_aggregate.aggregate?.count || 0)
      }
    },
    [client, discounts, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  const doDeleteDiscount = useCallback(
    async (id: number) => {
      if (window.confirm('Czy napewno chcesz usunąć rabat?')) {
        await deleteDiscount({ id })
        await loadMoreRows({ refetch: true })
      }
    },
    [deleteDiscount, loadMoreRows]
  )

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/discount')}
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetDiscountsQuery['discount'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Kod', accessor: 'code' },
          {
            Header: 'Rodzaj',
            accessor: (rowData) => {
              switch (rowData.type) {
                case Discount_Type_Enum_Enum.SomeTests:
                  return (
                    <Badge bg="secondary">
                      Liczba badań:{' '}
                      {rowData.discountTests_aggregate.aggregate?.count}
                    </Badge>
                  )
                case Discount_Type_Enum_Enum.AllTests:
                  return <Badge bg="success">Wszystkie badania</Badge>
                case Discount_Type_Enum_Enum.WholeOrder:
                  return <Badge bg="primary">Całe zamówienie</Badge>
                case Discount_Type_Enum_Enum.WholeOrderWithTests:
                  return <Badge bg="warning">Całe zamówienie (badania: {rowData.discountTests_aggregate.aggregate?.count})</Badge>
                case Discount_Type_Enum_Enum.CollectionCost:
                  return <Badge bg="secondary">Koszt pobrania</Badge>
                default:
                  return <span>?</span>
              }
            },
          },
          {
            Header: 'Rabat',
            accessor: (rowData) => <span>{rowData.percent.toFixed(2)}%</span>,
          },
          {
            Header: 'Obowiązuje od / Obowiązuje do',
            accessor: ({ dateFrom, dateTo }) => (
              <span>
                {dateFrom ? moment(dateFrom).format('yyyy-MM-DD') : '-'} /{' '}
                {dateTo ? moment(dateTo).format('yyyy-MM-DD') : '-'}
              </span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/discount/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => doDeleteDiscount(rowData.id)}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Discounts
