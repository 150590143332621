import decode from 'jwt-decode'
import { createContext, useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'

export type Auth = ReturnType<typeof useAuth>
export const AuthContext = createContext<Auth>({
  accessToken: null,
  administratorId: -1,
  setAccessToken: async () => {},
  clearTokens: async () => {},
  isLogged: false,
})

type AccessToken = {
  sub: string
  exp: string
  'https://homelab24.com/jwt/claims': {
    'x-hasura-administrator-id': string
  }
}

export function useAuth() {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [, , removeRefreshToken] = useCookies(['refreshToken'])

  return {
    accessToken,
    get administratorId() {
      if (this.accessToken) {
        const payload = decode<AccessToken>(this.accessToken)
        return parseInt(
          payload['https://homelab24.com/jwt/claims'][
            'x-hasura-administrator-id'
          ]
        )
      }

      return -1
    },
    setAccessToken,
    clearTokens: useCallback(async () => {
      setAccessToken(null)
      removeRefreshToken('refreshToken')
    }, [setAccessToken, removeRefreshToken]),
    isLogged: !!accessToken,
  }
}

export function isTokenExpired(accessToken: string, offset = 0) {
  const payload = decode<AccessToken>(accessToken)
  return Date.now() >= parseInt(payload.exp) * 1000 + offset
}
