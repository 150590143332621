import { Order, Order_State_Enum_Enum } from '../generated/urql.administrator'
import { BadgeProps } from 'react-bootstrap'

export function getOrderStateLabel(
  order: Pick<Order, 'currentState'> & {
    orderCollections: unknown[]
    orderConsultations: unknown[]
    orderTrainings: unknown[]
  }
) {
  if (!order.currentState) {
    return ''
  }
  if (!(order.currentState in OrderState2Label)) {
    return ''
  }

  if (
    order.currentState === Order_State_Enum_Enum.AwaitingConfirmation &&
    order.orderCollections.length === 0 &&
    (order.orderConsultations.length > 0 || order.orderTrainings.length > 0)
  ) {
    return 'Oczekiwanie'
  }

  return OrderState2Label[order.currentState as Order_State_Enum_Enum]
}

export const OrderState2Label: {
  [key in keyof typeof Order_State_Enum_Enum]: string
} = {
  [Order_State_Enum_Enum.Unpaid]: 'Nieopłacone',
  [Order_State_Enum_Enum.AwaitingConfirmation]:
    'Oczekiwanie na potwierdzenie przez pielęgniarza',
  [Order_State_Enum_Enum.AwaitingClientConfirmation]:
    'Oczekiwanie na potwierdzenie przez klienta',
  [Order_State_Enum_Enum.DifferentDateProposed]: 'Sugestia innego terminu',
  [Order_State_Enum_Enum.DifferentDateAccepted]:
    'Sugestia innego terminu zaakceptowana',
  [Order_State_Enum_Enum.DifferentDateRejected]:
    'Sugestia innego terminu odrzucona',
  [Order_State_Enum_Enum.Confirmed]: 'Potwierdzone',
  [Order_State_Enum_Enum.OnTheWayToClient]: 'W drodze do pobrania',
  [Order_State_Enum_Enum.CollectingSamples]: 'W trakcie pobierania próbek',
  [Order_State_Enum_Enum.OnTheWayToLab]: 'W drodze do laboratorium',
  [Order_State_Enum_Enum.ExaminingSamples]: 'W trakcie badania',
  [Order_State_Enum_Enum.Finished]: 'Zakończone',
  [Order_State_Enum_Enum.Cancelled]: 'Anulowane',
}

export const OrderState2BgVariant: {
  [key in keyof typeof Order_State_Enum_Enum]: BadgeProps['bg']
} = {
  [Order_State_Enum_Enum.Unpaid]: 'warning',
  [Order_State_Enum_Enum.AwaitingConfirmation]: 'warning',
  [Order_State_Enum_Enum.AwaitingClientConfirmation]: 'warning',
  [Order_State_Enum_Enum.DifferentDateProposed]: 'warning',
  [Order_State_Enum_Enum.DifferentDateAccepted]: 'warning',
  [Order_State_Enum_Enum.DifferentDateRejected]: 'warning',
  [Order_State_Enum_Enum.Confirmed]: 'warning',
  [Order_State_Enum_Enum.OnTheWayToClient]: 'warning',
  [Order_State_Enum_Enum.CollectingSamples]: 'warning',
  [Order_State_Enum_Enum.OnTheWayToLab]: 'warning',
  [Order_State_Enum_Enum.ExaminingSamples]: 'warning',
  [Order_State_Enum_Enum.Finished]: 'success',
  [Order_State_Enum_Enum.Cancelled]: 'danger',
}
