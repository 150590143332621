import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import {
  GetTagDocument,
  GetTagQuery,
  GetTagQueryVariables,
  useInsertTagMutation,
  useUpdateTagMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { Locale, LocalizedField } from '../lib/translations'
import { TranslatedField } from '../components/TranslatedField'

type FormTag = Omit<NonNullable<GetTagQuery['tag_by_pk']>, 'tag_i18n'> & {
  tag_i18n: LocalizedField
}

const Tag: FC = () => {
  const pageTitle = 'Kategoria'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetTagFetching] = useState(false)
  const [{ fetching: updateTagFetching }, updateTag] = useUpdateTagMutation()
  const [{ fetching: insertTagFetching }, insertTag] = useInsertTagMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormTag>({
    defaultValues: {
      tag_i18n: { pl_PL: '' },
    },
  })

  useEffect(() => {
    if (id) {
      setGetTagFetching(true)
      client
        .query<GetTagQuery, GetTagQueryVariables>(GetTagDocument, {
          id,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.tag_by_pk) {
            reset({
              tag_i18n: data.tag_by_pk.tag_i18n || { pl_PL: '' },
            })
          }
        })
        .finally(() => setGetTagFetching(false))
    }
  }, [id, client, reset])

  async function doSaveTag(tag: FormTag) {
    setGeneralError(undefined)

    const { data, error } = id
      ? await updateTag({
          ...tag,
          id,
        })
      : await insertTag({ ...tag, id: tag.tag_i18n['pl_PL'] })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_tag_one' in data) {
      navigate(`/tag/${data.insert_tag_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/tags')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSaveTag)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4 bottom-center-toast-container"
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano kategorię</Toast.Body>
          </Toast>
        </ToastContainer>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            ID
          </Form.Label>
          <Form.Label column sm="10">
            {id}
          </Form.Label>
        </Form.Group>

        <TranslatedField
          label="Kategoria"
          control={(locale) => (
            <>
              <Form.Control
                isInvalid={!!errors.tag_i18n?.[locale]}
                type="text"
                placeholder="Kategoria"
                {...register(`tag_i18n.${locale}`, {
                  required:
                    locale === Locale.pl_PL ?? 'Proszę podać nazwę kategorii',
                })}
              />
              {errors.tag_i18n?.[locale] && (
                <Form.Control.Feedback>
                  {errors.tag_i18n?.[locale]?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        />

        <Button
          variant="primary"
          type="submit"
          disabled={updateTagFetching || insertTagFetching}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default Tag
