import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import {
  assertPolygon,
  calculateInitialRegion,
  fromLinearRing,
  Polygon as PolygonType,
  toLinearRing,
} from '../lib/geography'
import {
  GetRateDocument,
  GetRateQuery,
  GetRateQueryVariables,
  useInsertRateMutation,
  useUpdateRateMutation,
} from '../generated/urql.administrator'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import {
  DrawingManager,
  GoogleMap,
  LoadScript,
  LoadScriptProps,
  Polygon,
} from '@react-google-maps/api'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

type FormRate = {
  name: string
  default: boolean
  collectionCost: number
  collectionCost3Plus: number
  collectionKitCost: number
  deliveryCostPerKm: number
  areaPolygons: PolygonType['coordinates']
}

const googleLibraries: LoadScriptProps['libraries'] = ['drawing']

const Rates: FC = () => {
  const pageTitle = 'Stawka'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetRateFetching] = useState(false)
  const [areaPolygonRefs, setAreaPolygonRefs] = useState<google.maps.Polygon[]>(
    []
  )
  const [{ fetching: updateRateFetching }, updateRate] = useUpdateRateMutation()
  const [{ fetching: insertRateFetching }, insertRate] = useInsertRateMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    setValue,
    watch,
  } = useForm<FormRate>({
    defaultValues: {
      name: '',
      default: false,
      collectionCost: 0,
      collectionCost3Plus: 0,
      collectionKitCost: 0,
      deliveryCostPerKm: 0,
      areaPolygons: [],
    },
  })

  useEffect(() => {
    if (id) {
      setGetRateFetching(true)
      client
        .query<GetRateQuery, GetRateQueryVariables>(GetRateDocument, {
          id: parseInt(id),
        })
        .toPromise()
        .then(({ data }) => {
          const area = data?.rate_by_pk?.area || {
            type: 'Polygon',
            coordinates: [],
          }

          assertPolygon(area)

          if (data?.rate_by_pk) {
            reset({
              name: data.rate_by_pk.name,
              default: data.rate_by_pk.default,
              collectionCost: data.rate_by_pk.collectionCost,
              collectionCost3Plus: data.rate_by_pk.collectionCost3Plus,
              collectionKitCost: data.rate_by_pk.collectionKitCost,
              deliveryCostPerKm: data.rate_by_pk.deliveryCostPerKm,
              areaPolygons: fromLinearRing(area).coordinates,
            })
          }
        })
        .finally(() => setGetRateFetching(false))
    }
  }, [id, client, reset])

  async function doSaveRate({ areaPolygons, ...rate }: FormRate) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateRate({
          ...rate,
          area: rate.default
            ? undefined
            : toLinearRing({
                type: 'Polygon',
                coordinates: areaPolygons,
              }),
          id: parseInt(id),
        })
      : await insertRate({
          ...rate,
          area: rate.default
            ? undefined
            : toLinearRing({
                type: 'Polygon',
                coordinates: areaPolygons,
              }),
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insert_rate_one' in data) {
      navigate(`/rate/${data.insert_rate_one?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <LoadScript
        googleMapsApiKey="AIzaSyBeR-j3Q7in_4YcIrqHWsyS2ZqQZh3XwBY"
        libraries={googleLibraries as any}
      >
        <div className="mt-3 mb-3 d-flex justify-content-start">
          <ButtonGroup>
            <Button
              className="d-flex align-items-center"
              onClick={() => navigate('/rates')}
            >
              <ChevronLeft size={20} />
              &nbsp;Wróć
            </Button>
          </ButtonGroup>
        </div>
        <Form onSubmit={handleSubmit(doSaveRate)} className="mt-4">
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <ToastContainer
            position="bottom-center"
            className="mb-4 bottom-center-toast-container"
          >
            <Toast
              onClose={() => setSuccessToast(false)}
              show={successToast}
              autohide
              animation={true}
              bg="success"
            >
              <Toast.Header>
                <strong className="me-auto">Sukces</strong>
              </Toast.Header>
              <Toast.Body>Pomyślnie zapisano parametry stawki</Toast.Body>
            </Toast>
          </ToastContainer>

          <Row>
            <Col>
              <h4>Stawka</h4>
              <FloatingLabel label="Nazwa" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.name}
                  type="text"
                  placeholder="Nazwa"
                  {...register('name', {
                    required: 'Proszę podać nazwę stawki',
                  })}
                />
                {errors.name && (
                  <Form.Control.Feedback>
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel label="Koszt dojazdu [zł/km]" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.deliveryCostPerKm}
                  type="text"
                  {...register('deliveryCostPerKm', {
                    required: 'Proszę podać stawkę za dojazd',
                    pattern: {
                      value: /^[0-9]+(\.[0-9]{1,4})?$/,
                      message: 'Nieprawidłowa stawka',
                    },
                  })}
                />
                {errors.deliveryCostPerKm && (
                  <Form.Control.Feedback>
                    {errors.deliveryCostPerKm.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel
                label="Koszt pobrania 1-2 osoby [zł]"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.collectionCost}
                  type="text"
                  {...register('collectionCost', {
                    required: 'Proszę podać koszt pobrania',
                    pattern: {
                      value: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message: 'Nieprawidłowy koszt',
                    },
                  })}
                />
                {errors.collectionCost && (
                  <Form.Control.Feedback>
                    {errors.collectionCost.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel
                label="Koszt pobrania 3+ osóby [zł]"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.collectionCost3Plus}
                  type="text"
                  {...register('collectionCost3Plus', {
                    required: 'Proszę podać koszt pobrania',
                    pattern: {
                      value: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message: 'Nieprawidłowy koszt',
                    },
                  })}
                />
                {errors.collectionCost3Plus && (
                  <Form.Control.Feedback>
                    {errors.collectionCost3Plus.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <FloatingLabel label="Zestaw pobraniowy [zł]" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.collectionKitCost}
                  type="text"
                  {...register('collectionKitCost', {
                    required: 'Proszę podać koszt zestawu pobraniowego',
                    pattern: {
                      value: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message: 'Nieprawidłowy koszt',
                    },
                  })}
                />
                {errors.collectionKitCost && (
                  <Form.Control.Feedback>
                    {errors.collectionKitCost.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Domyślna stawka"
                  {...register('default')}
                />
                {errors.default && (
                  <Form.Control.Feedback>
                    {errors.default.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              {!watch('default') && (
                <>
                  <h4>Obszar obowiązywania stawki</h4>

                  <Controller
                    name="areaPolygons"
                    control={control}
                    render={({ field: { value: polygons } }) => {
                      return (
                        <GoogleMap
                          mapContainerStyle={{ width: '100%', height: '400px' }}
                          center={
                            polygons.length
                              ? calculateInitialRegion({
                                  type: 'Polygon',
                                  coordinates: polygons,
                                })
                              : { lat: 52, lng: 16 }
                          }
                          zoom={7}
                        >
                          {polygons.length ? (
                            <>
                              {polygons.map((polygon, i) => (
                                <Polygon
                                  onLoad={(polygonRef) =>
                                    setAreaPolygonRefs([
                                      ...areaPolygonRefs.slice(0, i),
                                      polygonRef,
                                      ...areaPolygonRefs.slice(
                                        i + 1,
                                        polygons.length
                                      ),
                                    ])
                                  }
                                  onMouseUp={() => {
                                    if (areaPolygonRefs[i]) {
                                      const path = areaPolygonRefs[i]
                                        .getPath()
                                        .getArray()
                                      if (path) {
                                        setValue('areaPolygons', [
                                          ...polygons.slice(0, i),
                                          path.map((point) => [
                                            point.lat(),
                                            point.lng(),
                                          ]) as [number, number][],
                                          ...polygons.slice(
                                            i + 1,
                                            polygons.length
                                          ),
                                        ])
                                      }
                                    }
                                  }}
                                  editable={true}
                                  options={{
                                    strokeColor: '#ff0000',
                                    fillColor: 'rgba(255,0,0,0.3)',
                                  }}
                                  key={i}
                                  path={polygon.map(([lat, lng]) => ({
                                    lat,
                                    lng,
                                  }))}
                                />
                              ))}
                            </>
                          ) : (
                            <DrawingManager
                              drawingMode={'polygon' as any}
                              options={{
                                polygonOptions: {
                                  editable: true,
                                  draggable: true,
                                  strokeColor: '#ff0000',
                                  fillColor: 'rgba(255,0,0,0.3)',
                                },
                              }}
                              onPolygonComplete={(polygon) => {
                                polygon.setMap(null)
                                setValue('areaPolygons', [
                                  polygon
                                    .getPath()
                                    .getArray()
                                    .map((point) => [
                                      point.lat() as number,
                                      point.lng() as number,
                                    ]) as [number, number][],
                                ])
                              }}
                            />
                          )}
                        </GoogleMap>
                      )
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
          <Button
            variant="primary"
            type="submit"
            disabled={updateRateFetching || insertRateFetching}
          >
            Zapisz
          </Button>
        </Form>
      </LoadScript>
    </div>
  )
}

export default Rates
